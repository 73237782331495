/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { getOverrideProps } from "./utils";
import { Icon, View } from "@aws-amplify/ui-react";
export default function LogoFull(props) {
  const { overrides, ...rest } = props;
  return (
    <View
      width="326px"
      height="36.97px"
      display="block"
      gap="unset"
      alignItems="unset"
      justifyContent="unset"
      position="relative"
      padding="0px 0px 0px 0px"
      {...getOverrideProps(overrides, "LogoFull")}
      {...rest}
    >
      <View
        padding="0px 0px 0px 0px"
        width="326px"
        height="36.9px"
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        position="absolute"
        top="0px"
        left="0px"
        {...getOverrideProps(overrides, "Group 1")}
      >
        <View
          padding="0px 0px 0px 0px"
          width="282.86px"
          height="18.81px"
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          position="absolute"
          top="26.75%"
          bottom="22.26%"
          left="13.23%"
          right="0%"
          {...getOverrideProps(overrides, "Group49141136")}
        >
          <View
            padding="0px 0px 0px 0px"
            width="282.86px"
            height="18.81px"
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            position="absolute"
            top="0%"
            bottom="0%"
            left="0%"
            right="0%"
            {...getOverrideProps(overrides, "Group49141137")}
          >
            <Icon
              width="12.2px"
              height="14.15px"
              viewBox={{
                minX: 0,
                minY: 0,
                width: 12.19555377960205,
                height: 14.147880554199219,
              }}
              paths={[
                {
                  d: "M7.24314 14.1479C6.20841 14.1479 5.24526 13.9722 4.36021 13.6208C3.47515 13.2693 2.70723 12.7747 2.05646 12.137C1.40568 11.4992 0.898073 10.7508 0.540146 9.8918C0.182219 9.03278 0 8.09566 0 7.07394C0 6.05222 0.182219 5.1086 0.540146 4.25608C0.898073 3.39705 1.40568 2.65517 2.06297 2.0109C2.72025 1.37314 3.48817 0.878548 4.36671 0.527129C5.24526 0.175709 6.2019 0 7.23664 0C8.21931 0 9.1369 0.162694 9.99593 0.481574C10.855 0.800455 11.5838 1.28854 12.1956 1.93931L11.5578 2.5966C10.9591 2.0109 10.3018 1.5879 9.57293 1.32758C8.85056 1.06727 8.08265 0.937118 7.26918 0.937118C6.37761 0.937118 5.55112 1.0933 4.78971 1.39266C4.02831 1.69853 3.36452 2.12804 2.79834 2.68771C2.23217 3.24737 1.79615 3.89815 1.48377 4.64004C1.1714 5.38192 1.01521 6.19539 1.01521 7.07394C1.01521 7.95249 1.1714 8.75945 1.48377 9.50784C1.79615 10.2497 2.23217 10.9005 2.79834 11.4602C3.36452 12.0198 4.02831 12.4494 4.78971 12.7552C5.55112 13.0611 6.37761 13.2108 7.26918 13.2108C8.08265 13.2108 8.84406 13.0806 9.57293 12.8203C10.2953 12.56 10.9591 12.1305 11.5578 11.5318L12.1956 12.189C11.5838 12.8398 10.8484 13.3279 9.99593 13.6533C9.14341 13.9787 8.22582 14.1479 7.24314 14.1479Z",
                  fill: "rgba(117,120,123,1)",
                  fillRule: "nonzero",
                },
              ]}
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              position="absolute"
              top="4.36%"
              bottom="20.44%"
              left="0%"
              right="95.69%"
              {...getOverrideProps(overrides, "Vector49141138")}
            ></Icon>
            <Icon
              width="10.41px"
              height="10.56px"
              viewBox={{
                minX: 0,
                minY: 0,
                width: 10.405919075012207,
                height: 10.562102317810059,
              }}
              paths={[
                {
                  d: "M5.20621 10.5621C4.21052 10.5621 3.32547 10.3343 2.54454 9.88529C1.77011 9.43626 1.15188 8.81151 0.689826 8.01106C0.234283 7.2106 0 6.30602 0 5.2778C0 4.24306 0.227775 3.32547 0.689826 2.53803C1.14537 1.75059 1.76361 1.12584 2.54454 0.676808C3.32547 0.227772 4.21052 0 5.20621 0C6.2019 0 7.09346 0.227772 7.87439 0.676808C8.65533 1.12584 9.27357 1.75059 9.72911 2.53803C10.1781 3.32547 10.4059 4.24306 10.4059 5.2778C10.4059 6.29952 10.1781 7.2106 9.72911 8.01106C9.28008 8.80501 8.66183 9.42975 7.87439 9.88529C7.09346 10.3408 6.20841 10.5621 5.20621 10.5621ZM5.20621 9.67054C6.00016 9.67054 6.72252 9.48832 7.36028 9.12389C7.99805 8.75945 8.49914 8.24534 8.85707 7.58155C9.21499 6.91775 9.39721 6.14984 9.39721 5.29081C9.39721 4.41226 9.21499 3.64435 8.85707 2.98706C8.49914 2.32978 7.99805 1.81567 7.36028 1.45123C6.72252 1.0868 6.00667 0.90458 5.20621 0.90458C4.40576 0.90458 3.69641 1.0868 3.06516 1.45123C2.4339 1.81567 1.93281 2.32978 1.56837 2.98706C1.20394 3.64435 1.02172 4.41226 1.02172 5.29081C1.02172 6.15635 1.20394 6.91775 1.56837 7.58155C1.93281 8.24534 2.4339 8.75945 3.06516 9.12389C3.69641 9.48832 4.41226 9.67054 5.20621 9.67054Z",
                  fill: "rgba(117,120,123,1)",
                  fillRule: "nonzero",
                },
              ]}
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              position="absolute"
              top="23.28%"
              bottom="20.58%"
              left="4.84%"
              right="91.48%"
              {...getOverrideProps(overrides, "Vector49141139")}
            ></Icon>
            <Icon
              width="9.31px"
              height="10.48px"
              viewBox={{
                minX: 0,
                minY: 0,
                width: 9.306105613708496,
                height: 10.484010696411133,
              }}
              paths={[
                {
                  d: "M5.07606 0C5.92858 0 6.66395 0.162694 7.30171 0.488082C7.93297 0.813471 8.42756 1.30806 8.77898 1.96535C9.1304 2.62263 9.30611 3.4361 9.30611 4.40576L9.30611 10.484L8.31042 10.484L8.31042 4.48385C8.31042 3.31245 8.01757 2.42089 7.42536 1.81567C6.83316 1.20394 6.00667 0.898072 4.9459 0.898072C4.13243 0.898072 3.42959 1.06077 2.83088 1.39917C2.23216 1.73757 1.77662 2.19312 1.46425 2.79183C1.15187 3.39055 0.995687 4.1064 0.995687 4.9459L0.995687 10.484L0 10.484L0 0.0846016L0.956642 0.0846016L0.956642 2.97405L0.81998 2.65517C1.13886 1.82868 1.66599 1.18441 2.39486 0.709346C3.11722 0.240787 4.01529 0 5.07606 0Z",
                  fill: "rgba(117,120,123,1)",
                  fillRule: "nonzero",
                },
              ]}
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              position="absolute"
              top="23.28%"
              bottom="21%"
              left="9.61%"
              right="87.1%"
              {...getOverrideProps(overrides, "Vector49141140")}
            ></Icon>
            <Icon
              width="9.31px"
              height="10.48px"
              viewBox={{
                minX: 0,
                minY: 0,
                width: 9.306095123291016,
                height: 10.484010696411133,
              }}
              paths={[
                {
                  d: "M5.07605 0C5.92857 0 6.66395 0.162694 7.30171 0.488082C7.93297 0.813471 8.42755 1.30806 8.77897 1.96535C9.13039 2.62263 9.3061 3.4361 9.3061 4.40576L9.3061 10.484L8.31041 10.484L8.31041 4.48385C8.31041 3.31245 8.01756 2.42089 7.42535 1.81567C6.83315 1.20394 6.00666 0.898072 4.9459 0.898072C4.13242 0.898072 3.42959 1.06077 2.83087 1.39917C2.23216 1.73757 1.77662 2.19312 1.46425 2.79183C1.15187 3.39055 0.995687 4.1064 0.995687 4.9459L0.995687 10.484L0 10.484L0 0.0846016L0.956642 0.0846016L0.956642 2.97405L0.819975 2.65517C1.13886 1.82868 1.66598 1.18441 2.39485 0.709346C3.11721 0.240787 4.01528 0 5.07605 0Z",
                  fill: "rgba(117,120,123,1)",
                  fillRule: "nonzero",
                },
              ]}
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              position="absolute"
              top="23.28%"
              bottom="21%"
              left="14.36%"
              right="82.35%"
              {...getOverrideProps(overrides, "Vector49141141")}
            ></Icon>
            <Icon
              width="9.95px"
              height="10.56px"
              viewBox={{
                minX: 0,
                minY: 0,
                width: 9.95036792755127,
                height: 10.562102317810059,
              }}
              paths={[
                {
                  d: "M5.36239 10.5621C4.31464 10.5621 3.38404 10.3343 2.58358 9.88529C1.77662 9.43626 1.14536 8.81151 0.689821 8.01106C0.234278 7.2106 0 6.30602 0 5.2778C0 4.24306 0.214756 3.32547 0.650776 2.53803C1.08029 1.75059 1.679 1.12584 2.4339 0.676808C3.1888 0.227772 4.04131 0 4.98494 0C5.92857 0 6.77458 0.221264 7.52297 0.657284C8.27137 1.0933 8.86357 1.71154 9.29959 2.49898C9.7291 3.28642 9.95037 4.20402 9.95037 5.23875C9.95037 5.2778 9.95037 5.32335 9.93735 5.36891C9.93084 5.41446 9.92434 5.46652 9.92434 5.51859L0.735371 5.51859L0.735371 4.74416L9.3842 4.74416L8.98722 5.12161C9.00024 4.30814 8.83104 3.58578 8.47962 2.94151C8.1282 2.29724 7.65313 1.78964 7.05441 1.4252C6.4557 1.06077 5.76588 0.878548 4.97844 0.878548C4.21052 0.878548 3.5207 1.06077 2.91548 1.4252C2.31025 1.78964 1.83519 2.29724 1.49027 2.94151C1.14536 3.58578 0.969651 4.32116 0.969651 5.14113L0.969651 5.32335C0.969651 6.17587 1.15838 6.92426 1.53583 7.58805C1.91328 8.24534 2.4339 8.75945 3.09769 9.12389C3.76148 9.48832 4.51639 9.67054 5.36891 9.67054C6.0327 9.67054 6.65744 9.5534 7.23013 9.31261C7.80932 9.07182 8.2974 8.70739 8.69437 8.21931L9.27356 8.87659C8.82453 9.43626 8.25184 9.85926 7.56852 10.1391C6.89822 10.4254 6.15634 10.5621 5.36239 10.5621Z",
                  fill: "rgba(117,120,123,1)",
                  fillRule: "nonzero",
                },
              ]}
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              position="absolute"
              top="23.28%"
              bottom="20.58%"
              left="18.7%"
              right="77.78%"
              {...getOverrideProps(overrides, "Vector49141142")}
            ></Icon>
            <Icon
              width="9.21px"
              height="10.57px"
              viewBox={{
                minX: 0,
                minY: 0,
                width: 9.214987754821777,
                height: 10.568610191345215,
              }}
              paths={[
                {
                  d: "M5.26478 10.5686C4.25608 10.5686 3.3515 10.3408 2.56406 9.8918C1.77662 9.44277 1.15187 8.81802 0.689821 8.01757C0.234278 7.21711 0 6.31253 0 5.28431C0 4.24957 0.22777 3.33198 0.689821 2.53152C1.14536 1.73757 1.77011 1.11283 2.56406 0.6703C3.3515 0.227772 4.25608 0 5.26478 0C6.10428 0 6.85918 0.156187 7.549 0.475068C8.23232 0.793948 8.78548 1.27552 9.21499 1.91328L8.47962 2.45343C8.10867 1.91979 7.64012 1.52933 7.08696 1.27552C6.52729 1.02172 5.92206 0.898072 5.27128 0.898072C4.45781 0.898072 3.73546 1.08029 3.09119 1.44472C2.44692 1.80916 1.93931 2.32327 1.57488 2.98056C1.21044 3.63784 1.02823 4.40576 1.02823 5.28431C1.02823 6.16285 1.21044 6.93077 1.57488 7.58805C1.93931 8.24534 2.44692 8.75945 3.09119 9.12389C3.73546 9.48832 4.46432 9.67054 5.27128 9.67054C5.92206 9.67054 6.52729 9.54689 7.08696 9.29309C7.64662 9.03929 8.10867 8.64882 8.47962 8.11518L9.21499 8.65533C8.79198 9.29309 8.23232 9.76816 7.549 10.0935C6.85918 10.4124 6.09777 10.5686 5.26478 10.5686Z",
                  fill: "rgba(117,120,123,1)",
                  fillRule: "nonzero",
                },
              ]}
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              position="absolute"
              top="23.24%"
              bottom="20.58%"
              left="22.91%"
              right="73.83%"
              {...getOverrideProps(overrides, "Vector49141143")}
            ></Icon>
            <Icon
              width="6.87px"
              height="12.76px"
              viewBox={{
                minX: 0,
                minY: 0,
                width: 6.872207164764404,
                height: 12.755218505859375,
              }}
              paths={[
                {
                  d: "M0 3.13023L0 2.27121L6.22143 2.27121L6.22143 3.13023L0 3.13023ZM4.73766 12.7552C3.82007 12.7552 3.11722 12.5014 2.63565 12.0003C2.14756 11.4927 1.90678 10.8029 1.90678 9.92434L1.90678 0L2.90247 0L2.90247 9.82673C2.90247 10.4905 3.06516 11.0046 3.40357 11.3626C3.73546 11.7205 4.21703 11.9027 4.84178 11.9027C5.49255 11.9027 6.03271 11.7075 6.45571 11.3235L6.87221 12.0198C6.60539 12.2736 6.2865 12.4559 5.90255 12.5795C5.5251 12.6966 5.14114 12.7552 4.73766 12.7552Z",
                  fill: "rgba(117,120,123,1)",
                  fillRule: "nonzero",
                },
              ]}
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              position="absolute"
              top="11.62%"
              bottom="20.58%"
              left="26.65%"
              right="70.92%"
              {...getOverrideProps(overrides, "Vector49141144")}
            ></Icon>
            <Icon
              width="1.63px"
              height="14.56px"
              viewBox={{
                minX: 0,
                minY: 0,
                width: 1.633445382118225,
                height: 14.557867050170898,
              }}
              paths={[
                {
                  d: "M0.819975 1.62043C0.592203 1.62043 0.403478 1.54234 0.240784 1.37965C0.07809 1.21695 0 1.03473 0 0.819978C0 0.592206 0.07809 0.403481 0.240784 0.240787C0.39697 0.0780931 0.592203 0 0.819975 0C1.06076 0 1.256 0.0780928 1.40568 0.227771C1.55536 0.37745 1.63345 0.572683 1.63345 0.793947C1.63345 1.02172 1.55536 1.21044 1.40568 1.37314C1.256 1.53583 1.06076 1.62043 0.819975 1.62043ZM0.325388 14.5514L0.325388 4.15195L1.32108 4.15195L1.32108 14.5579L0.325388 14.5579L0.325388 14.5514Z",
                  fill: "rgba(117,120,123,1)",
                  fillRule: "nonzero",
                },
              ]}
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              position="absolute"
              top="1.66%"
              bottom="20.96%"
              left="29.95%"
              right="69.48%"
              {...getOverrideProps(overrides, "Vector49141145")}
            ></Icon>
            <Icon
              width="9.31px"
              height="10.48px"
              viewBox={{
                minX: 0,
                minY: 0,
                width: 9.306105613708496,
                height: 10.484010696411133,
              }}
              paths={[
                {
                  d: "M5.07606 0C5.92858 0 6.66395 0.162694 7.30171 0.488082C7.93297 0.813471 8.42756 1.30806 8.77898 1.96535C9.1304 2.62263 9.30611 3.4361 9.30611 4.40576L9.30611 10.484L8.31042 10.484L8.31042 4.48385C8.31042 3.31245 8.01757 2.42089 7.42536 1.81567C6.83316 1.20394 6.00667 0.898072 4.94591 0.898072C4.13243 0.898072 3.4296 1.06077 2.83088 1.39917C2.23217 1.73757 1.77662 2.19312 1.46425 2.79183C1.15187 3.39055 0.995687 4.1064 0.995687 4.9459L0.995687 10.484L0 10.484L0 0.0846016L0.956642 0.0846016L0.956642 2.97405L0.819975 2.65517C1.13886 1.82868 1.66598 1.18441 2.39485 0.709346C3.11721 0.240787 4.01529 0 5.07606 0Z",
                  fill: "rgba(117,120,123,1)",
                  fillRule: "nonzero",
                },
              ]}
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              position="absolute"
              top="23.28%"
              bottom="21%"
              left="31.9%"
              right="64.81%"
              {...getOverrideProps(overrides, "Vector49141146")}
            ></Icon>
            <Icon
              width="10.51px"
              height="14.44px"
              viewBox={{
                minX: 0,
                minY: 0,
                width: 10.510035514831543,
                height: 14.440731048583984,
              }}
              paths={[
                {
                  d: "M5.18669 10.1131C4.20402 10.1131 3.32546 9.89831 2.54453 9.46229C1.7701 9.03278 1.15187 8.43406 0.689821 7.66615C0.234278 6.90474 0 6.02619 0 5.04352C0 4.04783 0.22777 3.16928 0.689821 2.41438C1.14536 1.65948 1.7636 1.06727 2.54453 0.637762C3.32546 0.208249 4.20402 0 5.18669 0C6.13031 0 6.97632 0.201741 7.72471 0.605222C8.47311 1.0087 9.07182 1.58789 9.50784 2.33629C9.94386 3.08468 10.1651 3.98926 10.1651 5.03701C10.1651 6.07175 9.94386 6.96982 9.50784 7.72472C9.07182 8.47962 8.47311 9.06532 7.72471 9.48181C6.97632 9.90482 6.13031 10.1131 5.18669 10.1131ZM5.46652 14.4407C4.52289 14.4407 3.62482 14.2911 2.77881 14.0047C1.92629 13.7119 1.23648 13.3084 0.70284 12.7878L1.24298 12.0329C1.7636 12.5144 2.38183 12.8854 3.1042 13.1457C3.82656 13.4125 4.60749 13.5427 5.447 13.5427C6.83966 13.5427 7.86788 13.2173 8.52517 12.5665C9.18245 11.9157 9.51435 10.894 9.51435 9.49483L9.51435 6.60538L9.71609 5.05003L9.55339 3.49467L9.55339 0.0846016L10.51 0.0846016L10.51 9.37118C10.51 11.1088 10.087 12.3908 9.24753 13.2108C8.39501 14.0307 7.13901 14.4407 5.46652 14.4407ZM5.26478 9.21499C6.10428 9.21499 6.83965 9.03929 7.47742 8.68787C8.11518 8.33645 8.61627 7.84186 8.98071 7.2106C9.34514 6.57935 9.52737 5.85699 9.52737 5.05003C9.52737 4.22354 9.34514 3.50118 8.98071 2.87643C8.61627 2.25169 8.11518 1.77011 7.47742 1.41869C6.83965 1.07378 6.10428 0.898072 5.26478 0.898072C4.45131 0.898072 3.72894 1.07378 3.08467 1.41869C2.44041 1.7636 1.9328 2.25169 1.56836 2.87643C1.20393 3.50118 1.02171 4.22354 1.02171 5.05003C1.02171 5.8635 1.20393 6.57935 1.56836 7.2106C1.9328 7.84186 2.44041 8.33645 3.08467 8.68787C3.72894 9.03929 4.45782 9.21499 5.26478 9.21499Z",
                  fill: "rgba(117,120,123,1)",
                  fillRule: "nonzero",
                },
              ]}
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              position="absolute"
              top="23.24%"
              bottom="0%"
              left="36.25%"
              right="60.04%"
              {...getOverrideProps(overrides, "Vector49141147")}
            ></Icon>
            <Icon
              width="6.86px"
              height="14.86px"
              viewBox={{
                minX: 0,
                minY: 0,
                width: 6.859189510345459,
                height: 14.863736152648926,
              }}
              paths={[
                {
                  d: "M0 5.31684L0 4.45782L6.22142 4.45782L6.22142 5.31684L0 5.31684ZM1.91328 14.8637L1.91328 2.86992C1.91328 2.00439 2.16058 1.31457 2.66167 0.78744C3.16277 0.260311 3.87212 0 4.80273 0C5.18669 0 5.56414 0.05857 5.94159 0.169202C6.31904 0.279834 6.6184 0.449036 6.85919 0.676808L6.46221 1.43171C6.26046 1.22997 6.0262 1.0868 5.74637 0.995688C5.46653 0.90458 5.16717 0.859025 4.84829 0.859025C4.21053 0.859025 3.72244 1.03474 3.39054 1.38615C3.05864 1.73757 2.88945 2.25819 2.88945 2.94802L2.88945 4.69861L2.90897 5.18018L2.90897 14.8637L1.91328 14.8637Z",
                  fill: "rgba(117,120,123,1)",
                  fillRule: "nonzero",
                },
              ]}
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              position="absolute"
              top="0%"
              bottom="21%"
              left="42.66%"
              right="54.92%"
              {...getOverrideProps(overrides, "Vector49141148")}
            ></Icon>
            <Icon
              width="10.41px"
              height="10.56px"
              viewBox={{
                minX: 0,
                minY: 0,
                width: 10.405928611755371,
                height: 10.562102317810059,
              }}
              paths={[
                {
                  d: "M5.20621 10.5621C4.21052 10.5621 3.32547 10.3343 2.54454 9.88529C1.77011 9.43626 1.15187 8.81151 0.689821 8.01106C0.234278 7.2106 0 6.30602 0 5.2778C0 4.24306 0.22777 3.32547 0.689821 2.53803C1.14536 1.75059 1.76361 1.12584 2.54454 0.676808C3.32547 0.227772 4.21052 0 5.20621 0C6.2019 0 7.09347 0.227772 7.8744 0.676808C8.65533 1.12584 9.27357 1.75059 9.72912 2.53803C10.1782 3.32547 10.4059 4.24306 10.4059 5.2778C10.4059 6.29952 10.1782 7.2106 9.72912 8.01106C9.28008 8.80501 8.66184 9.42975 7.8744 9.88529C7.09347 10.3408 6.2019 10.5621 5.20621 10.5621ZM5.20621 9.67054C6.00016 9.67054 6.72252 9.48832 7.36028 9.12389C7.99804 8.75945 8.49915 8.24534 8.85708 7.58155C9.215 6.91775 9.39721 6.14984 9.39721 5.29081C9.39721 4.41226 9.215 3.64435 8.85708 2.98706C8.49915 2.32978 7.99804 1.81567 7.36028 1.45123C6.72252 1.0868 6.00667 0.90458 5.20621 0.90458C4.40576 0.90458 3.69642 1.0868 3.06517 1.45123C2.43392 1.81567 1.93281 2.32978 1.56837 2.98706C1.20394 3.64435 1.02172 4.41226 1.02172 5.29081C1.02172 6.15635 1.20394 6.91775 1.56837 7.58155C1.93281 8.24534 2.43392 8.75945 3.06517 9.12389C3.69642 9.48832 4.40576 9.67054 5.20621 9.67054Z",
                  fill: "rgba(117,120,123,1)",
                  fillRule: "nonzero",
                },
              ]}
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              position="absolute"
              top="23.28%"
              bottom="20.58%"
              left="45.12%"
              right="51.2%"
              {...getOverrideProps(overrides, "Vector49141149")}
            ></Icon>
            <Icon
              width="4.93px"
              height="10.48px"
              viewBox={{
                minX: 0,
                minY: 0,
                width: 4.926362991333008,
                height: 10.4840087890625,
              }}
              paths={[
                {
                  d: "M0 10.4775L0 0.0780937L0.956642 0.0780937L0.956642 2.92849L0.85901 2.60961C1.15186 1.77011 1.63995 1.12584 2.33629 0.676808C3.02611 0.227772 3.89163 0 4.92636 0L4.92636 0.976165C4.88732 0.976165 4.84827 0.976165 4.80923 0.969657C4.77018 0.963149 4.73114 0.956642 4.69209 0.956642C3.53371 0.956642 2.62912 1.32108 1.97835 2.04344C1.32757 2.7658 1.00218 3.7745 1.00218 5.06304L1.00218 10.484L0 10.484L0 10.4775Z",
                  fill: "rgba(117,120,123,1)",
                  fillRule: "nonzero",
                },
              ]}
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              position="absolute"
              top="23.31%"
              bottom="20.96%"
              left="49.89%"
              right="48.37%"
              {...getOverrideProps(overrides, "Vector49141150")}
            ></Icon>
            <Icon
              width="11.36px"
              height="13.95px"
              viewBox={{
                minX: 0,
                minY: 0,
                width: 11.356057167053223,
                height: 13.95265007019043,
              }}
              paths={[
                {
                  d: "M0 13.9527L0 0L5.81794 0C7.32124 0 8.49264 0.305865 9.34516 0.917595C10.1977 1.52932 10.6207 2.41438 10.6207 3.56626C10.6207 4.32116 10.4385 4.95892 10.0805 5.47954C9.72261 6.00016 9.22802 6.38412 8.59677 6.64443C7.96552 6.90474 7.25617 7.03489 6.47523 7.03489L6.83315 6.63792C7.78979 6.63792 8.60979 6.77458 9.28659 7.05442C9.9634 7.33425 10.4775 7.73773 10.8289 8.27137C11.1803 8.80501 11.3561 9.4688 11.3561 10.2627C11.3561 11.4341 10.9331 12.3387 10.0805 12.983C9.22802 13.6273 7.93948 13.9527 6.21493 13.9527L0 13.9527ZM1.02172 13.0741L6.22142 13.0741C7.56202 13.0741 8.58374 12.8463 9.29309 12.3843C9.99593 11.9287 10.3473 11.1934 10.3473 10.1847C10.3473 9.18896 9.99593 8.4601 9.29309 8.00455C8.59025 7.54901 7.56853 7.31473 6.22142 7.31473L0.898075 7.31473L0.898075 6.43618L5.7789 6.43618C6.98934 6.43618 7.92647 6.2019 8.59026 5.73985C9.25405 5.2778 9.58594 4.57496 9.58594 3.64435C9.58594 2.72675 9.25405 2.03693 8.59026 1.56837C7.92647 1.10632 6.98934 0.872041 5.7789 0.872041L1.01521 0.872041L1.01521 13.0741L1.02172 13.0741Z",
                  fill: "rgba(117,120,123,1)",
                  fillRule: "nonzero",
                },
              ]}
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              position="absolute"
              top="4.84%"
              bottom="21%"
              left="54.59%"
              right="41.4%"
              {...getOverrideProps(overrides, "Vector49141151")}
            ></Icon>
            <Icon
              width="9.96px"
              height="10.56px"
              viewBox={{
                minX: 0,
                minY: 0,
                width: 9.956871032714844,
                height: 10.562102317810059,
              }}
              paths={[
                {
                  d: "M5.36239 10.5621C4.31464 10.5621 3.38404 10.3343 2.58358 9.88529C1.77662 9.43626 1.14536 8.81151 0.689821 8.01106C0.234278 7.2106 0 6.30602 0 5.2778C0 4.24306 0.214756 3.32547 0.650776 2.53803C1.0868 1.75059 1.679 1.12584 2.4339 0.676808C3.1888 0.227772 4.04132 0 4.98495 0C5.92858 0 6.77457 0.221264 7.52947 0.657284C8.27786 1.0933 8.87007 1.71154 9.30609 2.49898C9.73561 3.28642 9.95687 4.20402 9.95687 5.23875C9.95687 5.2778 9.95688 5.32335 9.94386 5.36891C9.93736 5.41446 9.93735 5.46652 9.93735 5.51859L0.748389 5.51859L0.748389 4.74416L9.39721 4.74416L9.00023 5.12161C9.01324 4.30814 8.84404 3.58578 8.49262 2.94151C8.1412 2.29724 7.66614 1.78964 7.06742 1.4252C6.46871 1.06077 5.77888 0.878548 4.99144 0.878548C4.22353 0.878548 3.53372 1.06077 2.92849 1.4252C2.32327 1.78964 1.8482 2.29724 1.50329 2.94151C1.15838 3.58578 0.982659 4.32116 0.982659 5.14113L0.982659 5.32335C0.982659 6.17587 1.1714 6.92426 1.54885 7.58805C1.9263 8.24534 2.44692 8.75945 3.11071 9.12389C3.7745 9.48832 4.5294 9.67054 5.38191 9.67054C6.04571 9.67054 6.67046 9.5534 7.24314 9.31261C7.82234 9.07182 8.31042 8.70739 8.70739 8.21931L9.28657 8.87659C8.83754 9.43626 8.26486 9.85926 7.58154 10.1391C6.89823 10.4254 6.15634 10.5621 5.36239 10.5621Z",
                  fill: "rgba(117,120,123,1)",
                  fillRule: "nonzero",
                },
              ]}
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              position="absolute"
              top="23.28%"
              bottom="20.58%"
              left="59.37%"
              right="37.11%"
              {...getOverrideProps(overrides, "Vector49141152")}
            ></Icon>
            <Icon
              width="6.88px"
              height="12.76px"
              viewBox={{
                minX: 0,
                minY: 0,
                width: 6.878712177276611,
                height: 12.755218505859375,
              }}
              paths={[
                {
                  d: "M0 3.13023L0 2.27121L6.22142 2.27121L6.22142 3.13023L0 3.13023ZM4.74417 12.7552C3.82657 12.7552 3.12373 12.5014 2.64215 12.0003C2.15407 11.4927 1.91328 10.8029 1.91328 9.92434L1.91328 0L2.90897 0L2.90897 9.82673C2.90897 10.4905 3.07168 11.0046 3.41008 11.3626C3.74198 11.7205 4.22355 11.9027 4.84829 11.9027C5.49907 11.9027 6.0392 11.7075 6.46221 11.3235L6.87871 12.0198C6.61189 12.2736 6.29302 12.4559 5.90906 12.5795C5.53161 12.6966 5.14765 12.7552 4.74417 12.7552Z",
                  fill: "rgba(117,120,123,1)",
                  fillRule: "nonzero",
                },
              ]}
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              position="absolute"
              top="11.62%"
              bottom="20.58%"
              left="63.37%"
              right="34.2%"
              {...getOverrideProps(overrides, "Vector49141153")}
            ></Icon>
            <Icon
              width="6.88px"
              height="12.76px"
              viewBox={{
                minX: 0,
                minY: 0,
                width: 6.878712177276611,
                height: 12.755218505859375,
              }}
              paths={[
                {
                  d: "M0 3.13023L0 2.27121L6.21491 2.27121L6.21491 3.13023L0 3.13023ZM4.74417 12.7552C3.82657 12.7552 3.12373 12.5014 2.64215 12.0003C2.15407 11.4927 1.91328 10.8029 1.91328 9.92434L1.91328 0L2.90897 0L2.90897 9.82673C2.90897 10.4905 3.07166 11.0046 3.41006 11.3626C3.74196 11.7205 4.22353 11.9027 4.84827 11.9027C5.49905 11.9027 6.0392 11.7075 6.46221 11.3235L6.87871 12.0198C6.61189 12.2736 6.293 12.4559 5.90904 12.5795C5.53159 12.6966 5.14765 12.7552 4.74417 12.7552Z",
                  fill: "rgba(117,120,123,1)",
                  fillRule: "nonzero",
                },
              ]}
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              position="absolute"
              top="11.62%"
              bottom="20.58%"
              left="66.08%"
              right="31.49%"
              {...getOverrideProps(overrides, "Vector49141154")}
            ></Icon>
            <Icon
              width="9.95px"
              height="10.56px"
              viewBox={{
                minX: 0,
                minY: 0,
                width: 9.950377464294434,
                height: 10.562102317810059,
              }}
              paths={[
                {
                  d: "M5.36239 10.5621C4.31464 10.5621 3.38404 10.3343 2.58358 9.88529C1.77662 9.43626 1.14536 8.81151 0.689821 8.01106C0.234278 7.2106 0 6.30602 0 5.2778C0 4.24306 0.214756 3.32547 0.650776 2.53803C1.08029 1.75059 1.679 1.12584 2.4339 0.676808C3.1888 0.227772 4.04132 0 4.98495 0C5.92858 0 6.77458 0.221264 7.52297 0.657284C8.27137 1.0933 8.86358 1.71154 9.2996 2.49898C9.72911 3.28642 9.95038 4.20402 9.95038 5.23875C9.95038 5.2778 9.95037 5.32335 9.94386 5.36891C9.93736 5.41446 9.93085 5.46652 9.93085 5.51859L0.741875 5.51859L0.741875 4.74416L9.3907 4.74416L8.99374 5.12161C9.00675 4.30814 8.83755 3.58578 8.48613 2.94151C8.13471 2.29724 7.65964 1.78964 7.06093 1.4252C6.46221 1.06077 5.77238 0.878548 4.99144 0.878548C4.22353 0.878548 3.53372 1.06077 2.92849 1.4252C2.32327 1.78964 1.8482 2.29724 1.50329 2.94151C1.15838 3.58578 0.982679 4.32116 0.982679 5.14113L0.982679 5.32335C0.982679 6.17587 1.1714 6.92426 1.54885 7.58805C1.9263 8.25185 2.44692 8.75945 3.11071 9.12389C3.7745 9.48832 4.5294 9.67054 5.38191 9.67054C6.04571 9.67054 6.67046 9.5534 7.24314 9.31261C7.81583 9.07182 8.31042 8.70739 8.70739 8.21931L9.28657 8.87659C8.83103 9.43626 8.26486 9.85926 7.58154 10.1391C6.89823 10.4254 6.15634 10.5621 5.36239 10.5621Z",
                  fill: "rgba(117,120,123,1)",
                  fillRule: "nonzero",
                },
              ]}
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              position="absolute"
              top="23.28%"
              bottom="20.58%"
              left="68.96%"
              right="27.52%"
              {...getOverrideProps(overrides, "Vector49141155")}
            ></Icon>
            <Icon
              width="4.93px"
              height="10.48px"
              viewBox={{
                minX: 0,
                minY: 0,
                width: 4.926382541656494,
                height: 10.4840087890625,
              }}
              paths={[
                {
                  d: "M0 10.4775L0 0.0780937L0.956642 0.0780937L0.956642 2.92849L0.85903 2.60961C1.15188 1.77011 1.63995 1.12584 2.33629 0.676808C3.02611 0.227772 3.89165 0 4.92638 0L4.92638 0.976165C4.88734 0.976165 4.84827 0.976165 4.80923 0.969657C4.77018 0.963149 4.73114 0.956642 4.69209 0.956642C3.53371 0.956642 2.62914 1.32108 1.97837 2.04344C1.32759 2.7658 1.0022 3.7745 1.0022 5.06304L1.0022 10.484L0 10.484L0 10.4775Z",
                  fill: "rgba(117,120,123,1)",
                  fillRule: "nonzero",
                },
              ]}
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              position="absolute"
              top="23.31%"
              bottom="20.96%"
              left="73.57%"
              right="24.69%"
              {...getOverrideProps(overrides, "Vector49141156")}
            ></Icon>
            <Icon
              width="11.36px"
              height="13.95px"
              viewBox={{
                minX: 0,
                minY: 0,
                width: 11.356057167053223,
                height: 13.95265007019043,
              }}
              paths={[
                {
                  d: "M1.01521 13.9527L0 13.9527L0 0L1.01521 0L1.01521 13.9527ZM10.458 7.31473L0.891561 7.31473L0.891561 6.41666L10.458 6.41666L10.458 7.31473ZM10.3408 0L11.3561 0L11.3561 13.9527L10.3408 13.9527L10.3408 0Z",
                  fill: "rgba(117,120,123,1)",
                  fillRule: "nonzero",
                },
              ]}
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              position="absolute"
              top="4.84%"
              bottom="21%"
              left="78.27%"
              right="17.72%"
              {...getOverrideProps(overrides, "Vector49141157")}
            ></Icon>
            <Icon
              width="9.96px"
              height="10.56px"
              viewBox={{
                minX: 0,
                minY: 0,
                width: 9.956892013549805,
                height: 10.562102317810059,
              }}
              paths={[
                {
                  d: "M5.36239 10.5621C4.31464 10.5621 3.38404 10.3343 2.58358 9.88529C1.77662 9.43626 1.14536 8.81151 0.689821 8.01106C0.234278 7.2106 0 6.30602 0 5.2778C0 4.24306 0.214756 3.32547 0.650777 2.53803C1.0868 1.75059 1.679 1.12584 2.4339 0.676808C3.1888 0.227772 4.04132 0 4.98495 0C5.92858 0 6.77459 0.221264 7.52949 0.657284C8.27788 1.0933 8.8701 1.71154 9.30612 2.49898C9.73563 3.28642 9.95689 4.20402 9.95689 5.23875C9.95689 5.2778 9.95688 5.32335 9.94386 5.36891C9.93736 5.41446 9.93735 5.46652 9.93735 5.51859L0.748389 5.51859L0.748389 4.74416L9.39721 4.74416L9.00025 5.12161C9.01327 4.30814 8.84406 3.58578 8.49265 2.94151C8.14123 2.29724 7.66616 1.78964 7.06744 1.4252C6.46873 1.06077 5.7789 0.878548 4.99146 0.878548C4.22355 0.878548 3.53372 1.06077 2.92849 1.4252C2.32327 1.78964 1.8482 2.29724 1.50329 2.94151C1.15838 3.58578 0.982679 4.32116 0.982679 5.14113L0.982679 5.32335C0.982679 6.17587 1.1714 6.92426 1.54885 7.58805C1.9263 8.24534 2.44692 8.75945 3.11071 9.12389C3.7745 9.48832 4.5294 9.67054 5.38191 9.67054C6.04571 9.67054 6.67046 9.5534 7.24315 9.31261C7.82234 9.07182 8.31042 8.70739 8.70739 8.21931L9.28657 8.87659C8.83754 9.43626 8.26486 9.85926 7.58154 10.1391C6.89823 10.4254 6.15634 10.5621 5.36239 10.5621Z",
                  fill: "rgba(117,120,123,1)",
                  fillRule: "nonzero",
                },
              ]}
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              position="absolute"
              top="23.28%"
              bottom="20.58%"
              left="83.49%"
              right="12.99%"
              {...getOverrideProps(overrides, "Vector49141158")}
            ></Icon>
            <Icon
              width="8.44px"
              height="10.56px"
              viewBox={{
                minX: 0,
                minY: 0,
                width: 8.440571784973145,
                height: 10.555593490600586,
              }}
              paths={[
                {
                  d: "M3.74848 10.5556C2.98056 10.5556 2.31025 10.4319 1.74408 10.1847C1.1779 9.93736 0.748395 9.59244 0.449037 9.1369C0.14968 8.68787 0 8.16724 0 7.58155C0 7.04791 0.123652 6.56633 0.370947 6.13682C0.618242 5.70731 1.02823 5.35589 1.59441 5.08256C2.16709 4.80924 2.9285 4.67257 3.88514 4.67257L7.71171 4.67257L7.71171 5.447L3.90466 5.447C2.83088 5.447 2.07597 5.64874 1.63995 6.04571C1.21044 6.44269 0.989173 6.94378 0.989173 7.5425C0.989173 8.20629 1.24298 8.73993 1.74408 9.1369C2.25168 9.53387 2.96103 9.73561 3.87862 9.73561C4.75717 9.73561 5.49906 9.53387 6.10429 9.1369C6.70951 8.73993 7.15855 8.16074 7.4514 7.40584L7.73123 8.08264C7.45139 8.83754 6.97633 9.44276 6.30603 9.88529C5.63573 10.3343 4.78321 10.5556 3.74848 10.5556ZM7.49044 10.4775L7.49044 8.10867L7.4514 7.75075L7.4514 3.84609C7.4514 2.87643 7.19107 2.14105 6.66395 1.63345C6.13682 1.12584 5.36891 0.878548 4.36021 0.878548C3.64436 0.878548 2.97405 1.0022 2.35581 1.24949C1.73757 1.49679 1.21045 1.80916 0.774426 2.19312L0.273335 1.47726C0.793956 1.01521 1.41868 0.650777 2.14755 0.390466C2.87642 0.130156 3.64435 0 4.4383 0C5.72684 0 6.71602 0.325388 7.40584 0.976165C8.09566 1.62694 8.44057 2.5966 8.44057 3.88514L8.44057 10.484L7.49044 10.484L7.49044 10.4775Z",
                  fill: "rgba(117,120,123,1)",
                  fillRule: "nonzero",
                },
              ]}
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              position="absolute"
              top="23.31%"
              bottom="20.58%"
              left="87.67%"
              right="9.35%"
              {...getOverrideProps(overrides, "Vector49141159")}
            ></Icon>
            <Icon
              width="1px"
              height="14.79px"
              viewBox={{
                minX: 0,
                minY: 0,
                width: 0.9956872463226318,
                height: 14.78564167022705,
              }}
              paths={[
                {
                  d: "M0 14.7856L0 0L0.995687 0L0.995687 14.7856L0 14.7856Z",
                  fill: "rgba(117,120,123,1)",
                  fillRule: "nonzero",
                },
              ]}
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              position="absolute"
              top="0.41%"
              bottom="21%"
              left="92.11%"
              right="7.54%"
              {...getOverrideProps(overrides, "Vector49141160")}
            ></Icon>
            <Icon
              width="6.88px"
              height="12.76px"
              viewBox={{
                minX: 0,
                minY: 0,
                width: 6.878712177276611,
                height: 12.755218505859375,
              }}
              paths={[
                {
                  d: "M0 3.13023L0 2.27121L6.21491 2.27121L6.21491 3.13023L0 3.13023ZM4.74417 12.7552C3.82657 12.7552 3.12373 12.5014 2.64215 12.0003C2.15407 11.4927 1.91328 10.8029 1.91328 9.92434L1.91328 0L2.90897 0L2.90897 9.82673C2.90897 10.4905 3.07166 11.0046 3.41006 11.3626C3.74196 11.7205 4.22355 11.9027 4.84829 11.9027C5.49907 11.9027 6.0392 11.7075 6.46221 11.3235L6.87871 12.0198C6.61189 12.2736 6.293 12.4559 5.90904 12.5795C5.53159 12.6966 5.14114 12.7552 4.74417 12.7552Z",
                  fill: "rgba(117,120,123,1)",
                  fillRule: "nonzero",
                },
              ]}
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              position="absolute"
              top="11.62%"
              bottom="20.58%"
              left="93.3%"
              right="4.27%"
              {...getOverrideProps(overrides, "Vector49141161")}
            ></Icon>
            <Icon
              width="9.31px"
              height="14.79px"
              viewBox={{
                minX: 0,
                minY: 0,
                width: 9.306116104125977,
                height: 14.78564167022705,
              }}
              paths={[
                {
                  d: "M5.07607 4.30163C5.92859 4.30163 6.66395 4.46433 7.30171 4.78971C7.93297 5.1151 8.42106 5.60969 8.77899 6.26698C9.13041 6.92426 9.30612 7.73773 9.30612 8.70739L9.30612 14.7856L8.31043 14.7856L8.31043 8.78548C8.31043 7.61409 8.01757 6.72252 7.42536 6.1173C6.83316 5.50557 6.00667 5.1997 4.94591 5.1997C4.13243 5.1997 3.4296 5.3624 2.83088 5.7008C2.23217 6.03921 1.77662 6.49475 1.46425 7.09346C1.15187 7.69218 0.995687 8.40803 0.995687 9.24753L0.995687 14.7856L0 14.7856L0 0L0.995687 0L0.995687 7.27568L0.813471 6.9568C1.13235 6.13031 1.65949 5.48605 2.38836 5.01098C3.11723 4.53591 4.0153 4.30163 5.07607 4.30163Z",
                  fill: "rgba(117,120,123,1)",
                  fillRule: "nonzero",
                },
              ]}
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              position="absolute"
              top="0.41%"
              bottom="21%"
              left="96.71%"
              right="0%"
              {...getOverrideProps(overrides, "Vector49141162")}
            ></Icon>
          </View>
        </View>
        <View
          padding="0px 0px 0px 0px"
          width="36.9px"
          height="36.9px"
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          position="absolute"
          top="0%"
          bottom="0%"
          left="0%"
          right="88.68%"
          {...getOverrideProps(overrides, "Group49141163")}
        >
          <Icon
            width="36.9px"
            height="36.9px"
            viewBox={{
              minX: 0,
              minY: 0,
              width: 36.89902877807617,
              height: 36.899024963378906,
            }}
            paths={[
              {
                d: "M36.899 18.4495C36.899 28.6407 28.6407 36.899 18.4495 36.899C8.25835 36.899 0 28.6407 0 18.4495C0 8.25835 8.25835 0 18.4495 0C28.6407 0 36.899 8.25835 36.899 18.4495Z",
                fill: "rgba(79,144,205,1)",
                fillRule: "nonzero",
              },
            ]}
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            position="absolute"
            top="0%"
            bottom="0%"
            left="0%"
            right="0%"
            {...getOverrideProps(overrides, "Vector49141164")}
          ></Icon>
          <Icon
            width="0.98px"
            height="0.98px"
            viewBox={{
              minX: 0,
              minY: 0,
              width: 0.976164698600769,
              height: 0.9826724529266357,
            }}
            paths={[
              {
                d: "M0 0.49459C0 0.221264 0.214756 0 0.488082 0C0.754901 0 0.976165 0.221264 0.976165 0.49459C0.976165 0.767916 0.754901 0.982673 0.488082 0.982673C0.221264 0.982673 0 0.761409 0 0.49459Z",
                fill: "rgba(255,255,255,1)",
                fillRule: "nonzero",
              },
            ]}
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            position="absolute"
            top="48.66%"
            bottom="48.68%"
            left="89.29%"
            right="8.06%"
            {...getOverrideProps(overrides, "Vector49141165")}
          ></Icon>
          <Icon
            width="1.15px"
            height="1.15px"
            viewBox={{
              minX: 0,
              minY: 0,
              width: 1.1453654766082764,
              height: 1.1453653573989868,
            }}
            paths={[
              {
                d: "M0 0.572683C0 0.253802 0.253803 0 0.572684 0C0.891564 0 1.14537 0.26031 1.14537 0.572683C1.14537 0.891563 0.885057 1.14537 0.572684 1.14537C0.253803 1.14537 0 0.891563 0 0.572683Z",
                fill: "rgba(255,255,255,1)",
                fillRule: "nonzero",
              },
            ]}
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            position="absolute"
            top="48.45%"
            bottom="48.45%"
            left="82.36%"
            right="14.53%"
            {...getOverrideProps(overrides, "Vector49141166")}
          ></Icon>
          <Icon
            width="1.31px"
            height="1.31px"
            viewBox={{
              minX: 0,
              minY: 0,
              width: 1.3145687580108643,
              height: 1.3145687580108643,
            }}
            paths={[
              {
                d: "M0 0.657284C0 0.29285 0.292851 0 0.657286 0C1.02172 0 1.31457 0.29285 1.31457 0.657284C1.31457 1.02172 1.02172 1.31457 0.657286 1.31457C0.292851 1.31457 0 1.02172 0 0.657284Z",
                fill: "rgba(255,255,255,1)",
                fillRule: "nonzero",
              },
            ]}
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            position="absolute"
            top="48.22%"
            bottom="48.22%"
            left="74.36%"
            right="22.08%"
            {...getOverrideProps(overrides, "Vector49141167")}
          ></Icon>
          <Icon
            width="1.48px"
            height="1.48px"
            viewBox={{
              minX: 0,
              minY: 0,
              width: 1.4837695360183716,
              height: 1.483769416809082,
            }}
            paths={[
              {
                d: "M0 0.741885C0 0.331896 0.331896 0 0.741885 0C1.15187 0 1.48377 0.331896 1.48377 0.741885C1.48377 1.15187 1.15187 1.48377 0.741885 1.48377C0.338403 1.48377 0 1.15187 0 0.741885Z",
                fill: "rgba(255,255,255,1)",
                fillRule: "nonzero",
              },
            ]}
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            position="absolute"
            top="47.99%"
            bottom="47.99%"
            left="66.42%"
            right="29.56%"
            {...getOverrideProps(overrides, "Vector49141168")}
          ></Icon>
          <Icon
            width="1.65px"
            height="1.65px"
            viewBox={{
              minX: 0,
              minY: 0,
              width: 1.6529728174209595,
              height: 1.65297269821167,
            }}
            paths={[
              {
                d: "M0 0.826486C0 0.370943 0.370943 0 0.826486 0C1.28203 0 1.65297 0.370943 1.65297 0.826486C1.65297 1.28203 1.28203 1.65297 0.826486 1.65297C0.364435 1.65297 0 1.28203 0 0.826486Z",
                fill: "rgba(255,255,255,1)",
                fillRule: "nonzero",
              },
            ]}
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            position="absolute"
            top="47.76%"
            bottom="47.76%"
            left="57.37%"
            right="38.15%"
            {...getOverrideProps(overrides, "Vector49141169")}
          ></Icon>
          <Icon
            width="0.99px"
            height="0.98px"
            viewBox={{
              minX: 0,
              minY: 0,
              width: 0.9869553446769714,
              height: 0.983277440071106,
            }}
            paths={[
              {
                d: "M0.0379337 0.675694C-0.0661906 0.428399 0.0509491 0.13555 0.304752 0.0379337C0.552047 -0.0661906 0.844897 0.0509491 0.949022 0.304752C1.05315 0.552047 0.936006 0.844896 0.682203 0.94902C0.421893 1.04664 0.13555 0.929497 0.0379337 0.675694Z",
                fill: "rgba(255,255,255,1)",
                fillRule: "nonzero",
              },
            ]}
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            position="absolute"
            top="33.12%"
            bottom="64.21%"
            left="86.21%"
            right="11.11%"
            {...getOverrideProps(overrides, "Vector49141170")}
          ></Icon>
          <Icon
            width="1.15px"
            height="1.15px"
            viewBox={{
              minX: 0,
              minY: 0,
              width: 1.1498790979385376,
              height: 1.1535732746124268,
            }}
            paths={[
              {
                d: "M0.0445563 0.792949C-0.0790912 0.5001 0.0640793 0.161696 0.356929 0.0445563C0.649778 -0.0790912 0.988183 0.0640793 1.10532 0.356929C1.22897 0.649778 1.0858 0.988182 0.79295 1.11183C0.506609 1.22897 0.168204 1.09231 0.0445563 0.792949Z",
                fill: "rgba(255,255,255,1)",
                fillRule: "nonzero",
              },
            ]}
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            position="absolute"
            top="35.45%"
            bottom="61.42%"
            left="79.77%"
            right="17.11%"
            {...getOverrideProps(overrides, "Vector49141171")}
          ></Icon>
          <Icon
            width="1.32px"
            height="1.32px"
            viewBox={{
              minX: 0,
              minY: 0,
              width: 1.315630555152893,
              height: 1.3183611631393433,
            }}
            paths={[
              {
                d: "M0.0493396 0.908364C-0.0873234 0.56996 0.0688625 0.186002 0.407266 0.0493394C0.74567 -0.0873236 1.12963 0.0688635 1.26629 0.407267C1.40295 0.745671 1.24677 1.12963 0.908364 1.26629C0.576468 1.40946 0.186003 1.24677 0.0493396 0.908364Z",
                fill: "rgba(255,255,255,1)",
                fillRule: "nonzero",
              },
            ]}
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            position="absolute"
            top="38.21%"
            bottom="58.22%"
            left="72.37%"
            right="24.06%"
            {...getOverrideProps(overrides, "Vector49141172")}
          ></Icon>
          <Icon
            width="1.49px"
            height="1.49px"
            viewBox={{
              minX: 0,
              minY: 0,
              width: 1.485957145690918,
              height: 1.486840844154358,
            }}
            paths={[
              {
                d: "M0.0568513 1.02651C-0.0993351 0.649058 0.0763764 0.213038 0.460334 0.0568517C0.837785 -0.0993347 1.2738 0.0763743 1.42999 0.460332C1.58618 0.837783 1.40396 1.2738 1.02651 1.42999C0.642551 1.58618 0.213038 1.41047 0.0568513 1.02651Z",
                fill: "rgba(255,255,255,1)",
                fillRule: "nonzero",
              },
            ]}
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            position="absolute"
            top="40.92%"
            bottom="55.05%"
            left="65.03%"
            right="30.94%"
            {...getOverrideProps(overrides, "Vector49141173")}
          ></Icon>
          <Icon
            width="0.98px"
            height="0.98px"
            viewBox={{
              minX: 0,
              minY: 0,
              width: 0.9794207811355591,
              height: 0.9842991828918457,
            }}
            paths={[
              {
                d: "M0.141544 0.837874C-0.0471813 0.649149 -0.0471813 0.336777 0.141544 0.141544C0.330269 -0.0471813 0.642644 -0.0471813 0.837877 0.141544C1.0266 0.336777 1.0266 0.642642 0.837877 0.837874C0.649152 1.03311 0.336777 1.03311 0.141544 0.837874Z",
                fill: "rgba(255,255,255,1)",
                fillRule: "nonzero",
              },
            ]}
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            position="absolute"
            top="19.93%"
            bottom="77.4%"
            left="77.39%"
            right="19.95%"
            {...getOverrideProps(overrides, "Vector49141174")}
          ></Icon>
          <Icon
            width="1.15px"
            height="1.15px"
            viewBox={{
              minX: 0,
              minY: 0,
              width: 1.1453665494918823,
              height: 1.15024733543396,
            }}
            paths={[
              {
                d: "M0.165948 0.979418C-0.055316 0.758155 -0.055316 0.39372 0.165948 0.165948C0.39372 -0.055316 0.758155 -0.055316 0.979419 0.165948C1.20068 0.39372 1.20068 0.751647 0.979419 0.979418C0.758155 1.20719 0.39372 1.20719 0.165948 0.979418Z",
                fill: "rgba(255,255,255,1)",
                fillRule: "nonzero",
              },
            ]}
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            position="absolute"
            top="24.45%"
            bottom="72.43%"
            left="72.43%"
            right="24.47%"
            {...getOverrideProps(overrides, "Vector49141175")}
          ></Icon>
          <Icon
            width="1.32px"
            height="1.32px"
            viewBox={{
              minX: 0,
              minY: 0,
              width: 1.3210763931274414,
              height: 1.3186436891555786,
            }}
            paths={[
              {
                d: "M0.195233 1.12341C-0.0650776 0.8631 -0.0650776 0.446603 0.195233 0.1928C0.449036 -0.0610025 0.865533 -0.0675103 1.12584 0.1928C1.38615 0.446603 1.38615 0.869608 1.12584 1.12341C0.872041 1.38372 0.455544 1.38372 0.195233 1.12341Z",
                fill: "rgba(255,255,255,1)",
                fillRule: "nonzero",
              },
            ]}
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            position="absolute"
            top="29.72%"
            bottom="66.7%"
            left="66.68%"
            right="29.74%"
            {...getOverrideProps(overrides, "Vector49141176")}
          ></Icon>
          <Icon
            width="1.48px"
            height="1.49px"
            viewBox={{
              minX: 0,
              minY: 0,
              width: 1.484591007232666,
              height: 1.4935322999954224,
            }}
            paths={[
              {
                d: "M0.219637 1.2739C-0.0732124 0.981046 -0.0732124 0.512486 0.219637 0.219637C0.505979 -0.0732123 0.981046 -0.0732123 1.26739 0.219637C1.56024 0.505979 1.55373 0.981046 1.26739 1.2739C0.974538 1.56674 0.505979 1.56674 0.219637 1.2739Z",
                fill: "rgba(255,255,255,1)",
                fillRule: "nonzero",
              },
            ]}
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            position="absolute"
            top="34.93%"
            bottom="61.03%"
            left="61.03%"
            right="34.95%"
            {...getOverrideProps(overrides, "Vector49141177")}
          ></Icon>
          <Icon
            width="1.66px"
            height="1.66px"
            viewBox={{
              minX: 0,
              minY: 0,
              width: 1.6570451259613037,
              height: 1.6594794988632202,
            }}
            paths={[
              {
                d: "M0.241607 1.41544C-0.0772735 1.09005 -0.0837813 0.569429 0.241607 0.244041C0.566995 -0.0813471 1.08762 -0.0813471 1.413 0.244041C1.73839 0.569429 1.73839 1.09005 1.413 1.41544C1.08762 1.74083 0.566995 1.74083 0.241607 1.41544Z",
                fill: "rgba(255,255,255,1)",
                fillRule: "nonzero",
              },
            ]}
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            position="absolute"
            top="40.94%"
            bottom="54.56%"
            left="54.55%"
            right="40.96%"
            {...getOverrideProps(overrides, "Vector49141178")}
          ></Icon>
          <Icon
            width="0.98px"
            height="0.98px"
            viewBox={{
              minX: 0,
              minY: 0,
              width: 0.9804461598396301,
              height: 0.9796635508537292,
            }}
            paths={[
              {
                d: "M0.304752 0.941631C0.0509491 0.837507 -0.0661906 0.551165 0.0379337 0.30387C0.142058 0.0565754 0.428399 -0.067072 0.675694 0.0370522C0.929497 0.141176 1.04664 0.427518 0.942513 0.681321C0.838388 0.928616 0.552047 1.04576 0.304752 0.941631Z",
                fill: "rgba(255,255,255,1)",
                fillRule: "nonzero",
              },
            ]}
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            position="absolute"
            top="11.13%"
            bottom="86.21%"
            left="64.22%"
            right="33.12%"
            {...getOverrideProps(overrides, "Vector49141179")}
          ></Icon>
          <Icon
            width="1.14px"
            height="1.15px"
            viewBox={{
              minX: 0,
              minY: 0,
              width: 1.1444380283355713,
              height: 1.1481558084487915,
            }}
            paths={[
              {
                d: "M0.354208 1.1026C0.0613589 0.978954 -0.0753039 0.647058 0.0418359 0.354209C0.165483 0.0613594 0.49738 -0.075304 0.79023 0.0418358C1.08308 0.165483 1.21974 0.497379 1.1026 0.796736C0.985462 1.08959 0.647058 1.22625 0.354208 1.1026Z",
                fill: "rgba(255,255,255,1)",
                fillRule: "nonzero",
              },
            ]}
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            position="absolute"
            top="17.1%"
            bottom="79.79%"
            left="61.42%"
            right="35.48%"
            {...getOverrideProps(overrides, "Vector49141180")}
          ></Icon>
          <Icon
            width="1.32px"
            height="1.32px"
            viewBox={{
              minX: 0,
              minY: 0,
              width: 1.315630555152893,
              height: 1.3156309127807617,
            }}
            paths={[
              {
                d: "M0.407266 1.26629C0.0688625 1.12963 -0.0873234 0.739163 0.0493396 0.407267C0.186003 0.068863 0.576468 -0.0873235 0.908364 0.0493395C1.24677 0.186003 1.40295 0.576468 1.26629 0.908364C1.12963 1.24677 0.74567 1.40295 0.407266 1.26629Z",
                fill: "rgba(255,255,255,1)",
                fillRule: "nonzero",
              },
            ]}
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            position="absolute"
            top="24.06%"
            bottom="72.37%"
            left="58.21%"
            right="38.23%"
            {...getOverrideProps(overrides, "Vector49141181")}
          ></Icon>
          <Icon
            width="1.49px"
            height="1.49px"
            viewBox={{
              minX: 0,
              minY: 0,
              width: 1.4850727319717407,
              height: 1.4850716590881348,
            }}
            paths={[
              {
                d: "M0.459448 1.4291C0.0819976 1.27292 -0.100219 0.836898 0.0559672 0.459448C0.212154 0.0819975 0.648172 -0.100219 1.02562 0.0559672C1.40307 0.212153 1.58529 0.648174 1.42911 1.02562C1.27292 1.40307 0.836898 1.58529 0.459448 1.4291Z",
                fill: "rgba(255,255,255,1)",
                fillRule: "nonzero",
              },
            ]}
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            position="absolute"
            top="30.94%"
            bottom="65.03%"
            left="55.03%"
            right="40.94%"
            {...getOverrideProps(overrides, "Vector49141182")}
          ></Icon>
          <Icon
            width="0.99px"
            height="0.98px"
            viewBox={{
              minX: 0,
              minY: 0,
              width: 0.9891805052757263,
              height: 0.9761646389961243,
            }}
            paths={[
              {
                d: "M0.49459 0.976165C0.221264 0.976165 0 0.754901 0 0.488082C0 0.221264 0.221264 0 0.49459 0C0.761409 0 0.982673 0.221264 0.989181 0.488082C0.982673 0.761408 0.761409 0.976165 0.49459 0.976165Z",
                fill: "rgba(255,255,255,1)",
                fillRule: "nonzero",
              },
            ]}
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            position="absolute"
            top="8.04%"
            bottom="89.31%"
            left="48.66%"
            right="48.66%"
            {...getOverrideProps(overrides, "Vector49141183")}
          ></Icon>
          <Icon
            width="1.15px"
            height="1.15px"
            viewBox={{
              minX: 0,
              minY: 0,
              width: 1.1518734693527222,
              height: 1.1453659534454346,
            }}
            paths={[
              {
                d: "M0.572683 1.14537C0.253802 1.14537 0 0.891563 0 0.572683C0 0.253802 0.26031 0 0.579191 0C0.898071 0 1.15187 0.253802 1.15187 0.572683C1.15187 0.885055 0.891563 1.14537 0.572683 1.14537Z",
                fill: "rgba(255,255,255,1)",
                fillRule: "nonzero",
              },
            ]}
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            position="absolute"
            top="14.53%"
            bottom="82.36%"
            left="48.43%"
            right="48.45%"
            {...getOverrideProps(overrides, "Vector49141184")}
          ></Icon>
          <Icon
            width="1.32px"
            height="1.31px"
            viewBox={{
              minX: 0,
              minY: 0,
              width: 1.3210766315460205,
              height: 1.3146727085113525,
            }}
            paths={[
              {
                d: "M0.663792 1.31457C0.299357 1.31457 0 1.02172 0 0.657284C0 0.29285 0.29285 0 0.663792 0C1.02823 0 1.32108 0.29285 1.32108 0.657284C1.32108 1.02172 1.02823 1.32108 0.663792 1.31457Z",
                fill: "rgba(255,255,255,1)",
                fillRule: "nonzero",
              },
            ]}
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            position="absolute"
            top="22.06%"
            bottom="74.37%"
            left="48.2%"
            right="48.22%"
            {...getOverrideProps(overrides, "Vector49141185")}
          ></Icon>
          <Icon
            width="1.48px"
            height="1.48px"
            viewBox={{
              minX: 0,
              minY: 0,
              width: 1.4837695360183716,
              height: 1.483770728111267,
            }}
            paths={[
              {
                d: "M0.741885 1.48377C0.331896 1.48377 0 1.15187 0 0.741885C0 0.331896 0.331896 0 0.741885 0C1.15187 0 1.48377 0.331896 1.48377 0.741885C1.48377 1.15187 1.15187 1.48377 0.741885 1.48377Z",
                fill: "rgba(255,255,255,1)",
                fillRule: "nonzero",
              },
            ]}
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            position="absolute"
            top="29.54%"
            bottom="66.44%"
            left="47.99%"
            right="47.99%"
            {...getOverrideProps(overrides, "Vector49141186")}
          ></Icon>
          <Icon
            width="1.65px"
            height="1.65px"
            viewBox={{
              minX: 0,
              minY: 0,
              width: 1.653057336807251,
              height: 1.6529715061187744,
            }}
            paths={[
              {
                d: "M0.826486 1.65297C0.370943 1.65297 0 1.28203 0 0.826485C0 0.370942 0.370943 0 0.826486 0C1.28203 0 1.65297 0.370942 1.65297 0.826485C1.65948 1.28203 1.28854 1.65297 0.826486 1.65297Z",
                fill: "rgba(255,255,255,1)",
                fillRule: "nonzero",
              },
            ]}
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            position="absolute"
            top="38.15%"
            bottom="57.37%"
            left="47.74%"
            right="47.78%"
            {...getOverrideProps(overrides, "Vector49141187")}
          ></Icon>
          <Icon
            width="0.98px"
            height="0.98px"
            viewBox={{
              minX: 0,
              minY: 0,
              width: 0.9797877073287964,
              height: 0.9795650839805603,
            }}
            paths={[
              {
                d: "M0.675694 0.941631C0.428399 1.04576 0.142058 0.928616 0.0379337 0.674813C-0.0661906 0.427518 0.0509491 0.134669 0.304752 0.0370522C0.558555 -0.067072 0.844896 0.0565754 0.94902 0.30387C1.04013 0.551166 0.922989 0.837507 0.675694 0.941631Z",
                fill: "rgba(255,255,255,1)",
                fillRule: "nonzero",
              },
            ]}
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            position="absolute"
            top="11.13%"
            bottom="86.21%"
            left="33.12%"
            right="64.22%"
            {...getOverrideProps(overrides, "Vector49141188")}
          ></Icon>
          <Icon
            width="1.15px"
            height="1.15px"
            viewBox={{
              minX: 0,
              minY: 0,
              width: 1.1498777866363525,
              height: 1.1498782634735107,
            }}
            paths={[
              {
                d: "M0.792949 1.10532C0.5001 1.22897 0.161696 1.0858 0.0445563 0.792949C-0.0790912 0.5001 0.0640793 0.161696 0.356929 0.0445563C0.649778 -0.0790912 0.981674 0.0640793 1.10532 0.356929C1.22897 0.64327 1.0858 0.981674 0.792949 1.10532Z",
                fill: "rgba(255,255,255,1)",
                fillRule: "nonzero",
              },
            ]}
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            position="absolute"
            top="17.11%"
            bottom="79.77%"
            left="35.45%"
            right="61.43%"
            {...getOverrideProps(overrides, "Vector49141189")}
          ></Icon>
          <Icon
            width="1.32px"
            height="1.32px"
            viewBox={{
              minX: 0,
              minY: 0,
              width: 1.3156315088272095,
              height: 1.3183609247207642,
            }}
            paths={[
              {
                d: "M0.908364 1.26902C0.56996 1.40568 0.186003 1.2495 0.0493395 0.911095C-0.0873236 0.572691 0.0688635 0.188732 0.407267 0.0520694C0.745671 -0.0911015 1.12963 0.0715928 1.26629 0.409997C1.40296 0.7484 1.24677 1.13236 0.908364 1.26902Z",
                fill: "rgba(255,255,255,1)",
                fillRule: "nonzero",
              },
            ]}
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            position="absolute"
            top="24.04%"
            bottom="72.39%"
            left="38.21%"
            right="58.23%"
            {...getOverrideProps(overrides, "Vector49141190")}
          ></Icon>
          <Icon
            width="1.49px"
            height="1.49px"
            viewBox={{
              minX: 0,
              minY: 0,
              width: 1.4850716590881348,
              height: 1.4850716590881348,
            }}
            paths={[
              {
                d: "M1.02562 1.4291C0.641666 1.58529 0.212153 1.40307 0.0559672 1.02562C-0.100219 0.648174 0.0819975 0.212153 0.459448 0.0559672C0.836898 -0.100219 1.27292 0.0819975 1.4291 0.459448C1.58529 0.836898 1.40307 1.27292 1.02562 1.4291Z",
                fill: "rgba(255,255,255,1)",
                fillRule: "nonzero",
              },
            ]}
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            position="absolute"
            top="30.94%"
            bottom="65.03%"
            left="40.92%"
            right="55.05%"
            {...getOverrideProps(overrides, "Vector49141191")}
          ></Icon>
          <Icon
            width="0.98px"
            height="0.98px"
            viewBox={{
              minX: 0,
              minY: 0,
              width: 0.9794182777404785,
              height: 0.9794182777404785,
            }}
            paths={[
              {
                d: "M0.837874 0.837874C0.649149 1.0266 0.336777 1.0266 0.141544 0.837874C-0.0471813 0.649149 -0.0471813 0.336777 0.141544 0.141544C0.336777 -0.0471813 0.642642 -0.0471813 0.837874 0.141544C1.0266 0.336777 1.0266 0.649149 0.837874 0.837874Z",
                fill: "rgba(255,255,255,1)",
                fillRule: "nonzero",
              },
            ]}
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            position="absolute"
            top="19.93%"
            bottom="77.41%"
            left="19.93%"
            right="77.41%"
            {...getOverrideProps(overrides, "Vector49141192")}
          ></Icon>
          <Icon
            width="1.15px"
            height="1.16px"
            viewBox={{
              minX: 0,
              minY: 0,
              width: 1.1526973247528076,
              height: 1.1551282405853271,
            }}
            paths={[
              {
                d: "M0.981869 0.984299C0.760605 1.21207 0.396169 1.21207 0.168397 0.984299C-0.0593745 0.756528 -0.0528668 0.398601 0.168397 0.170829C0.389661 -0.0569429 0.754097 -0.0569429 0.981869 0.170829C1.20964 0.398601 1.20964 0.763035 0.981869 0.984299Z",
                fill: "rgba(255,255,255,1)",
                fillRule: "nonzero",
              },
            ]}
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            position="absolute"
            top="24.44%"
            bottom="72.43%"
            left="24.45%"
            right="72.43%"
            {...getOverrideProps(overrides, "Vector49141193")}
          ></Icon>
          <Icon
            width="1.32px"
            height="1.32px"
            viewBox={{
              minX: 0,
              minY: 0,
              width: 1.3186438083648682,
              height: 1.3186436891555786,
            }}
            paths={[
              {
                d: "M1.12584 1.12341C0.865533 1.38372 0.449036 1.38372 0.195233 1.12341C-0.0650776 0.8631 -0.0650776 0.446603 0.195233 0.1928C0.455544 -0.0675103 0.872041 -0.0610025 1.12584 0.1928C1.38615 0.446603 1.37965 0.869608 1.12584 1.12341Z",
                fill: "rgba(255,255,255,1)",
                fillRule: "nonzero",
              },
            ]}
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            position="absolute"
            top="29.72%"
            bottom="66.7%"
            left="29.72%"
            right="66.71%"
            {...getOverrideProps(overrides, "Vector49141194")}
          ></Icon>
          <Icon
            width="1.48px"
            height="1.48px"
            viewBox={{
              minX: 0,
              minY: 0,
              width: 1.47726309299469,
              height: 1.4797099828720093,
            }}
            paths={[
              {
                d: "M1.26251 1.26495C0.969657 1.5513 0.501098 1.5513 0.214756 1.26495C-0.0715854 0.978612 -0.0715854 0.503545 0.214756 0.217203C0.501098 -0.0756459 0.976165 -0.0691381 1.26251 0.217203C1.54885 0.503545 1.54885 0.972104 1.26251 1.26495Z",
                fill: "rgba(255,255,255,1)",
                fillRule: "nonzero",
              },
            ]}
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            position="absolute"
            top="34.95%"
            bottom="61.04%"
            left="34.96%"
            right="61.04%"
            {...getOverrideProps(overrides, "Vector49141195")}
          ></Icon>
          <Icon
            width="1.66px"
            height="1.66px"
            viewBox={{
              minX: 0,
              minY: 0,
              width: 1.6594794988632202,
              height: 1.6594794988632202,
            }}
            paths={[
              {
                d: "M1.41544 1.41544C1.09005 1.74083 0.569429 1.74083 0.244041 1.41544C-0.0813471 1.09005 -0.0813471 0.569429 0.244041 0.244041C0.569429 -0.0813471 1.09005 -0.0813471 1.41544 0.244041C1.74083 0.562922 1.74083 1.09005 1.41544 1.41544Z",
                fill: "rgba(255,255,255,1)",
                fillRule: "nonzero",
              },
            ]}
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            position="absolute"
            top="40.96%"
            bottom="54.54%"
            left="40.94%"
            right="54.56%"
            {...getOverrideProps(overrides, "Vector49141196")}
          ></Icon>
          <Icon
            width="0.99px"
            height="0.98px"
            viewBox={{
              minX: 0,
              minY: 0,
              width: 0.9851915240287781,
              height: 0.9804461002349854,
            }}
            paths={[
              {
                d: "M0.948139 0.675694C0.844015 0.929497 0.557673 1.04664 0.30387 0.942512C0.0565754 0.838388 -0.067072 0.552047 0.0370522 0.304752C0.141176 0.0509491 0.427518 -0.0661906 0.681321 0.0379337C0.928616 0.142058 1.05226 0.428399 0.948139 0.675694Z",
                fill: "rgba(255,255,255,1)",
                fillRule: "nonzero",
              },
            ]}
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            position="absolute"
            top="33.12%"
            bottom="64.22%"
            left="11.12%"
            right="86.21%"
            {...getOverrideProps(overrides, "Vector49141197")}
          ></Icon>
          <Icon
            width="1.15px"
            height="1.15px"
            viewBox={{
              minX: 0,
              minY: 0,
              width: 1.1499743461608887,
              height: 1.1471571922302246,
            }}
            paths={[
              {
                d: "M1.10532 0.790228C0.981674 1.08308 0.649778 1.22625 0.356929 1.1026C0.0640793 0.978953 -0.0790912 0.647058 0.0445563 0.354208C0.168204 0.0613588 0.5001 -0.0753039 0.799457 0.0418359C1.0858 0.158976 1.22897 0.497379 1.10532 0.790228Z",
                fill: "rgba(255,255,255,1)",
                fillRule: "nonzero",
              },
            ]}
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            position="absolute"
            top="35.48%"
            bottom="61.41%"
            left="17.09%"
            right="79.79%"
            {...getOverrideProps(overrides, "Vector49141198")}
          ></Icon>
          <Icon
            width="1.32px"
            height="1.32px"
            viewBox={{
              minX: 0,
              minY: 0,
              width: 1.3183608055114746,
              height: 1.3248690366744995,
            }}
            paths={[
              {
                d: "M1.26629 0.914872C1.12963 1.25328 0.739163 1.41597 0.407267 1.2728C0.068863 1.13614 -0.0873235 0.745671 0.0493395 0.407267C0.186003 0.0688635 0.576468 -0.0873236 0.908364 0.0493394C1.24677 0.19251 1.40946 0.582976 1.26629 0.914872Z",
                fill: "rgba(255,255,255,1)",
                fillRule: "nonzero",
              },
            ]}
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            position="absolute"
            top="38.19%"
            bottom="58.22%"
            left="24.05%"
            right="72.38%"
            {...getOverrideProps(overrides, "Vector49141199")}
          ></Icon>
          <Icon
            width="1.49px"
            height="1.49px"
            viewBox={{
              minX: 0,
              minY: 0,
              width: 1.4850716590881348,
              height: 1.4859563112258911,
            }}
            paths={[
              {
                d: "M1.4291 1.02651C1.27292 1.40396 0.836898 1.58618 0.459448 1.42999C0.0819975 1.2738 -0.100219 0.837783 0.0559672 0.460332C0.212153 0.0763743 0.648174 -0.0993347 1.02562 0.0568517C1.40307 0.213038 1.58529 0.649058 1.4291 1.02651Z",
                fill: "rgba(255,255,255,1)",
                fillRule: "nonzero",
              },
            ]}
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            position="absolute"
            top="40.92%"
            bottom="55.05%"
            left="30.94%"
            right="65.03%"
            {...getOverrideProps(overrides, "Vector49141200")}
          ></Icon>
          <Icon
            width="0.98px"
            height="0.98px"
            viewBox={{
              minX: 0,
              minY: 0,
              width: 0.9826723337173462,
              height: 0.9826724529266357,
            }}
            paths={[
              {
                d: "M0.982672 0.488082C0.982672 0.761408 0.767916 0.982673 0.488082 0.982673C0.221264 0.982673 0 0.761409 0 0.49459C0 0.221264 0.221264 0 0.488082 0C0.761409 0 0.982672 0.214756 0.982672 0.488082Z",
                fill: "rgba(255,255,255,1)",
                fillRule: "nonzero",
              },
            ]}
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            position="absolute"
            top="48.68%"
            bottom="48.66%"
            left="8.02%"
            right="89.31%"
            {...getOverrideProps(overrides, "Vector49141201")}
          ></Icon>
          <Icon
            width="1.15px"
            height="1.15px"
            viewBox={{
              minX: 0,
              minY: 0,
              width: 1.1453666687011719,
              height: 1.1518733501434326,
            }}
            paths={[
              {
                d: "M1.14537 0.579191C1.14537 0.898071 0.891564 1.15187 0.572683 1.15187C0.253803 1.15187 0 0.891563 0 0.579191C0 0.26031 0.253803 0.00650776 0.572683 0C0.885056 0 1.14537 0.26031 1.14537 0.579191Z",
                fill: "rgba(255,255,255,1)",
                fillRule: "nonzero",
              },
            ]}
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            position="absolute"
            top="48.43%"
            bottom="48.45%"
            left="14.53%"
            right="82.36%"
            {...getOverrideProps(overrides, "Vector49141202")}
          ></Icon>
          <Icon
            width="1.31px"
            height="1.31px"
            viewBox={{
              minX: 0,
              minY: 0,
              width: 1.3146721124649048,
              height: 1.3146727085113525,
            }}
            paths={[
              {
                d: "M1.31457 0.657284C1.31457 1.02172 1.02172 1.32108 0.657284 1.31457C0.29285 1.31457 0 1.02172 0 0.657284C0 0.29285 0.29285 0 0.657284 0C1.02172 0 1.32108 0.29285 1.31457 0.657284Z",
                fill: "rgba(255,255,255,1)",
                fillRule: "nonzero",
              },
            ]}
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            position="absolute"
            top="48.22%"
            bottom="48.22%"
            left="22.06%"
            right="74.37%"
            {...getOverrideProps(overrides, "Vector49141203")}
          ></Icon>
          <Icon
            width="1.48px"
            height="1.48px"
            viewBox={{
              minX: 0,
              minY: 0,
              width: 1.4837708473205566,
              height: 1.483769416809082,
            }}
            paths={[
              {
                d: "M1.48377 0.741885C1.48377 1.15187 1.15187 1.48377 0.741885 1.48377C0.331896 1.48377 0 1.15187 0 0.741885C0 0.331896 0.331896 0 0.741885 0C1.15187 0 1.48377 0.331896 1.48377 0.741885Z",
                fill: "rgba(255,255,255,1)",
                fillRule: "nonzero",
              },
            ]}
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            position="absolute"
            top="47.99%"
            bottom="47.99%"
            left="29.54%"
            right="66.44%"
            {...getOverrideProps(overrides, "Vector49141204")}
          ></Icon>
          <Icon
            width="1.65px"
            height="1.65px"
            viewBox={{
              minX: 0,
              minY: 0,
              width: 1.652971625328064,
              height: 1.65297269821167,
            }}
            paths={[
              {
                d: "M1.65297 0.826486C1.65297 1.28203 1.28203 1.65297 0.826485 1.65297C0.370942 1.65297 0 1.28203 0 0.826486C0 0.370943 0.370942 0 0.826485 0C1.28203 -2.31202e-15 1.65297 0.370943 1.65297 0.826486Z",
                fill: "rgba(255,255,255,1)",
                fillRule: "nonzero",
              },
            ]}
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            position="absolute"
            top="47.76%"
            bottom="47.76%"
            left="38.15%"
            right="57.37%"
            {...getOverrideProps(overrides, "Vector49141205")}
          ></Icon>
          <Icon
            width="0.98px"
            height="0.99px"
            viewBox={{
              minX: 0,
              minY: 0,
              width: 0.980446457862854,
              height: 0.9860714077949524,
            }}
            paths={[
              {
                d: "M0.942513 0.304752C1.04664 0.558555 0.929497 0.844895 0.675695 0.949019C0.4284 1.05314 0.13555 0.929496 0.0379337 0.682201C-0.0661906 0.434906 0.0509491 0.142058 0.304752 0.0379337C0.552047 -0.0661906 0.838389 0.0509491 0.942513 0.304752Z",
                fill: "rgba(255,255,255,1)",
                fillRule: "nonzero",
              },
            ]}
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            position="absolute"
            top="64.22%"
            bottom="33.11%"
            left="11.13%"
            right="86.21%"
            {...getOverrideProps(overrides, "Vector49141206")}
          ></Icon>
          <Icon
            width="1.16px"
            height="1.15px"
            viewBox={{
              minX: 0,
              minY: 0,
              width: 1.1563860177993774,
              height: 1.1500380039215088,
            }}
            paths={[
              {
                d: "M1.11183 0.357088C1.23548 0.649937 1.09231 0.988342 0.799457 1.10548C0.506607 1.22913 0.168204 1.08596 0.0445562 0.793109C-0.0790913 0.50026 0.0640799 0.161856 0.356929 0.0382086C0.656286 -0.0724234 0.99469 0.0642383 1.11183 0.357088Z",
                fill: "rgba(255,255,255,1)",
                fillRule: "nonzero",
              },
            ]}
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            position="absolute"
            top="61.41%"
            bottom="35.47%"
            left="17.07%"
            right="79.79%"
            {...getOverrideProps(overrides, "Vector49141207")}
          ></Icon>
          <Icon
            width="1.32px"
            height="1.32px"
            viewBox={{
              minX: 0,
              minY: 0,
              width: 1.3156309127807617,
              height: 1.3183603286743164,
            }}
            paths={[
              {
                d: "M1.26629 0.409996C1.40295 0.7484 1.24677 1.13236 0.908364 1.26902C0.56996 1.40568 0.186003 1.2495 0.0493395 0.911094C-0.0873235 0.57269 0.068863 0.188733 0.407267 0.0520695C0.74567 -0.0911014 1.12963 0.0715922 1.26629 0.409996Z",
                fill: "rgba(255,255,255,1)",
                fillRule: "nonzero",
              },
            ]}
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            position="absolute"
            top="58.22%"
            bottom="38.21%"
            left="24.05%"
            right="72.39%"
            {...getOverrideProps(overrides, "Vector49141208")}
          ></Icon>
          <Icon
            width="1.49px"
            height="1.48px"
            viewBox={{
              minX: 0,
              minY: 0,
              width: 1.4850716590881348,
              height: 1.482357382774353,
            }}
            paths={[
              {
                d: "M1.4291 0.456733C1.58529 0.834183 1.40307 1.2702 1.02562 1.42639C0.648174 1.58258 0.212153 1.40036 0.0559672 1.02291C-0.100219 0.645457 0.0819975 0.209438 0.459448 0.0532519C0.836898 -0.0964267 1.27292 0.0792822 1.4291 0.456733Z",
                fill: "rgba(255,255,255,1)",
                fillRule: "nonzero",
              },
            ]}
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            position="absolute"
            top="55.04%"
            bottom="40.94%"
            left="30.94%"
            right="65.03%"
            {...getOverrideProps(overrides, "Vector49141209")}
          ></Icon>
          <Icon
            width="0.98px"
            height="0.98px"
            viewBox={{
              minX: 0,
              minY: 0,
              width: 0.9842992424964905,
              height: 0.9842991828918457,
            }}
            paths={[
              {
                d: "M0.837875 0.146425C1.03311 0.33515 1.03311 0.647522 0.837875 0.842755C0.649149 1.03148 0.336777 1.03148 0.141544 0.842755C-0.0471813 0.65403 -0.0471813 0.341658 0.141544 0.146425C0.336777 -0.0488082 0.649149 -0.0488082 0.837875 0.146425Z",
                fill: "rgba(255,255,255,1)",
                fillRule: "nonzero",
              },
            ]}
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            position="absolute"
            top="77.4%"
            bottom="19.93%"
            left="19.93%"
            right="77.4%"
            {...getOverrideProps(overrides, "Vector49141210")}
          ></Icon>
          <Icon
            width="1.16px"
            height="1.15px"
            viewBox={{
              minX: 0,
              minY: 0,
              width: 1.155128836631775,
              height: 1.1526966094970703,
            }}
            paths={[
              {
                d: "M0.9843 0.170829C1.21207 0.398601 1.21207 0.756528 0.9843 0.984299C0.763036 1.21207 0.392093 1.20556 0.170829 0.984299C-0.0569429 0.763035 -0.0569429 0.398601 0.170829 0.170829C0.398601 -0.0569429 0.756528 -0.0569429 0.9843 0.170829Z",
                fill: "rgba(255,255,255,1)",
                fillRule: "nonzero",
              },
            ]}
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            position="absolute"
            top="72.43%"
            bottom="24.45%"
            left="24.44%"
            right="72.43%"
            {...getOverrideProps(overrides, "Vector49141211")}
          ></Icon>
          <Icon
            width="1.33px"
            height="1.32px"
            viewBox={{
              minX: 0,
              minY: 0,
              width: 1.327584147453308,
              height: 1.3186436891555786,
            }}
            paths={[
              {
                d: "M1.13235 0.195233C1.39266 0.455543 1.39266 0.872041 1.13235 1.12584C0.872041 1.37965 0.455543 1.38615 0.195233 1.12584C-0.0650776 0.872041 -0.0650776 0.449036 0.195233 0.195233C0.455543 -0.0650776 0.878548 -0.0650776 1.13235 0.195233Z",
                fill: "rgba(255,255,255,1)",
                fillRule: "nonzero",
              },
            ]}
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            position="absolute"
            top="66.7%"
            bottom="29.72%"
            left="29.7%"
            right="66.7%"
            {...getOverrideProps(overrides, "Vector49141212")}
          ></Icon>
          <Icon
            width="1.48px"
            height="1.49px"
            viewBox={{
              minX: 0,
              minY: 0,
              width: 1.4797101020812988,
              height: 1.4902927875518799,
            }}
            paths={[
              {
                d: "M1.26495 0.21883C1.5513 0.511679 1.5513 0.98024 1.26495 1.27309C0.978612 1.55943 0.503545 1.56594 0.217203 1.27309C-0.0756459 0.986748 -0.0691381 0.51168 0.217203 0.225339C0.503545 -0.0740184 0.972105 -0.0740198 1.26495 0.21883Z",
                fill: "rgba(255,255,255,1)",
                fillRule: "nonzero",
              },
            ]}
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            position="absolute"
            top="61.03%"
            bottom="34.93%"
            left="34.95%"
            right="61.04%"
            {...getOverrideProps(overrides, "Vector49141213")}
          ></Icon>
          <Icon
            width="1.65px"
            height="1.65px"
            viewBox={{
              minX: 0,
              minY: 0,
              width: 1.652971625328064,
              height: 1.6545974016189575,
            }}
            paths={[
              {
                d: "M1.40893 0.23916C1.73432 0.564549 1.73432 1.08517 1.40893 1.41056C1.08354 1.73594 0.562922 1.73594 0.244041 1.41056C-0.0813471 1.08517 -0.0813471 0.564549 0.244041 0.23916C0.562922 -0.0797201 1.08354 -0.0797201 1.40893 0.23916Z",
                fill: "rgba(255,255,255,1)",
                fillRule: "nonzero",
              },
            ]}
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            position="absolute"
            top="54.55%"
            bottom="40.96%"
            left="40.96%"
            right="54.56%"
            {...getOverrideProps(overrides, "Vector49141214")}
          ></Icon>
          <Icon
            width="0.98px"
            height="0.99px"
            viewBox={{
              minX: 0,
              minY: 0,
              width: 0.9804461598396301,
              height: 0.986073911190033,
            }}
            paths={[
              {
                d: "M0.675694 0.0379337C0.929497 0.142058 1.04664 0.428401 0.942513 0.682203C0.838388 0.929498 0.552047 1.05315 0.304752 0.949022C0.0509491 0.844897 -0.0661906 0.558555 0.0379337 0.304752C0.142058 0.0509491 0.428399 -0.0661906 0.675694 0.0379337Z",
                fill: "rgba(255,255,255,1)",
                fillRule: "nonzero",
              },
            ]}
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            position="absolute"
            top="86.21%"
            bottom="11.12%"
            left="33.12%"
            right="64.22%"
            {...getOverrideProps(overrides, "Vector49141215")}
          ></Icon>
          <Icon
            width="1.15px"
            height="1.14px"
            viewBox={{
              minX: 0,
              minY: 0,
              width: 1.1471573114395142,
              height: 1.1445305347442627,
            }}
            paths={[
              {
                d: "M0.792949 0.0419284C1.0858 0.165576 1.22246 0.497473 1.10532 0.790322C0.988182 1.08317 0.649778 1.21983 0.356929 1.10269C0.0640793 0.985555 -0.0790912 0.647151 0.0445563 0.347794C0.161696 0.0614525 0.506607 -0.0752113 0.792949 0.0419284Z",
                fill: "rgba(255,255,255,1)",
                fillRule: "nonzero",
              },
            ]}
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            position="absolute"
            top="79.78%"
            bottom="17.12%"
            left="35.45%"
            right="61.44%"
            {...getOverrideProps(overrides, "Vector49141216")}
          ></Icon>
          <Icon
            width="1.32px"
            height="1.31px"
            viewBox={{
              minX: 0,
              minY: 0,
              width: 1.3156315088272095,
              height: 1.3147592544555664,
            }}
            paths={[
              {
                d: "M0.908364 0.0484684C1.24677 0.185131 1.40296 0.569089 1.26629 0.907493C1.12963 1.2459 0.739163 1.40208 0.407267 1.26542C0.0688635 1.12876 -0.0873236 0.738291 0.0493395 0.406395C0.186003 0.074499 0.576468 -0.0881946 0.908364 0.0484684Z",
                fill: "rgba(255,255,255,1)",
                fillRule: "nonzero",
              },
            ]}
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            position="absolute"
            top="72.37%"
            bottom="24.06%"
            left="38.21%"
            right="58.23%"
            {...getOverrideProps(overrides, "Vector49141217")}
          ></Icon>
          <Icon
            width="1.49px"
            height="1.49px"
            viewBox={{
              minX: 0,
              minY: 0,
              width: 1.4850716590881348,
              height: 1.4850726127624512,
            }}
            paths={[
              {
                d: "M1.02562 0.0559668C1.40307 0.212153 1.58529 0.648174 1.4291 1.02562C1.27292 1.40308 0.836898 1.58529 0.459448 1.42911C0.0819975 1.27292 -0.100219 0.8369 0.0559672 0.45945C0.212153 0.0819996 0.648174 -0.10022 1.02562 0.0559668Z",
                fill: "rgba(255,255,255,1)",
                fillRule: "nonzero",
              },
            ]}
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            position="absolute"
            top="65.03%"
            bottom="30.94%"
            left="40.92%"
            right="55.05%"
            {...getOverrideProps(overrides, "Vector49141218")}
          ></Icon>
          <Icon
            width="0.99px"
            height="0.98px"
            viewBox={{
              minX: 0,
              minY: 0,
              width: 0.9891805052757263,
              height: 0.9761646389961243,
            }}
            paths={[
              {
                d: "M0.49459 0C0.767916 0 0.989181 0.221264 0.989181 0.488082C0.989181 0.754901 0.767916 0.976165 0.49459 0.976165C0.221264 0.976165 0 0.754901 0 0.488082C0.00650776 0.214756 0.221264 0 0.49459 0Z",
                fill: "rgba(255,255,255,1)",
                fillRule: "nonzero",
              },
            ]}
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            position="absolute"
            top="89.31%"
            bottom="8.04%"
            left="48.66%"
            right="48.66%"
            {...getOverrideProps(overrides, "Vector49141219")}
          ></Icon>
          <Icon
            width="1.15px"
            height="1.15px"
            viewBox={{
              minX: 0,
              minY: 0,
              width: 1.1519927978515625,
              height: 1.1453653573989868,
            }}
            paths={[
              {
                d: "M0.579191 0C0.898071 0 1.15838 0.253803 1.15187 0.572684C1.15187 0.891564 0.891563 1.14537 0.579191 1.14537C0.26031 1.14537 0.00650776 0.891564 0 0.572684C0 0.260311 0.26031 0 0.579191 0Z",
                fill: "rgba(255,255,255,1)",
                fillRule: "nonzero",
              },
            ]}
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            position="absolute"
            top="82.36%"
            bottom="14.53%"
            left="48.43%"
            right="48.45%"
            {...getOverrideProps(overrides, "Vector49141220")}
          ></Icon>
          <Icon
            width="1.32px"
            height="1.31px"
            viewBox={{
              minX: 0,
              minY: 0,
              width: 1.3210766315460205,
              height: 1.3147743940353394,
            }}
            paths={[
              {
                d: "M0.657284 0.000104139C1.02172 0.000104139 1.32108 0.292952 1.32108 0.657387C1.32108 1.02182 1.02823 1.32118 0.657284 1.31467C0.29285 1.31467 0 1.02182 0 0.657387C0 0.292952 0.29285 -0.00640362 0.657284 0.000104139Z",
                fill: "rgba(255,255,255,1)",
                fillRule: "nonzero",
              },
            ]}
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            position="absolute"
            top="74.37%"
            bottom="22.06%"
            left="48.22%"
            right="48.2%"
            {...getOverrideProps(overrides, "Vector49141221")}
          ></Icon>
          <Icon
            width="1.48px"
            height="1.48px"
            viewBox={{
              minX: 0,
              minY: 0,
              width: 1.4837695360183716,
              height: 1.483769416809082,
            }}
            paths={[
              {
                d: "M0.741885 0C1.15187 0 1.48377 0.331896 1.48377 0.741885C1.48377 1.15187 1.15187 1.48377 0.741885 1.48377C0.331896 1.48377 0 1.15187 0 0.741885C0 0.331896 0.331896 0 0.741885 0Z",
                fill: "rgba(255,255,255,1)",
                fillRule: "nonzero",
              },
            ]}
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            position="absolute"
            top="66.44%"
            bottom="29.54%"
            left="47.99%"
            right="47.99%"
            {...getOverrideProps(overrides, "Vector49141222")}
          ></Icon>
          <Icon
            width="1.65px"
            height="1.65px"
            viewBox={{
              minX: 0,
              minY: 0,
              width: 1.6529728174209595,
              height: 1.65297269821167,
            }}
            paths={[
              {
                d: "M0.826486 0C1.28203 0 1.65297 0.370943 1.65297 0.826486C1.65297 1.28203 1.28203 1.65297 0.826486 1.65297C0.370943 1.65297 0 1.28203 0 0.826486C-2.31202e-15 0.370943 0.364435 0 0.826486 0Z",
                fill: "rgba(255,255,255,1)",
                fillRule: "nonzero",
              },
            ]}
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            position="absolute"
            top="57.37%"
            bottom="38.15%"
            left="47.76%"
            right="47.76%"
            {...getOverrideProps(overrides, "Vector49141223")}
          ></Icon>
          <Icon
            width="0.98px"
            height="0.99px"
            viewBox={{
              minX: 0,
              minY: 0,
              width: 0.9833430647850037,
              height: 0.986073911190033,
            }}
            paths={[
              {
                d: "M0.302024 0.0379337C0.549319 -0.0661906 0.835659 0.0509491 0.946291 0.304752C1.05042 0.558555 0.926768 0.844897 0.679473 0.949022C0.432178 1.05315 0.139328 0.929498 0.041712 0.682203C-0.06892 0.428401 0.0482208 0.142058 0.302024 0.0379337Z",
                fill: "rgba(255,255,255,1)",
                fillRule: "nonzero",
              },
            ]}
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            position="absolute"
            top="86.21%"
            bottom="11.12%"
            left="64.23%"
            right="33.11%"
            {...getOverrideProps(overrides, "Vector49141224")}
          ></Icon>
          <Icon
            width="1.15px"
            height="1.15px"
            viewBox={{
              minX: 0,
              minY: 0,
              width: 1.1527245044708252,
              height: 1.149878978729248,
            }}
            paths={[
              {
                d: "M0.359774 0.0445563C0.652624 -0.0790912 0.991028 0.0640793 1.10817 0.356929C1.23182 0.649778 1.08865 0.988183 0.795796 1.10532C0.502946 1.22897 0.164543 1.0858 0.0408952 0.79295C-0.0762446 0.506609 0.0669248 0.168204 0.359774 0.0445563Z",
                fill: "rgba(255,255,255,1)",
                fillRule: "nonzero",
              },
            ]}
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            position="absolute"
            top="79.77%"
            bottom="17.11%"
            left="61.41%"
            right="35.47%"
            {...getOverrideProps(overrides, "Vector49141225")}
          ></Icon>
          <Icon
            width="1.32px"
            height="1.32px"
            viewBox={{
              minX: 0,
              minY: 0,
              width: 1.318360447883606,
              height: 1.3221392631530762,
            }}
            paths={[
              {
                d: "M0.409996 0.0493396C0.7484 -0.0873234 1.13236 0.0688624 1.26902 0.407266C1.40568 0.74567 1.2495 1.12963 0.911094 1.2728C0.57269 1.40946 0.188733 1.25327 0.0520695 0.914871C-0.0911014 0.576467 0.0715922 0.19251 0.409996 0.0493396Z",
                fill: "rgba(255,255,255,1)",
                fillRule: "nonzero",
              },
            ]}
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            position="absolute"
            top="72.37%"
            bottom="24.05%"
            left="58.22%"
            right="38.21%"
            {...getOverrideProps(overrides, "Vector49141226")}
          ></Icon>
          <Icon
            width="1.48px"
            height="1.49px"
            viewBox={{
              minX: 0,
              minY: 0,
              width: 1.482357382774353,
              height: 1.4850726127624512,
            }}
            paths={[
              {
                d: "M0.456733 0.0559668C0.834183 -0.10022 1.2702 0.0819996 1.42639 0.45945C1.58258 0.8369 1.40036 1.27292 1.02291 1.42911C0.645457 1.58529 0.209438 1.40308 0.0532519 1.02562C-0.0964267 0.648174 0.0792822 0.212153 0.456733 0.0559668Z",
                fill: "rgba(255,255,255,1)",
                fillRule: "nonzero",
              },
            ]}
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            position="absolute"
            top="65.03%"
            bottom="30.94%"
            left="55.04%"
            right="40.94%"
            {...getOverrideProps(overrides, "Vector49141227")}
          ></Icon>
          <Icon
            width="0.98px"
            height="0.98px"
            viewBox={{
              minX: 0,
              minY: 0,
              width: 0.9842992424964905,
              height: 0.9842991828918457,
            }}
            paths={[
              {
                d: "M0.146425 0.146425C0.33515 -0.0488082 0.647522 -0.0488082 0.842755 0.146425C1.03148 0.33515 1.03148 0.647522 0.842755 0.842755C0.647522 1.03148 0.33515 1.03148 0.146425 0.842755C-0.0488082 0.647522 -0.0488082 0.33515 0.146425 0.146425Z",
                fill: "rgba(255,255,255,1)",
                fillRule: "nonzero",
              },
            ]}
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            position="absolute"
            top="77.4%"
            bottom="19.93%"
            left="77.4%"
            right="19.93%"
            {...getOverrideProps(overrides, "Vector49141228")}
          ></Icon>
          <Icon
            width="1.15px"
            height="1.16px"
            viewBox={{
              minX: 0,
              minY: 0,
              width: 1.1478158235549927,
              height: 1.1551282405853271,
            }}
            paths={[
              {
                d: "M0.165948 0.170829C0.387212 -0.0569429 0.751647 -0.0569429 0.979419 0.170829C1.20719 0.398601 1.20068 0.756528 0.979419 0.984299C0.758155 1.21207 0.39372 1.21207 0.165948 0.984299C-0.055316 0.756528 -0.055316 0.392093 0.165948 0.170829Z",
                fill: "rgba(255,255,255,1)",
                fillRule: "nonzero",
              },
            ]}
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            position="absolute"
            top="72.43%"
            bottom="24.44%"
            left="72.43%"
            right="24.46%"
            {...getOverrideProps(overrides, "Vector49141229")}
          ></Icon>
          <Icon
            width="1.32px"
            height="1.33px"
            viewBox={{
              minX: 0,
              minY: 0,
              width: 1.3210763931274414,
              height: 1.3251529932022095,
            }}
            paths={[
              {
                d: "M0.195233 0.195233C0.455544 -0.0650776 0.865533 -0.0650776 1.12584 0.195233C1.38615 0.455544 1.38615 0.872042 1.12584 1.13235C0.872041 1.39266 0.449036 1.38616 0.195233 1.13235C-0.0650776 0.872042 -0.0650776 0.449036 0.195233 0.195233Z",
                fill: "rgba(255,255,255,1)",
                fillRule: "nonzero",
              },
            ]}
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            position="absolute"
            top="66.7%"
            bottom="29.71%"
            left="66.7%"
            right="29.72%"
            {...getOverrideProps(overrides, "Vector49141230")}
          ></Icon>
          <Icon
            width="1.49px"
            height="1.48px"
            viewBox={{
              minX: 0,
              minY: 0,
              width: 1.4886529445648193,
              height: 1.4772629737854004,
            }}
            paths={[
              {
                d: "M0.219637 0.214756C0.512486 -0.0715854 0.981047 -0.0715854 1.2739 0.214756C1.56024 0.501098 1.56024 0.976165 1.2739 1.26251C0.987555 1.54885 0.512486 1.54885 0.219637 1.26251C-0.0732124 0.976165 -0.0732124 0.507606 0.219637 0.214756Z",
                fill: "rgba(255,255,255,1)",
                fillRule: "nonzero",
              },
            ]}
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            position="absolute"
            top="61.04%"
            bottom="34.96%"
            left="61.03%"
            right="34.94%"
            {...getOverrideProps(overrides, "Vector49141231")}
          ></Icon>
          <Icon
            width="1.65px"
            height="1.66px"
            viewBox={{
              minX: 0,
              minY: 0,
              width: 1.6513701677322388,
              height: 1.6594780683517456,
            }}
            paths={[
              {
                d: "M0.242439 0.244041C0.567827 -0.081347 1.08845 -0.081347 1.40733 0.244041C1.73272 0.569429 1.73272 1.09005 1.40733 1.41544C1.08194 1.74083 0.56132 1.74083 0.235932 1.41544C-0.0829486 1.09656 -0.0764419 0.569429 0.242439 0.244041Z",
                fill: "rgba(255,255,255,1)",
                fillRule: "nonzero",
              },
            ]}
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            position="absolute"
            top="54.54%"
            bottom="40.96%"
            left="54.55%"
            right="40.98%"
            {...getOverrideProps(overrides, "Vector49141232")}
          ></Icon>
          <Icon
            width="0.99px"
            height="0.99px"
            viewBox={{
              minX: 0,
              minY: 0,
              width: 0.9859762787818909,
              height: 0.9860714077949524,
            }}
            paths={[
              {
                d: "M0.037836 0.304752C0.14196 0.0509491 0.428303 -0.0661906 0.682106 0.0379337C0.929401 0.142058 1.05305 0.428398 0.948924 0.682201C0.8448 0.929496 0.558458 1.05314 0.311163 0.949019C0.0508528 0.838387 -0.0662882 0.552047 0.037836 0.304752Z",
                fill: "rgba(255,255,255,1)",
                fillRule: "nonzero",
              },
            ]}
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            position="absolute"
            top="64.22%"
            bottom="33.11%"
            left="86.21%"
            right="11.12%"
            {...getOverrideProps(overrides, "Vector49141233")}
          ></Icon>
          <Icon
            width="1.15px"
            height="1.15px"
            viewBox={{
              minX: 0,
              minY: 0,
              width: 1.1472543478012085,
              height: 1.149878978729248,
            }}
            paths={[
              {
                d: "M0.0446522 0.356929C0.161792 0.0640793 0.500197 -0.0790912 0.793046 0.0445563C1.0859 0.168204 1.22256 0.500101 1.10542 0.79295C0.988279 1.0858 0.649875 1.22897 0.350518 1.10532C0.0641763 0.988183 -0.0789953 0.649778 0.0446522 0.356929Z",
                fill: "rgba(255,255,255,1)",
                fillRule: "nonzero",
              },
            ]}
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            position="absolute"
            top="61.41%"
            bottom="35.47%"
            left="79.77%"
            right="17.12%"
            {...getOverrideProps(overrides, "Vector49141234")}
          ></Icon>
          <Icon
            width="1.32px"
            height="1.32px"
            viewBox={{
              minX: 0,
              minY: 0,
              width: 1.318360447883606,
              height: 1.3221396207809448,
            }}
            paths={[
              {
                d: "M0.0520695 0.407266C0.188733 0.0688624 0.57269 -0.0873234 0.911094 0.0493396C1.2495 0.19251 1.40568 0.57647 1.26902 0.914873C1.13236 1.25328 0.7484 1.40946 0.409996 1.2728C0.0715922 1.12963 -0.0911014 0.739162 0.0520695 0.407266Z",
                fill: "rgba(255,255,255,1)",
                fillRule: "nonzero",
              },
            ]}
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            position="absolute"
            top="58.23%"
            bottom="38.19%"
            left="72.36%"
            right="24.06%"
            {...getOverrideProps(overrides, "Vector49141235")}
          ></Icon>
          <Icon
            width="1.49px"
            height="1.49px"
            viewBox={{
              minX: 0,
              minY: 0,
              width: 1.485957145690918,
              height: 1.4850715398788452,
            }}
            paths={[
              {
                d: "M0.0568513 0.459449C0.213038 0.0819986 0.649059 -0.100219 1.02651 0.055967C1.40396 0.212153 1.58618 0.648173 1.42999 1.02562C1.2738 1.40307 0.837785 1.58529 0.460334 1.4291C0.0763764 1.26641 -0.0993351 0.836899 0.0568513 0.459449Z",
                fill: "rgba(255,255,255,1)",
                fillRule: "nonzero",
              },
            ]}
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            position="absolute"
            top="55.05%"
            bottom="40.92%"
            left="65.03%"
            right="30.94%"
            {...getOverrideProps(overrides, "Vector49141236")}
          ></Icon>
        </View>
      </View>
    </View>
  );
}
