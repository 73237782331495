'use client';

import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { Autocomplete, Button, Flex } from '@aws-amplify/ui-react';
// import { Chart } from 'regraph';
import { getEnvURL } from '../envUtils';

import * as L from 'leaflet/dist/leaflet';
import 'leaflet/dist/leaflet.css';
import '../css/annotation.css';

const USE_NEPTUNE = false;

//1 show 14 exchanges
//2 show 3800 participants
//3 show links between exchange group and participants
//4 styling
//5 filter by exchange name
//6 filter by communication type

// const makeQuery = (queryType, params) => `?queryType=${queryType}&comm_type=${params.comm_type}&exchange=${params.exchange}&type=${params.type}&sub_type=${params.sub_type}&category=${params.category}&city=${params.city}`;

const makeQuery = (queryType, queryParams = {}) => {
	const urlParams = [`queryType=${queryType}`];

	// Add each parameter if it has a value
	for (const [key, value] of Object.entries(queryParams)) {
		if (value) {
			urlParams.push(`${key}=${encodeURIComponent(value)}`);
		}
	}

	// Join url params with '&' and add leading '?'
	const queryString = urlParams.length > 0 ? `?${urlParams.join('&')}` : '';
	console.log("queryString: ", queryString);
	return queryString;
};

let timeoutId;
const debounce = (func, delay) => {
	return (...args) => {
		clearTimeout(timeoutId);
		timeoutId = setTimeout(() => func(...args), delay);
	};
};

Object.filter = (obj, predicate) =>
	Object.keys(obj)
		.filter(key => predicate(obj[key]))
		.reduce((res, key) => (res[key] = obj[key], res), {});

// returns a new object with the values at each key mapped using mapFn(value)
Object.map = (object, mapFn) => {
	return Object.keys(object).reduce(function (result, key) {
		result[key] = mapFn(object[key]);
		return result;
	}, {});
};

const neo4j_api = USE_NEPTUNE ? getEnvURL("REACT_APP_NEPTUNE_API") : getEnvURL("REACT_APP_NEO4J_API");

async function fetchData(queryType, queryParams) {
	const uri = neo4j_api + makeQuery(queryType, queryParams);
	//console.log("uri", uri)

	return await axios({
		url: uri,
		method: 'GET',
		onDownloadProgress: progressEvent => {
			// console.log("progress event", progressEvent)
			const dataChunk = progressEvent?.currentTarget?.response;
			//console.log(dataChunk)
			return dataChunk;
			// dataChunk contains the data that have been obtained so far (the whole data so far)..
			// So here we do whatever we want with this partial data..
			// In my case I'm storing that on a redux store that is used to
			// render a table, so now, table rows are rendered as soon as
			// they are obtained from the endpoint.
		}


	})
		.then((res) => {
			// console.log("Got graph response: ", res, "from uri: ", uri)
			if (res.error) {
				throw Error(res.error);
			}
			return res.data.rawItems;
		})

		// return fetch(uri, {
		// 	method: 'GET',
		// 	credentials: 'same-origin',
		// })
		// .then(response => {
		// 	if (!response.ok) {
		// 	throw new Error(`HTTP error! status: ${response.status}`);
		// 	}
		// 	const decoder = new TextDecoder();
		// 	const chunks = []; // Array to store decoded chunks
		// 	return response.body.getReader().read().then(function processChunk(result) {
		// 		while (!result.done) {
		// 			chunks.push(decoder.decode(result.value));
		// 		}
		// 		return chunks;
		// 	});
		// })
		// .then((chunks) => {
		// 	// Join chunks and parse JSON
		// 	const text = chunks.join('');
		// 	const filtered = text.slice(1); // Remove leading character (optional)
		// 	//console.log("Got graph response: ", filtered, "from uri: ", uri);
		// 	const content = JSON.parse(filtered);
		// 	// Process the parsed content
		// 	return {
		// 		items: content,
		// 	};
		// })
		// .then(response => {
		// 	console.log("got resp ", response)
		// 	if (!response.ok) {
		// 		throw new Error(`HTTP error! status: ${response.status}`);
		// 	}
		// 	return response.text();
		// })
		// .then((text) => {
		// 	const filtered = text.slice(1)
		// 	console.log("Got graph response: ", filtered, "from uri: ", uri)
		// 	const content = JSON.parse(filtered);

		// 	// if server returned some human readable error message
		// 	if (content.error) {
		// 		throw Error(content.error);
		// 	}

		// 	return {
		// 		items: content.rawItems,
		// 	};
		// })
		.catch((err) => {
			console.error(err);
			throw Error(`Error while fetching ${queryType} "${JSON.stringify(queryParams)}"`);
		});
}

//takes a list of objects and creates
//one object with a list of each of the values for each field of an object in the input
function mergeObjectsToFieldLists(objects,
	{ sort, dedup, convert } = { sort: true, dedup: true, convert: (val, key = 0) => { return { id: val, label: val, prop: key }; } }
) {
	//console.log("merge ",objects)
	const mergedData = {};
	const seen = [];
	for (const obj of objects) {
		if (!obj.data) { continue; }
		for (const [key, value] of Object.entries(obj.data)) {
			if (!mergedData[key]) {
				mergedData[key] = [];
			}
			if (!dedup || !seen.includes(value)) {
				mergedData[key].push(convert(value, key));
				seen.push(value);
			}
		}
	}
	// console.log("mergedData: ", mergedData)
	if (sort) { // Sort each value list
		function compare(a, b) {
			if (a.label < b.label) {
				return -1;
			}
			if (a.label > b.label) {
				return 1;
			}
			return 0;
		}
		for (const key in mergedData) {
			mergedData[key].sort(compare);//(a, b) => a.label.localeCompare(b.label)); // Sort numbers in ascending order
		}
	}

	return mergedData;
}



function groupBySameIdAndFilterSingles(data) {
	return Object.entries(data.reduce((acc, obj) => {
		const id = obj.id;
		acc[id] = acc[id] || []; // Initialize if not present
		acc[id].push(obj);
		return acc;
	}, {}))[0][1]
		.filter((ls) => ls.length > 1);
}


const combine = {
	level: 1,
	properties: ['city'],
};

function flatMapObject(obj) {
	const result = {};
	//console.log(obj)
	for (const [key, sublist] of Object.entries(obj)) {
		// //console.log("sl", sublist)
		// Copy all properties except _fields
		Object.keys(sublist).forEach(key => {
			if (key !== '_fields') {
				result[key] = sublist[key];
			}
		});

		// Concatenate all elements within _fields
		result._fields = (result._fields ?? []).concat(...sublist._fields);
	}
	return result;
}

function firstKey(record) {
	if (record == null) {
		return null;
	}
	const keys = Object.keys(record);
	return keys[0];
}

function isNode(item) {
	return item && item.id1 == null && item.id2 == null;
}

const Graph = () => {
	const [api_queryType, setQueryType] = useState("participants");
	const [api_params, setApiParams] = useState({});

	function hslStringToArray(hslString) {
		// Regular expression to match HSL components (fixed for decimals)
		// console.log("hslString:", hslString)
		// const hslRegex = "hsl\(\s*(\d+)\s*,\s*(\d+\.?\d+)%\s*,\s*(\d+\.?\d+)%\s*\)";
		const regex = /\d+(\.\d+)?/g;

		// Extract matches from the string
		const match = hslString.match(regex);
		// console.log("match: ", match)
		// Check if the string is valid HSL format
		if (!match) {
			throw new Error("Invalid HSL string format");
		}

		// Convert matched values to numbers (ignoring the unit "%")
		const hue = parseInt(match[0]);
		const saturation = parseFloat(match[1]);
		const lightness = parseFloat(match[2]);

		// Return the array of HSL values
		return [hue, saturation, lightness];
	}

	const HexToHSL = (hex) => {
		const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

		if (!result) {
			throw new Error("Could not parse Hex Color");
		}

		const rHex = parseInt(result[1], 16);
		const gHex = parseInt(result[2], 16);
		const bHex = parseInt(result[3], 16);

		const r = rHex / 255;
		const g = gHex / 255;
		const b = bHex / 255;

		const max = Math.max(r, g, b);
		const min = Math.min(r, g, b);

		let h = (max + min) / 2;
		let s = h;
		let l = h;

		if (max === min) {
			// Achromatic
			return { h: 0, s: 0, l };
		}

		const d = max - min;
		s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
		switch (max) {
			case r:
				h = (g - b) / d + (g < b ? 6 : 0);
				break;
			case g:
				h = (b - r) / d + 2;
				break;
			case b:
				h = (r - g) / d + 4;
				break;
		}
		h /= 6;

		s = s * 100;
		s = Math.round(s);
		l = l * 100;
		l = Math.round(l);
		h = Math.round(360 * h);

		return { h, s, l };
	};
	const hexToHSL = (hex) => {
		console.log("parsing hex: ", hex);
		const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
		if (!result) {
			throw new Error("Could not parse Hex Color");
		}

		const rHex = parseInt(result[1], 16);
		const gHex = parseInt(result[2], 16);
		const bHex = parseInt(result[3], 16);

		const r = rHex / 255;
		const g = gHex / 255;
		const b = bHex / 255;

		const max = Math.max(r, g, b);
		const min = Math.min(r, g, b);

		let h = (max + min) / 2;
		let s = h;
		let l = h;

		if (max === min) {
			// Achromatic
			return { h: 0, s: 0, l };
		}

		const d = max - min;
		s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
		switch (max) {
			case r:
				h = (g - b) / d + (g < b ? 6 : 0);
				break;
			case g:
				h = (b - r) / d + 2;
				break;
			case b:
				h = (r - g) / d + 4;
				break;
		}
		h /= 6;

		s = s * 100;
		s = Math.round(s);
		l = l * 100;
		l = Math.round(l);
		h = Math.round(360 * h);

		return { h, s, l };
	};

	function hslToHex({ h, s, l }) {
		s /= 100;
		l /= 100;
		let c = (1 - Math.abs(2 * l - 1)) * s,
			x = c * (1 - Math.abs((h / 60) % 2 - 1)),
			m = l - c / 2,
			r = 0,
			g = 0,
			b = 0;

		if (0 <= h && h < 60) {
			r = c; g = x; b = 0;
		} else if (60 <= h && h < 120) {
			r = x; g = c; b = 0;
		} else if (120 <= h && h < 180) {
			r = 0; g = c; b = x;
		} else if (180 <= h && h < 240) {
			r = 0; g = x; b = c;
		} else if (240 <= h && h < 300) {
			r = x; g = 0; b = c;
		} else if (300 <= h && h < 360) {
			r = c; g = 0; b = x;
		}
		// Having obtained RGB, convert channels to hex
		r = Math.round((r + m) * 255).toString(16);
		g = Math.round((g + m) * 255).toString(16);
		b = Math.round((b + m) * 255).toString(16);

		// Prepend 0s, if necessary
		if (r.length == 1)
			r = "0" + r;
		if (g.length == 1)
			g = "0" + g;
		if (b.length == 1)
			b = "0" + b;

		return "#" + r + g + b;
	}


	const getPallete = (color) => {
		let palette = [];
		let style = getComputedStyle(document.body);
		for (let i of [10, 20, 40, 60, 80, 90, 100]) {
			// console.log("color i", color, i)
			const ampl_color = style.getPropertyValue(`--amplify-colors-${color}-${i}`);
			// console.log("amplify color: ", ampl_color)
			if (ampl_color[0] == 'h') {
				let [h, s, l] = hslStringToArray(ampl_color);
				// l += 10
				// s += 10
				// console.log("h, s, l: ", h, s, l)
				const hex = hslToHex({ h, s, l });
				// console.log("hex: ", hex)
				palette.push(hex);
			} else if (ampl_color[0] == '#') {
				// let {h, s, l} = hexToHSL(ampl_color)
				// // l += 10
				// // s += 10
				// const hex_color = hslToHex({h, s, l})
				// console.log("prod hex_color: ", hex_color)
				palette.push(ampl_color);
			}
		}
		return palette;
	};

	const [palettes, setPalettes] = useState({});

	// console.log("Palettes: ", palettes)
	function makeNode(node, show_labels = false) {
		// //console.log("node: ", node)
		if (!node.labels) {
			console.error("Error: Trying to make a node from a non-node item: ", node);
			return;
		}
		const node_id = USE_NEPTUNE ? node.identity.low : node.identity;

		const category_palette_map = {
			"Hospital": palettes['blue'][5],
			"Skilled Nursing Facilities": palettes['blue'][5],
			"Physician organizations and medical groups": palettes['blue'][4],
			"Clinical Laboratories": palettes['blue'][3],
			"Primary with PD selections and Subs": palettes['blue'][2],
			"Primary with Subs, no exchange at Primary level": palettes['blue'][2],
			"Voluntary": palettes['teal'][4],
			"Health Care Service Plans and Disability Insurers": palettes['teal'][3],
			"County (health, public health, social services)": palettes['teal'][2],
			"County (entire county -  Primary Org no Subs)": palettes['teal'][2],
			"State": palettes['teal'][5],
			"QHIO": palettes['teal'][5]
		};

		let re_node;
		node.properties["labels"] = node.labels;
		if (node.labels.includes("Participant")) {
			const word_list = node.properties.Participant_Name_PrimaryOrganization.split(" ");
			const label_text = word_list.slice(0, 4).join(" ") + (word_list.length > 4 ? "..." : "");
			const category = node.properties.DxF_Program_Category;
			const disp_color = category_palette_map[category];

			const lat = node.properties["County Latitude"] * 1000;
			const long = node.properties["County Longitude"] * 1000;
			// console.log("disp_color: ", disp_color)

			re_node = {
				size: 4,
				label: [
					show_labels ? {
						text: label_text,
						"textWrap": "normal",
						"backgroundColor": "hsl(0, 0%, 90%)",
						//"backgroundColor": "rgba(255,255,255,00)",
						// backgroundColor: "#ffffff",
						"fontSize": 8,
						"bold": true,
						// "color": "#00423e",
						// color: "#ffffff"
					} : {},
				],
				coordinates: {
					lat: parseFloat(lat),
					lng: parseFloat(long),
				},
				color: disp_color,
				backup: "#d9d9d9",
				// backgroundColor: "#2dcda8",
				data: node.properties,
			};
		}
		else if (node.labels.includes("Exchange")) {
			const name = node.properties.name;
			const word_list = name.split(" ");
			const label_text = word_list.slice(0, 4).join(" ") + (word_list.length > 4 ? "..." : "");
			re_node = {
				size: 12,
				label: [
					{
						text: label_text,
						"textWrap": "normal",
						// "backgroundColor": "rgba(255,255,255,0)",
						"backgroundColor": "hsl(0, 0%, 90%)",
						"fontSize": 20,
						"bold": true,
						"color": "#00423e",
					},
				],
				color: palettes['green'][2],//"#B3CDE3",
				// backgroundColor: "#2dcda8",
				data: node.properties,
			};
		}
		else if (node.labels.includes("V_Exchanges")) {
			re_node = {
				size: 6,
				text: "All Exchanges",
				color: "#B3CDE3",
				// backgroundColor: "#2dcda8",
				data: node.properties,
				glyphs: [
					{
						"position": "ne",
						"color": "#00423e",
						"size": 1.5,
						"label": {
							"text": node.properties.count
						}
					}
				]
			};
		}
		else {
			console.log("node isn't a participant or exchange", node);
			re_node = {
				text: "test" ?? node.labels[0],
				color: "#FBB4AE",
				// backgroundColor: "#2dcda8",
				data: node.properties,
			};
		}
		// console.log("re_node: ", re_node)
		return { key: node_id, val: re_node };

	}

	function makeLink(relationship, show_labels) {
		//console.log("Link: ", relationship)
		if (relationship.labels) {
			console.error("Error: Trying to make a relationship link from a non-relationship item: ", relationship);
			return;
		}
		const start = relationship.start;
		const end = relationship.end;
		const link_id = `${start}-${end}`;
		var link_color;
		var label;
		switch (relationship.type) {
			case "Communicates_REQ":
				label = "Requests Information";
				link_color = "#FCA15C"; //orange
				break;
			case "Communicates_DLV":
				label = "Delivers Information";
				link_color = "#E13549"; //red
				break;
			case "Communicates_ADT":
				label = "Requests Information";
				link_color = "#F793F4"; //pink
				break;
		}
		// //console.log("making link: ", link_id)
		const link = {
			id1: start,
			id2: end,
			label: show_labels ? {
				text: relationship.type,
				size: 1,
				color: "#2e3842",
				backgroundColor: "#f5f7fa50",
			} : {},
			color: link_color,
			width: 3
		};
		return { key: link_id, val: link };
	}

	const [nodes, setNodes] = useState([]);
	const [counts, setCounts] = useState({});
	const [chartItems, setChartItems] = useState({
		// node1: {
		//   label: [
		//     {
		//       text: 'Welcome to ReGraph!',
		//       fontSize: 20,
		//       color: "#2e3842",
		//       backgroundColor: "rgba(0,0,0,0)",
		//       position: "s",
		//     },
		//   ],
		//   color: "#2dcda8",
		// },
	});
	const [filtered, setFiltered] = useState([]);
	const [participant_properties, setParticipantProperties] = useState({});

	function toRegraphFormat(records, queryType, show_labels) {
		if (!records || records.length < 1) {
			console.error("Error: Format func got no records");
			return;
		}
		// console.log("Converting to regraph format: ", records)
		let items = {};
		let n = 0;
		const nodes = [];
		const relationships = [];

		setCounts({});
		const seen_nodes = new Set();
		const seen_relationships = new Set();
		records.forEach((entry) => {
			const fields = entry._fields;
			const lookup = entry._fieldLookup;
			entry.keys.forEach((key) => {
				const i = fields[lookup[key]];
				if (i.labels && !seen_nodes.has(i.identity)) {
					nodes.push(i);
					seen_nodes.add(i.identity);
				}
				else if (i.labels == undefined && !seen_relationships.has(!i.identity)) {
					// if (n < 100)
					// {//console.log("relationship found ", i); n++}
					relationships.push(i);
					seen_relationships.add(i.identity);
				}
			});
		});

		nodes.forEach((node) => {
			const re_node = makeNode(node, show_labels);
			// if (n < 100) { console.log("made node ", re_node); n++}
			items[re_node.key] = re_node.val;
		});

		relationships.forEach((link) => {
			// //console.log("link ", link)
			const re_link = makeLink(link, show_labels);
			// //console.log("made link: ", re_link)
			items[re_link.key] = re_link.val;
		});
		return items;
	}

	function getItem(chartItemId) {
		if (!chartItems)
			return;
		//console.log("getting ", chartItemId)
		return chartItems[chartItemId];
	}
	const chartRef = useRef(null);
	const [isQueryLoading, setIsQueryLoading] = useState(false);
	const [isQueryError, setIsQueryError] = useState(false);
	const [state, setState] = useState({
		show_annotation: false,
		positions: {},
		selection: {},
		zoom: 1,
		offset: { x: 0, y: 0 },
		annotationPosition: null,
		isDragging: false,
		labels_enabled: true,
	});
	const [group_by, setGroupBy] = useState(USE_NEPTUNE ? "" : "");
	const chart_layout = { tightness: 2 };
	const MAP_BOUNDS = {
		nw: [42.067646, -124.431016],
		se: [32.311509, -114.168645],
	};
	const [map, setMap] = useState(false);
	const [oldPosition, setOldPosition] = useState(null);
	const toggleMap = () => {
		setMap(!map);
		// when we toggle the map we want the annotation to be visible on the revelant node if it already was
		// save the old position
		if (state.annotationPosition) setOldPosition(state.annotationPosition);

		// temporarily hide the annotation during the transition
		setState((current) => ({ ...current, annotationPosition: null }));
	};

	const onClickHandler = ({ id }) => {
		// if the user clicks on the chart background remove the annotation
		if (!id) {
			setState((current) => ({
				...current,
				annotationPosition: null,
			}));
		}
	};

	const onChangeHandler = ({
		positions: newPositions,
		selection: newSelection,
	}) => {
		const chart = chartRef.current;
		// console.log("chart: ", chart)
		if (chart == null) {
			return;
		}

		if (newPositions == null && newSelection == null && !oldPosition) {
			return;
		}

		// if there was an oldPosition, we have toggled the map
		if (oldPosition) {
			const selectedItem = firstKey(state.selection);

			let nextAnnotationPosition;
			const nextPositions = state.annotationPosition;
			if (map && selectedItem.coordinates) {
				// we store the data on the item, so its easy to get the coordinates
				const { coordinates } = chart.getItemInfo(selectedItem);
				nextAnnotationPosition = chart.viewCoordinates(
					coordinates.lng,
					coordinates.lat
				);
			} else {
				// otherwise we need to wait for the chart to load, get the position and place the annotation there
				const { x, y } =
					chart.getViewCoordinatesOfItem(selectedItem);
				nextAnnotationPosition = { x, y };
			}

			setState((current) => ({
				...current,
				annotationPosition: nextAnnotationPosition,
				positions: nextPositions,
			}));
			setOldPosition(null);
		}

		setState((current) => {
			const nextPositions = newPositions || current.positions;
			let nextSelection = current.selection;
			let nextAnnotationPosition = current.annotationPosition;

			if (newSelection) {
				const selectedItemId = firstKey(newSelection);

				if (selectedItemId != null) {
					const selectedItem = chartItems[selectedItemId];
					if (isNode(selectedItem)) {
						nextSelection = { [selectedItemId]: true };
						if (map && selectedItem.coordinates) {
							nextAnnotationPosition = chart.viewCoordinates(
								selectedItem.coordinates.lng,
								selectedItem.coordinates.lat
							);
						} else {
							nextAnnotationPosition = chart.viewCoordinates(
								nextPositions[selectedItemId].x,
								nextPositions[selectedItemId].y
							);
						}
					}
				} else {
					nextSelection = {};
				}
			} else if (current.annotationPosition == null && nextPositions != null) {
				const firstItemId = firstKey(chartItems);
				nextAnnotationPosition = chart.viewCoordinates(
					nextPositions[firstItemId].x,
					nextPositions[firstItemId].y
				);
				nextSelection = { [firstItemId]: true };
			}

			return {
				...current,
				annotationPosition: nextAnnotationPosition,
				selection: nextSelection,
				positions: nextPositions,
			};
		});
	};
	const onDragHandler = ({ type, x, y, draggedItems }) => {
		if (type !== 'node' || firstKey(draggedItems) !== firstKey(state.selection)) {
			return;
		}
		// if dragging the selected node the position isn't updated until the
		// onChange event, so calculate it from the cursor
		setState((current) => {
			return {
				...current,
				isDragging: true,
				annotationPosition: {
					x: x - current.offset.x,
					y: y - current.offset.y,
				},
			};
		});
	};

	const onDragEndHandler = ({ defaultPrevented }) => {
		setState((current) => {
			const chart = chartRef.current;
			const selectedItemId = firstKey(current.selection);
			if (
				!defaultPrevented ||
				chart == null ||
				selectedItemId == null ||
				current.positions[selectedItemId] == null
			) {
				return { ...current, isDragging: false };
			}

			const annotationPosition = chart.viewCoordinates(
				current.positions[selectedItemId].x,
				current.positions[selectedItemId].y,
			);

			return {
				...current,
				isDragging: false,
				annotationPosition,
			};
		});
	};

	// store the offset to the cursor position to correct during a drag
	const onPointerDownHandler = ({ id, x, y }) => {
		const item = chartItems[id];
		const chart = chartRef.current;
		if (id == null || !isNode(item) || chart == null) {
			return;
		}
		setState((current) => {
			if (current.positions) {
				// console.log("current.positions: ", current.positions)
				const position = chart.viewCoordinates(current.positions[id].x, current.positions[id].y);
				const offset = {
					x: x - position.x,
					y: y - position.y,
				};
				return {
					...current,
					offset,
				};
			} else {
				return current;
			}
		});
	};

	const onViewChangeHandler = ({ zoom }) => {
		const chart = chartRef.current;
		if (chart == null) {
			return;
		}

		const selectedItemId = firstKey(state.selection);
		if (
			state.zoom === zoom &&
			(selectedItemId == null || (state.positions == null || state.positions[selectedItemId] == null))
		) {
			return;
		}

		setState((current) => {
			let { annotationPosition } = current;
			if (selectedItemId != null && current.positions != null && current.positions[selectedItemId] != null) {
				annotationPosition = chart.viewCoordinates(
					current.positions[selectedItemId].x,
					current.positions[selectedItemId].y,
				);
			}

			return {
				...current,
				zoom,
				annotationPosition,
			};
		});
	};

	const onWheelHandler = ({ preventDefault }) => {
		if (state.isDragging) {
			preventDefault();
		}
	};

	const handleChartChange = ({ positions }) => {
		if (!positions) {
			return;
		}
		setState((current) => {
			return { ...current, positions: { ...positions } };
		});
	};


	const handleParamSelect = (option) => {
		setApiParams({ ...api_params, [option.prop]: option.label }); // Update specific key-value pair
	};

	const handleGroupBySelect = (option) => {
		setGroupBy(option.label);
	};

	const handleGroupByClear = (option) => {
		setGroupBy(undefined);
	};

	// const handleParamChange = (option) => {
	// 	setApiParams({ ...api_params, [option.prop]: option.label }); // Update specific key-value pair
	// };

	const handleParamClear = (prop) => {
		// setApiParams({ ...api_params, [prop]: "" }); // Update specific key-value pair
		console.log("prop: ", prop);
		setApiParams(current => {
			// remove cost key from object
			const { [prop]: propToRemove, ...rest } = current;
			return rest;
		});
	};

	const submit_query = () => {
		setIsQueryLoading(true);
		debouncedFetch(api_queryType, api_params);
	};
	useEffect(() => {
		console.log("new params: ", api_params);
	}, [api_params]);

	const debouncedFetch = debounce((queryType, params) => {
		console.log("fetching: ", queryType, params);
		try {
			fetchData(queryType, params)
				.then((data) => {
					if (data) {
						// console.log("Got res: ", data);
						setNodes(data);
					} else {
						console.error("Error, no res: ", data);
					}
				});
		} catch (err) {
			console.error("Error while fetching neo4j: ", err);
			setIsQueryLoading(false);
			setIsQueryError(true);
		}
	}, 250); // Debounced fetch with delay of 250ms (adjust as needed)

	useEffect((prev) => {
		// console.log("re-rendering, ", state)
		if (nodes ?? [].length > 0) {
			const items = toRegraphFormat(nodes, api_queryType, state.labels_enabled);
			setState(current => {
				// remove cost key from object
				const { positions: propToRemove, ...rest } = current;
				return rest;
			});
			console.log("items:", items);
			setChartItems(items);
			setIsQueryLoading(false);
			// //console.log("displaying items: ", items)
			// const repeats = groupBySameIdAndFilterSingles(items);
			// //console.log("repeat nodes", repeats);
		}
	}, [nodes]);

	//Automatically filter nodes when params change
	useEffect(() => {
		//filter items
		console.log("filtering chartItems: ", chartItems, " by params: ", api_params);
		if (!chartItems || Object.keys(chartItems).length <= 1)
			return;
		let filteredItemsss;

		if (api_queryType == "connected") {
			filteredItemsss = Object.filter(chartItems, (item) => {
				// console.log("start filter item: ", item)
				if (!item.data) { return true; } //don't modify links
				if (item.data.labels.includes("Exchange")) { return true; }
				return Object.entries(api_params).reduce((result, entry) => {
					let [key, value] = entry;
					// console.log("k: v", key, value)
					return result && (!value || item.data[key] == value);
				}, true);
			});
			// if (!state.show_labels){
			// 	filteredItemsss = Object.map(filteredItemsss, (item) => {
			// 		const { label: propToRemove, ...rest } = item;
			// 		return rest
			// 	})
			// }
		} else if (api_queryType != "method") {
			filteredItemsss = Object.map(chartItems, (item) => {
				// console.log("start filter item: ", item)
				if (!item.data) { return item; } //don't modify links
				const is_selected = Object.entries(api_params).reduce((result, entry) => {
					let [key, value] = entry;
					// console.log("k: v", key, value)
					return result && (!value || item.data[key] == value);
				}, true);
				// console.log(item, "is_selected: ", is_selected)
				item.is_selected = is_selected;
				if (is_selected) {
					if (item.color == "#d9d9d9") {
						item.color = item.backup;
						item.backup = "#d9d9d9";
					}
				} else if (!is_selected) {
					if (item.color != "#d9d9d9") {
						item.backup = item.color;
						item.color = "#d9d9d9";
					}
					// console.log("end filter item: ", item)
				}
				// if (!state.show_labels){
				// 	const { label: propToRemove, ...rest } = item;
				// 	return rest
				// }
				return item;
			});
		} else {
			filteredItemsss = chartItems;
		}
		// const newFilteredItems = Object.values(chartItems).filter((chartItem) => {
		// 	// const chartItem = chartItemArr[1]
		// 	// console.log("node_id: ", node_id, "chart item",  chartItem)
		// 	return
		// })
		// console.log("chart items: ", chartItems, " -> newFilteredItems ", filteredItemsss)
		calculate_filter_props(Object.values(filteredItemsss));
		setFiltered(window.structuredClone(filteredItemsss));

	}, [api_params, chartItems]);

	// useEffect(() => {
	// 	console.log("filtered: ", filtered)
	// }, [filtered])

	const calculate_filter_props = (items) => {
		// console.log("counting props from ", items)
		const newCounts = {};
		items.forEach((item) => {
			if (!item.data) { return; }
			// console.log("Counting node: ", item, " = ", newCounts[item.data.labels[0]])
			// Access and increment the count for the key
			newCounts[item.data.labels[0]] = (newCounts[item.data.labels[0]] || 0) + 1;

			// Return the updated state object
			// return newCounts;
			// if (n < 100) {console.log("made node ", re_node); n++}
		});
		setCounts(newCounts);
		const node_properties = mergeObjectsToFieldLists(items);
		//console.log("part props = ", node_properties)
		setParticipantProperties(node_properties);
	};


	useEffect(() => {
		submit_query();
		setPalettes({
			teal: getPallete("teal"),
			blue: getPallete("blue"),
			orange: getPallete("orange"),
			green: getPallete("green")
		});
		// return () => {
		// 	window.removeEventListener('resize', handleResize);
		// };
	}, []);

	const layoutRef = useRef({ tightness: 2 });
	const handleResize = () => {
		setState((current) => {
			if (firstKey(current.selection) == null) {
				return current;
			}

			return {
				...current,
				selection: {},
			};
		});
	};
	window.addEventListener('resize', handleResize);

	let selectedItemId = firstKey(state.selection);
	let show_annotation = false;
	return (
		<div className="" style={{ height: '100%', width: '100%' }}>

			<div className='graph-outer-flexbox'>
				<Flex
					direction={"column"}
					gap={"0px"}
				>
					<Button
						isLoading={isQueryLoading}
						// type="submit"
						onClick={submit_query}
						loadingText="Querying"
						variation="primary"
						title="Query to request a new graph type or to filter out grayed nodes"
					>
						{isQueryError ? "Error" : "Query"}
					</Button>

					<select onChange={(e) => { setQueryType(e.target.value); setApiParams({}); }}>
						<option value="participants">participants</option>
						<option value="exchanges">exchanges</option>
						<option value="connected">connected</option>
						<option value="method">method</option>
					</select>
				</Flex>
				<Flex
					direction={"column"}
					gap={"0px"}
					className={"toggle-set"}
				>
					<Button onClick={toggleMap}>
						Toggle Map
					</Button>
					<Button
						onClick={() => {
							console.log("toggling labels");
							setState((prevState) => { return { ...prevState, labels_enabled: !prevState.labels_enabled }; });
						}}
						variation="primary"
					>
						Toggle Labels
					</Button>
					<Autocomplete
						label="Autocomplete group_by"
						options={Object.keys(participant_properties).length > 0 ? Object.keys(participant_properties).reduce((result, key) => {
							result.push({ prop: key, label: key });
							return result;
						}, []) : []}
						size="small"
						name="group_by"
						placeholder="Group By"
						id="group_by"
						onSelect={handleGroupBySelect}
						onClear={() => { handleGroupByClear(); }}
					/>
				</Flex>


				<table style={{ minWidth: "fit-content", tableLayout: "fixed" }}>
					<thead>
						<tr>
							<th>Type</th>
							<th>Count</th>
						</tr>
					</thead>
					<tbody>
						{Object.entries(counts).map(([type, count]) => (
							<tr key={type}>
								<td>{type}</td>
								<td>{count}</td>
							</tr>
						))}
					</tbody>
				</table>

				<form className="graph-inner-flexbox">
					{api_queryType == "connected" &&
						<div
							className="graph-filter-autocomplete"
						>
							<Autocomplete
								label="Autocomplete comm_type"
								options={[
									{ id: "Request", label: "Request", prop: "comm_type" },
									{ id: "Deliver", label: "Deliver", prop: "comm_type" },
									{ id: "ADT", label: "ADT", prop: "comm_type" }
								]}
								size="small"
								name="comm_type"
								placeholder="Communication Type"
								id="comm_type"
								onSelect={handleParamSelect}
								onClear={() => { handleParamClear('comm_type'); }}
							/>
						</div>}{api_queryType == "connected" &&
							<div
								className="graph-filter-autocomplete"
							>
								<Autocomplete
									label="Autocomplete exchange_name"
									options={[
										{ id: "NONE SELECTED", label: "NONE SELECTED", prop: "exchange_name" },
										{ id: "Manifest MedEx", label: "Manifest MedEx", prop: "exchange_name" },
										{ id: "Long Health", label: "Long Health", prop: "exchange_name" },
										{ id: "NOT APPLICABLE", label: "NOT APPLICABLE", prop: "exchange_name" },
										{ id: "CommonWell Health Alliance", label: "CommonWell Health Alliance", prop: "exchange_name" },
										{ id: "SELF", label: "SELF", prop: "exchange_name" },
										{ id: "Los Angeles Network for Enhanced Services (LANES)", label: "Los Angeles Network for Enhanced Services (LANES)", prop: "exchange_name" },
										{ id: "Carequality", label: "Carequality", prop: "exchange_name" },
										{ id: "ONBOARDING TO QHIO", label: "ONBOARDING TO QHIO", prop: "exchange_name" },
										{ id: "Orange County Partners in Health HIE", label: "Orange County Partners in Health HIE", prop: "exchange_name" },
										{ id: "Cozeva", label: "Cozeva", prop: "exchange_name" },
										{ id: "SacValley MedShare", label: "SacValley MedShare", prop: "exchange_name" },
										{ id: "San Diego Health Connect", label: "San Diego Health Connect", prop: "exchange_name" },
										{ id: "OTHER", label: "OTHER", prop: "exchange_name" },
										{ id: "eHealth Exchange", label: "eHealth Exchange", prop: "exchange_name" },
										{ id: "Serving Communities Health Information Organization", label: "Serving Communities Health Information Organization", prop: "exchange_name" },
										{ id: "Health Gorilla", label: "Health Gorilla", prop: "exchange_name" },
										{ id: "DirectTrust", label: "DirectTrust", prop: "exchange_name" }
									]}
									size="small"
									name="exchange_name"
									placeholder="Exchange"
									id="exchange_name"
									onSelect={handleParamSelect}
									onClear={() => { handleParamClear('exchange_name'); }}
								/>
							</div>
					}{api_queryType != "exchanges" && api_queryType != "method" &&
						<div
							className="graph-filter-autocomplete"
						>
							<Autocomplete
								label="Autocomplete participant_type"
								options={participant_properties.Type ?? []}
								size="small"
								name="participant_type"
								placeholder="Type"
								id="participant_type"
								onSelect={handleParamSelect}
								onClear={() => { handleParamClear('Type'); }}
							/>
						</div>}
					{api_queryType != "exchanges" && api_queryType != "method" &&
						<div
							className="graph-filter-autocomplete"
						>
							<Autocomplete
								label="Autocomplete participant_sub_type"
								options={participant_properties.Sub_Type ?? []}
								size="small"
								name="participant_sub_type"
								placeholder="Sub Type"
								id="participant_sub_type"
								onSelect={handleParamSelect}
								onClear={() => { handleParamClear('Sub_Type'); }}
							/>
						</div>}
					{api_queryType != "exchanges" && api_queryType != "method" &&
						<div
							className="graph-filter-autocomplete"
						>
							<Autocomplete
								label="Autocomplete participant_zip_code"
								options={participant_properties["ZIP_code Clean"] ?? []}
								size="small"
								name="participant_zip_code"
								placeholder="Zip Code"
								id="participant_zip_code"
								onSelect={handleParamSelect}
								onClear={() => { handleParamClear("ZIP_code Clean"); }}
							/>
						</div>}
					{api_queryType != "exchanges" && api_queryType != "method" &&
						<div
							className="graph-filter-autocomplete"
						>
							<Autocomplete
								label="Autocomplete participant_city"
								options={participant_properties["City Clean"] ?? []}
								size="small"
								name="participant_city"
								placeholder="City"
								id="participant_city"
								onSelect={handleParamSelect}
								onClear={() => { handleParamClear("City Clean"); }}
							/>
						</div>}
					{api_queryType != "exchanges" && api_queryType != "method" &&
						<div
							className="graph-filter-autocomplete"
						>
							<Autocomplete
								label="Autocomplete participant_county"
								options={participant_properties.County ?? []}
								size="small"
								name="participant_county"
								placeholder="County"
								id="participant_county"
								onSelect={handleParamSelect}
								onClear={() => { handleParamClear('County'); }}
							/>
						</div>}
					{api_queryType != "exchanges" && api_queryType != "method" &&
						<div>
							{/* <label htmlFor="participant_category">Participant Category:</label> */}
							<Autocomplete
								label="Autocomplete participant_category"
								options={participant_properties.DxF_Program_Category ?? []}
								size="small"
								name="participant_category"
								placeholder="Category"
								id="participant_category"
								onSelect={handleParamSelect}
								onClear={() => { handleParamClear('DxF_Program_Category'); }}
							/>
						</div>
					}
					{api_queryType == "method" &&
						<div>
							{/* <label htmlFor="participant_category">Participant Category:</label> */}
							<Autocomplete
								label="Autocomplete method_type"
								options={[
									{ id: "Direct Secure Email", label: "Direct Secure Email", prop: "method_type" },
									{ id: "sFTP", label: "sFTP", prop: "method_type" },
									{ id: "IHE", label: "IHE", prop: "method_type" },
									{ id: "Point to Point", label: "Point to Point", prop: "method_type" },
									{ id: "HL7 v2", label: "HL7 v2", prop: "method_type" },
									{ id: "FHIR", label: "FHIR", prop: "method_type" },
									{ id: "Other", label: "Other", prop: "method_type" },
									{ id: "Third Party Referral", label: "Third Party Referral", prop: "method_type" },
									{ id: "Portal", label: "Portal", prop: "method_type" },
								]}
								size="small"
								name="method_type"
								placeholder="Method"
								id="method_type"
								onSelect={handleParamSelect}
								onClear={() => { handleParamClear('method'); }}
							/>
							<p>Press Query again to filter</p>
						</div>
					}
				</form>
			</div>
			{/* <Chart className="regraph-chart" height='100% !important' width='100% !important'
				ref={chartRef}
				items={filtered}
				selection={state.selection}
				combine={group_by ? {
					properties: [group_by],
					level: 2,
				} : {}}
				options={{
					dragPan: false,
					fit: 'auto',
					iconFontFamily: 'Font Awesome 5 Free',
					imageAlignment: { 'fa-user': { size: 0.9, dy: -3 } },
					minZoom: -10,
					navigation: false,
					map: {
						tiles: {
							url: 'https://cartodb-basemaps-{s}.global.ssl.fastly.net/light_all/{z}/{x}/{y}.png',
							attribution:
								'&copy; <a href="http://www.openstreetmap.org/copyright" target="_blank">OpenStreetMap</a> &copy; <a href="http://cartodb.com/attributions" target="_blank">CartoDB</a>',
						},
						leaflet: {
							maxZoom: 13,
							maxBounds: L.latLngBounds(MAP_BOUNDS.nw, MAP_BOUNDS.se),
							maxBoundsViscosity: 1,
						},
					},
				}}
				map={map}
				positions={api_queryType != "connected" ? state.positions : undefined}
				animation={{ animate: true, time: 650 }}
				layout={layoutRef}
				onChange={onChangeHandler}
				onDrag={onDragHandler}
				onDragEnd={onDragEndHandler}
				onPointerDown={onPointerDownHandler}
				onViewChange={onViewChangeHandler}
				onWheel={onWheelHandler}
				onClick={onClickHandler}
			/> */}
			{selectedItemId && getItem(selectedItemId) && state.annotationPosition != null && (
				<Annotation
					position={state.annotationPosition}
					info={(getItem(selectedItemId) ?? {}).data ?? {}}
					size={(getItem(selectedItemId) ?? {}).size ?? 0}
					zoom={state.zoom}
				/>
			)}
		</div>
	);
};

function getFormHeight(className) {
	const formElement = document.querySelector(`.${className}`);

	if (formElement) {
		// Use getBoundingClientRect() for most accurate height
		const rect = formElement.getBoundingClientRect();
		return rect.height;
	} else {
		// Handle case where form is not found
		console.warn(`Form with class "${className}" not found.`);
		return 0; // Or return null or any default value
	}
}

function Annotation({ position, info, zoom, size = 1 }) {
	const style = {
		top: `${position.y - 70 + getFormHeight("graph-filters") + getFormHeight("graph-outer-flexbox") + getFormHeight("amplify-tabs__list") + 12}px `,
		left: `calc(${position.x + (20 + size * zoom * 30) + 2}px + var(--side-nav) + 5%)`,
	};
	return (
		<div className="annotation" style={style}>
			<div className="annotation-arrow" />
			<div className="annotation-header">{info.name}</div>
			{info && Object.entries(info).map(([key, value]) => (
				key != "name" && (
					<AnnotationRow key={key} title={key} content={value} />
				)
			))}
		</div>
	);
}

function AnnotationRow({ title, content }) {
	return (
		<div className="annotation-row">
			<div className="annotation-column"><b>{title}: </b></div>
			<div>{content}</div>
		</div>
	);
}

export default Graph;