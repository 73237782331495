// import { Amplify, Auth } from 'aws-amplify';
import { Heading, Image, Text, View, useTheme, useAuthenticator, Button } from '@aws-amplify/ui-react';
import Logo from './Logo';

export const components = {
	Header() {
		const { tokens } = useTheme();

		return (
			<View textAlign="center" padding={tokens.space.large}>
			<Logo/>
			</View>
		);
	},

	Footer() {
		const { tokens } = useTheme();

		return (
			<View textAlign="center" padding={tokens.space.large}>
			<Text color={tokens.colors.neutral[80]}>
			&copy; All Rights Reserved
			</Text>
			</View>
		);
	},

	SignIn: {
		Header() {
			const { tokens } = useTheme();

			return (
				<Heading
				padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
				level={3}
				>
				Sign in to your account
				</Heading>
			);
		},
		Footer() {
			const { toForgotPassword } = useAuthenticator();

			return (
				<View textAlign="center">
				<Button
				fontWeight="normal"
				onClick={toForgotPassword}
				size="small"
				variation="link"
				>
				Reset Password
				</Button>
				</View>
			);
		},
	},

	SignUp: {
		Header() {
			const { tokens } = useTheme();

			return (
				<Heading
				padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
				level={3}
				>
				Create a new account
				</Heading>
			);
		},
		Footer() {
			const { toSignIn } = useAuthenticator();

			return (
				<View textAlign="center">
				<Button
				fontWeight="normal"
				onClick={toSignIn}
				size="small"
				variation="link"
				>
				Back to Sign In
				</Button>
				</View>
			);
		},
	},
	ConfirmSignUp: {
		Header() {
			const { tokens } = useTheme();
			return (
				<Heading
				padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
				level={3}
				>
				Enter Information:
				</Heading>
			);
		},
		Footer() {
			return <Text>Footer Information</Text>;
		},
	},
	SetupTotp: {
		Header() {
			const { tokens } = useTheme();
			return (
				<Heading
				padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
				level={3}
				>
				Enter Information:
				</Heading>
			);
		},
		Footer() {
			return <Text>Footer Information</Text>;
		},
	},
	ConfirmSignIn: {
		Header() {
			const { tokens } = useTheme();
			return (
				<Heading
				padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
				level={3}
				>
				Enter Information:
				</Heading>
			);
		},
		Footer() {
			return <Text>Footer Information</Text>;
		},
	},
	ForgotPassword: {
		Header() {
			const { tokens } = useTheme();
			return (
				<Heading
				padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
				level={3}
				>
				Enter Information:
				</Heading>
			);
		},
		Footer() {
			return <Text>Footer Information</Text>;
		},
	},
	ConfirmResetPassword: {
		Header() {
			const { tokens } = useTheme();
			return (
				<Heading
				padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
				level={3}
				>
				Enter Information:
				</Heading>
			);
		},
		Footer() {
			return <Text>Footer Information</Text>;
		},
	},
};

export const formFields = {
	signIn: {
		username: {
			placeholder: 'Enter your email',
		},
	},
	signUp: {
		email: {
			label: 'Email:',
			placeholder: 'Enter your email',
			isRequired: true,
			order: 1,
		},
		password: {
			label: 'Password:',
			placeholder: 'Enter your Password:',
			isRequired: true,
			order: 2,
		},
		confirm_password: {
			label: 'Confirm Password:',
			order: 3,
		},
	},
	forceNewPassword: {
		password: {
			placeholder: 'Enter your Password:',
		},
	},
	forgotPassword: {
		username: {
			placeholder: 'Enter your email:',
		},
	},
	confirmResetPassword: {
		confirmation_code: {
			placeholder: 'Enter your Confirmation Code:',
			label: 'New Label',
			isRequired: false,
		},
		confirm_password: {
			placeholder: 'Enter your Password Please:',
		},
	},
	setupTotp: {
		QR: {
			totpIssuer: 'test issuer',
			totpUsername: 'amplify_qr_test_user',
		},
		confirmation_code: {
			label: 'New Label',
			placeholder: 'Enter your Confirmation Code:',
			isRequired: false,
		},
	},
	confirmSignIn: {
		confirmation_code: {
			label: 'New Label',
			placeholder: 'Enter your Confirmation Code:',
			isRequired: false,
		},
	},
};