const estella_ruiz = {
	slug: "estella_ruiz",
	table: {
		Title: "Emergency Department Care Transition",
		Category: "Aging",
		Persona: "Estella Ruiz",
		Actors: ["ED", "HH", "QHIO"],
	},
	page: {
		Summary: `
			Estella falls at home and is taken to the Emergency Department (ED) where they send a notification of the injury to the local QHIO who sends a notification to her PCP and MCP. The ED clinical staff member identifies through a survey that Estella is at risk for food and housing instability and records in her EHR that Estella will need these needs addressed. After she is discharged from the hospital, Estella is referred to a home health services and a provider monitors and evaluates her until she is feeling better.
		`,

		Transactions: [
			"ED sends event notification to QHIO",
			"QHIO ingests ADT",
			"QHIO notifies PCP",
			"QHIO notifies MCP Administrator",
			"ED asks QHIO if they have patient's data (patient discovery)",
			"Request sent to QHIO(document discovery)",
			"Response returned(document retrieve)",
			"ED asks the QHIO if they have patient's data",
			"ED EHR sends order information(referral, planned intervention, ordered activity) to HH EHR",
			"HH confirms receipt of order",
			"HH completes the service requested",
			"ED EHR is informed of the completion",
			"ED EHR confirms receipt of order",
			"After each visit to PCP, Estella's encounter data for that visit is pushed to QHIO (data aggregator)",
			"QHIO accepts the data and conducts aggregation",
			"MCP requests aggregated patient information for a given time period from QHIO",
			"QHIO gathers relevant information",
			"QHIO sends patient information back to MCP",
		],
		artifacts: [
			{
				title: "Use Case Overview",
				pdf: "https://drive.google.com/file/d/155A5OmQ7n9n2ct-7hypGH_Iywt3XpBNY/preview"
			},
			{
				title: "Data Set Requirements",
				embed_link: "https://docs.google.com/spreadsheets/d/1ZoOfsfqA3IKzmO0qZRWvi_N_AO_zAr2S/pubhtml?gid=788357733&single=true"
			},
			{
				title: "Synthetic Data",
				files: [
					{ name: "EDCT EstellaRuiz_C-CDA Referral.txt", path: "https://drive.google.com/file/d/1PI_-cTrqNFrwAY18ortVcTxFRUlN6tDK/view?usp=sharing" },
					{ name: "EDCT EstellaRuiz_JSON.json", path: "https://drive.google.com/file/d/1wppvBvSr8X_K3dEaAybVs3jAct5yPetn/view?usp=sharing" },
					{ name: "EDCT Estella-Ruiz_HL7-V2_ADT-A01.hl7", path: "https://drive.google.com/file/d/1Qhv0ZFBd2aOnPKcYwRvjlby-q7YoIX9v/view?usp=sharing" },
				]
			},
			{
				title: "Validation Report",
				pdf: "https://drive.google.com/file/d/1Czzjll8Byhh0T582T-dr2-pgH3uwdGyI/preview"
			}
		]
	}
}

export default estella_ruiz;