// src/data/ApiDatas/Cerner/summary.js
const cernerSummary = {
	provider: "Cerner",
	logo: "https://upload.wikimedia.org/wikipedia/commons/3/3f/2011.Cerner.logo.png",
	provider_description: `
VA Developer is a product of the VA Lighthouse Developer Experience program, which is part of VA's Digital Modernization strategy. Since August 2018, we've been giving approved individuals and organizations access to the VA data they need. Our platform adheres to Office of Information and Technology (OIT) standards and technology to provide these approved entities—our consumers— with the highest quality integration experience.

What do we do?

We give our consumers no-cost access to VA data through our APIs. We never, ever charge fees.

Why do we do it?

Our APIs empower partners to build innovative, Veteran-centered apps with the goal of better serving those who have served us.

What are APIs?

APIs are application programming interfaces, which allow applications to send and retrieve data without having to build functionality from scratch. We publish only modern, RESTful APIs.

Why use our APIs?

Integrating with our APIs allows you to access the most up-to-date VA data with the least amount of effort. When you choose to integrate your application with one or more of our APIs, you're getting trusted and reliable access to the data you need with the highest standards for security, performance, and more.
	`,
	table: {
		Title: "Cerner (VA)",
		Categories: ["Organizational"],
		Protocols: ["json"],
		Authentication: ["API key"],
	},
};

export default cernerSummary;
