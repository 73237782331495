import { getEnvURL } from '../../../envUtils';
const smile_api = getEnvURL('REACT_APP_FETCH_CDR_PATIENT_DATA');


const smile_api_patient = {
    slug: "Patient",
    table: {
        Title: "Patient",
        Categories: ["Healthcare"],
        Protocols: ["http"],
        Authentication: ["Basic Auth"], // Adjust according to your API's auth method
    },
    page: {
        Summary: `
        http://ec2-100-25-223-189.compute-1.amazonaws.com:8000/Patient

Background

The SMILE Patient API provides a comprehensive view of patient profiles, crucial for healthcare systems that require up-to-date information on patients under their care. This API facilitates the retrieval of extensive patient data, including demographics, healthcare providers, communication details, and identifiers, ensuring a holistic understanding of patient profiles.

Authentication and Authorization

Secure access to the Patient API is managed through Basic Authentication, requiring users to authenticate using credentials provided in the HTTP header. This secure method ensures that patient information is accessed only by authorized personnel, maintaining privacy and compliance with healthcare regulations.

Technical Overview

The Patient API delivers detailed information, including:

- **Identifiers and Records**: Offers multiple identifiers, including official and secondary IDs that help in tracking patient records across different systems.

- **Demographic Details**: Provides comprehensive demographic information such as names, birth dates, and addresses, which are essential for personal identification and administrative processes.

- **Contact Information**: Includes multiple contact details to facilitate communication with patients.

- **Healthcare Provider Connections**: Lists the patient's general practitioners and associated healthcare professionals, linking to their detailed profiles within the system.

- **Extensions and Attributes**: Captures additional attributes like citizenship and religious affiliations which may influence care protocols and preferences.

- **Real-Time Updates**: Ensures that data such as practitioner updates, patient status changes (e.g., deceased status), and other modifications are reflected promptly to provide the most current data snapshot.

Summary of API Usage

This API is designed for use by healthcare providers, administrative staff, and health IT systems. It supports functionalities like patient management systems, emergency healthcare services, and personalized patient care planning. The API's detailed patient information aids in the accurate and efficient delivery of healthcare services, optimizing both clinical outcomes and patient satisfaction.

        `, 
        Endpoint: smile_api,
        Authentication: ["Basic Auth"], 
        Dataflow: ["JSON"],
        example_query: {
            name: 'Query patients',
            method: 'GET',
            url: smile_api,
        },
    }
}

export default smile_api_patient;