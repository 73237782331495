import React from 'react';
import { Button, Flex, Table } from '@aws-amplify/ui-react';
import { Accordion, Text, View } from '@aws-amplify/ui-react';
import font from '../../css/fonts.module.css';

import FileDownloadList from "../Files/FileDownloadList.js";
import FileListItem from '../Files/FileListItem.js';

const UsecaseDetail = ({ usecase }) => {
	const { table, page } = usecase;

	return (
		<View padding="20px">
			{/* Display the table */}
			<h2 className={`${font.h2}`}>{table.title}</h2>
			<Table variation="bordered">
				<thead>
					<tr>
						<th style={{ width: '1%' }}>Category</th>
						<th style={{ width: '1%' }}>Persona / Name of Story</th>
						<th style={{ width: '1%' }}>System Actors</th>
						<th style={{ width: '30%' }}>Summary</th>
						<th style={{ width: '80%' }}>Transactions</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td>{table.Category}</td>
						<td>{table.Persona}</td>
						<td>{table.Actors.join('; ')}</td>
						<td>
							<Text>{page.Summary}</Text>
						</td>
						<td style={{ fontSize: '15px' }}>
							<ol>
								{page.Transactions.map((transaction, index) => (
									<li key={index}>{transaction}</li>
								))}
							</ol>
						</td>
					</tr>
				</tbody>
			</Table>

			{/* Display the artifacts using AWS Amplify Accordion */}
			<div className="accordion-wrapper">
				<Accordion.Container className="accordion">
					{page.artifacts.map((artifact, index) => {
						return (

							<Accordion.Item key={index} value={`item-${index}`}>
								<Accordion.Trigger>
									{artifact.title}
								</Accordion.Trigger>
								<Accordion.Content>
									{artifact.description && (
										<Text>{artifact.description}</Text>
									)}

									{/* Conditionally render content based on artifact properties */}
									{artifact.files && (
										<FileDownloadList files={artifact.files} renderItem={(file) => {
											file.is_path_direct_url = true;
											return (
												<FileListItem key={file.id} file={file} is_deletable={false} />
											);
										}}>
										</FileDownloadList>
									)}

									{artifact.pdf && (
										<View padding="20px 0">
											<iframe
												src={artifact.pdf}
												width="100%"
												height="800px"
												fontFamily="Montserrat"
												style={{ border: 'none', marginBottom: 72 }}
												title={artifact.title}
											/>
										</View>
									)}

									{artifact.embed_link && (
										<View padding="20px 0">
											<iframe
												src={artifact.embed_link}
												width="100%"
												height="600px"
												title={artifact.title}
												style={{ border: 'none' }}
											></iframe>
										</View>
									)}
								</Accordion.Content>
							</Accordion.Item>
						);
					})}
				</Accordion.Container>
			</div >
		</View >
	);
};

export default UsecaseDetail;
