import React, { useEffect, useState, useContext, useMemo, useRef } from 'react';
import { Tabs } from '@aws-amplify/ui-react';
import axios from 'axios';
import { StaticTreeDataProvider, Tree, TreeEnvironmentRef, UncontrolledTreeEnvironment } from 'react-complex-tree';
import { SessionContext } from '../App'; // Assuming this is the correct path
import font from "../css/fonts.module.css";
import 'react-complex-tree/lib/style-modern.css';
import '../css/ftp.css'
import { getEnvURL } from '../envUtils';

const generateUUID = () => {
	const combinedString = performance.now().toString(36) + Math.random().toString(36).slice(2, 15);
	const uuid = btoa(combinedString).replace(/\W+/g, '-').replace(/\W/g, ''); // Print the generated ID to the console
	// console.log("uuid", uuid)
	return uuid;
}

Object.filter = (obj, predicate) =>
	Object.keys(obj)
		.filter(key => predicate(obj[key]))
		.reduce((res, key) => (res[key] = obj[key], res), {});

// returns a new object with the values at each key mapped using mapFn(value)
Object.map = (object, mapFn) => {
	return Object.keys(object).reduce(function (result, key) {
		result[key] = mapFn(object[key])
		return result
	}, {})
}

const FTP = () => {
	const [directories, setDirectories] = useState({});
	const [tab, setTab] = useState('upload');
	const [activeTab, setActiveTab] = useState('uploadFiles');
	const [selectedFile, setSelectedFile] = useState(null);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState('');
	const { session } = useContext(SessionContext);
	const [outboundDirectories, setOutboundDirectories] = useState({});
	const [expandedGroups, setExpandedGroups] = useState({});
	const [uploadDirectory, setUploadDirectory] = useState('Inbound');
	const [upload_directories, setUploadDirectories] = useState([])



	// when session changes
	useEffect(() => {
		if (!session || !session.userSub) {
			setError("User session is not available.");
			return;
		} else {
			// setUploadDirectory(session["custom:member_of_dxf"])
			setError("")
		}
		console.log("querying ftp")
		setLoading(true);
		query_ftp();

	}, [session]);

	useEffect(() => {
		if (Object.values(directories) ?? [].length > 0) {
			console.log("directories: ", directories)
			setLoading(false)
		}
		setUploadDirectories(Object.values(directories).filter(dir => {
			return (dir.index != "root" && dir.isFolder)
		}))
	}, [directories])

	useEffect(() => {
		console.log("uploadDirectory: ", uploadDirectory)
	}, [uploadDirectory])

	useEffect(() => {
		if (selectedFile)
			handleUpload();
	}, [selectedFile])

	const make_tree_dir = (dir) => {
		return {
			index: dir.name.replace(/ /g, "_").split(/[^A-Za-z_]/)[0] + generateUUID(),
			name: dir.name,
			isFolder: true,
			children: dir.files?.map(make_tree_file),
			data: {}
		}
	}

	const make_tree_file = (file) => {
		return {
			index: file.name.replace(/ /g, "_").split(/[^A-Za-z_]/)[0] + generateUUID(),
			name: file.name,
			isFolder: false,
			data: { timestamp: file.attrs?.mtime }
		}
	}



	const chop = (str, suffix) => {
		const suffixRegex = new RegExp(suffix + '(.*)$'); // Capture trailing characters
		const match = str.match(suffixRegex);
		console.log("chop: ", str, suffix, match)
		return match ? str.slice(0, match.index) : str;
	};

	const parseS3Data = (s3Data) => {
		const items = {
			root: {
				index: 'root',
				isFolder: true,
				children: [],
				name: 'Root item',
			},
		};
		s3Data.forEach((key) => {
			console.log("key: ", key)
			const is_dir = key.slice(-1) === '/'
			const parts = key.split('/').filter(item => item);
			const file_name = parts.slice(-1)[0];
			const file_id = parts.slice(-1)[0] + generateUUID();
			console.log("file_id: ", file_id)
			items[file_id] = {
				index: file_id,
				isFolder: is_dir,
				children: [],
				name: file_name,
				fullpath: key.substring(0, key.lastIndexOf('/') + 1)
			}
			// console.log("parts.slice(-2)[0]: ", parts.slice(-2)[0])
			if (parts.length > 1) {
				const parent_name = parts.slice(-2)[0];
				// const parent_id = ;
				const parent_id = Object.values(items).find(item => item.name == parent_name)?.index || parts.slice(-2)[0] + generateUUID()
				console.log("parent ", parent_id)
				if (!(parent_id in items)) {
					items[parent_id] = {
						index: parent_id,
						isFolder: true,
						children: [],
						name: parent_name,
						fullpath: chop(key, file_name)
					}
					if (parent_id.includes("DXF")) {
						items['root'].children.push(parent_id)
					}
				}
				items[parent_id].children.push(file_id)

			}
			else if (parts.length = 1)
				items['root'].children.push(file_id)
		});
		return items;
	};

	const query_ftp = () => {
		console.log("session: ", session)
		if (!session) {
			return;
		} else if (!session["custom:member_of_dxf"]) {
			setError("You are not a member of a DxF Participant. Please submit a registration on our registration page titled 'Sandbox Registration' to join.");
			setLoading(false)
			return;
		}

		const base_dir = session["custom:member_of_dxf"]
		console.log("base_dir: ", base_dir)

		// const dirs_uri = `${getEnvURL("REACT_APP_FTP_DIRS_API")}?userSub=${base_dir}`
		const dirs_uri = `${getEnvURL("REACT_APP_FTP_FILES_API")}?dir=${base_dir}`
		axios.get(dirs_uri)
			.then(dirResponse => {
				console.log('dir response', dirResponse);
				if (dirResponse.data.length === 0) {
					setError("You are not a member of a DxF Participant. Submit a Registration to join as an Admin, or contact us.");
					return;
				} else {
					setError("")
				}
				const parsedData = parseS3Data(dirResponse.data);
				console.log("parsedData: ", parsedData)
				const filteredData = Object.filter(parsedData, (dir, obj) => (true));
				console.log("filteredData: ", filteredData)
				setDirectories(filteredData)
			});
	}





	useEffect(() => {
		const fetchDirectories = async () => {
			try {
				const dirs_uri = `${getEnvURL("REACT_APP_S3_LIST_FILES_API")}`;
				const response = await axios.get(dirs_uri);
				console.log("API response:", response.data);

				// Parse the data using your existing function
				let structuredData = parseS3Data(response.data);

				// Ensure structuredData has the 'children' array properly initialized
				structuredData = filterOutInbound(structuredData);

				console.log("Filtered Outbound Directories:", structuredData);

				// Set the outbound directories without 'Inbound' folders
				setOutboundDirectories(structuredData); // Assuming root is always there and properly formatted
			} catch (error) {
				console.error('Failed to fetch directories:', error);
				setError("Failed to load data.");
			}
		};

		fetchDirectories();
	}, [session]);

	// Function to recursively remove 'Inbound' folders
	const filterOutInbound = (nodes) => {
		if (!nodes) return nodes; // If no children, return as is

		// Remove any child nodes labeled 'Inbound'
		nodes = Object.filter(nodes, child => !child.name.includes('Inbound'));
		return nodes;
	};

	const handleUpload = () => {
		if (!selectedFile || !uploadDirectory) {
			alert("Please select a file and a directory.");
			return;
		}
		console.log("uploading file", selectedFile, " to ", uploadDirectory)

		const formData = new FormData();
		formData.append("file", selectedFile);
		formData.append("directory", uploadDirectory);
		const upload_uri = getEnvURL("REACT_APP_FTP_UPLOAD_API")
		axios.post(upload_uri, formData, {
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		})
			.then(() => {
				alert('File uploaded successfully');
				setSelectedFile(null); // Reset the selected file
			})
			.catch(error => {
				console.error('Error uploading file:', error);
				alert('Failed to upload file.');
			});
	};

	const renderFile = (file) => {
		console.log("file", file);
		console.log("file data", file.data);

		const timestamp = file.data && file.data.timestamp ? new Date(file.data.timestamp * 1000).toLocaleString() : "";
		const name = file.name || "Unnamed file";

		return (
			<div>
				<p>{name} - {timestamp}</p>
			</div>
		);
	};
	const treeEnv = useRef(null);
	const tree_data_provider = useMemo(
		() =>
			new StaticTreeDataProvider(directories, {
				getKey: (item) => { console.log("key ", item); return item.index }, // Unique key for each file
				getChildren: (item) => { console.log("child ", item); return (item.children || []) }, // Handle nested files
				getIcon: (item) => { console.log("icon ", item); return ((item.type === 'folder' || item.isFolder) ? 'folder' : 'file') }, // Optional icon based on type
				getContent: renderFile, // Use the custom render function
			}),
		[directories]
	);

	useEffect(
		() =>
			tree_data_provider.onDidChangeTreeData(changedItemIds => {
				console.log('Changed Item IDs:', changedItemIds);

				const focusedItem = treeEnv.current?.viewState['tree-1']?.focusedItem;
				if (focusedItem && !directories[focusedItem]) {
					console.log('Focused item was deleted, refocusing new item...');
					treeEnv.current.focusItem(directories.root.children[0], 'tree-1');
				}
			}).dispose,
		[tree_data_provider, directories]
	);
	//   const handleTabChange = (tabName) => {
	//     setCurrentTab(tabName);
	// };

	// const renderOutboundFolder = (item) => {

	// 		console.log("Rendering Outbound Folder:", item);


	// 	return (
	// 		<div key={item.index} className="dxf-folder-group">
	// 			<button
	// 				className={`folder-header ${expandedGroups[item.index] ? 'expanded' : ''}`}
	// 				onClick={() => {
	// 					const newExpandedGroups = { ...expandedGroups, [item.index]: !expandedGroups[item.index] };
	// 					setExpandedGroups(newExpandedGroups);
	// 				}}
	// 			>
	// 				<span className="header-arrow">{expandedGroups[item.index] ? '▼' : '▶'}</span>
	// 				{item.name}
	// 			</button>
	// 			<ul className={`folder-content ${expandedGroups[item.index] ? 'show' : ''}`}>
	// 				{item.children.map((child, index) => (
	// 					<li key={index} className="folder-name">{child.name}</li>
	// 				))}
	// 			</ul>
	// 		</div>
	// 	);
	// };

	const outboundTreeDataProvider = useMemo(() => {
		return new StaticTreeDataProvider(outboundDirectories, {
			getKey: (item) => { console.log("key ", item); return item.index }, // Unique key for each file
			getChildren: (item) => { console.log("child ", item); return (item.children || []) }, // Handle nested files
			getIcon: (item) => { console.log("icon ", item); return ((item.type === 'folder' || item.isFolder) ? 'folder' : 'file') }, // Optional icon based on type
			getContent: renderFile,
		});
	}, [outboundDirectories]);






	if (loading) return <div>Loading...</div>;
	if (error) return <div>Error: {error}</div>;

	return (
		<div className="ftp-container">
			<div className="ftp-description">
				<h4 className={`${font.h2}`} style={{ margin: '0px !important' }}>File Transfer</h4>
				<p>Use these folders to simulate inbound and outbound data exchange with other organizations who have been onboarded to the DxF Community Sandbox. Simply upload non-PHI files to your Outbound folder where other participants can pick them up. Or, transfer files from another participant's Outbound folder to your Inbound folder so you can bring it into your environment for testing.
					<br /><br />
					If you would like to use an FTP client connect to the endpoint using your sandbox username (email) and password.
					<br /><b>sftp://s-32c4e00ddf904c2b9.server.transfer.us-east-1.amazonaws.com</b>
				</p>
			</div>

			<Tabs
				style={{
					width: "90%",
					height: "100%",
					padding: "0px 5% 0px 5%"
				}}
				value={tab}
				onValueChange={setTab}
				isLazy={true}
				items={[
					{
						label: 'Upload Files',
						value: 'upload',
						content: (
							<><div className="ftp-upload" style={{ margin: '20px 0' }}>
								<h2>Upload Files:</h2>
								Select file:
								<input type="file" onChange={event => setSelectedFile(event.target.files[0])} />
								Upload to:
								<select defaultValue={uploadDirectory} onChange={(e) => {
									const fp = directories[e?.target?.value].fullpath;
									console.log("uploaddir", fp);
									setUploadDirectory(fp);
								}}>
									{upload_directories.map(dir => (
										<option key={dir.fullpath} value={dir.index}>{dir.fullpath}</option>
									))}
								</select>

							</div><div className="ftp-view">
									<UncontrolledTreeEnvironment
										// dataProvider={new StaticTreeDataProvider(directories, (item, data) => ({ ...item, data }))}
										dataProvider={tree_data_provider}
										getItemTitle={item => {
											// console.log("item: ", item)
											return item.name + (item.name == session["custom:member_of_dxf"] ? " : " + session.organization : "");
											//+ (item.data? " (updated: " + new Date(item.data.timestamp * 1000).toLocaleString() + ")": "")
										}}
										ref={treeEnv}
										viewState={{
											'tree-1': {
												expandedItems: [],
											},
										}}
									>
										<Tree treeId="tree-1" rootItem="root" treeLabel="Tree Example" />
									</UncontrolledTreeEnvironment>
								</div></>


						)
					},
					{
						label: 'Outbound Folders',
						value: 'outbound',
						content: (
							<div className="ftp-view">
								<h2 className="outbound-folders-title">Outbound Folders</h2>
								<UncontrolledTreeEnvironment
									dataProvider={outboundTreeDataProvider}
									getItemTitle={item => {
										// console.log("item: ", item)
										return item.name + (item.name == session["custom:member_of_dxf"] ? " : " + session.organization : "");
										//+ (item.data? " (updated: " + new Date(item.data.timestamp * 1000).toLocaleString() + ")": "")
									}}
									ref={treeEnv}
									viewState={{
										'tree-1': {
											expandedItems: [],

										},
									}}
								>
									<Tree treeId="tree-1" rootItem="root" treeLabel="Outbound Directory Structure" />
								</UncontrolledTreeEnvironment>
							</div>
						)
					}
				]}
			/>
		</div>
	);
};

export default FTP;



