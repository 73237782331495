const john_lee = {
	slug: "john_lee",
	table: {
		Title: "Housing and Other Referrals",
		Category: "Housing",
		Persona: "John Lee",
		Actors: ["ED", "Hospital", "CBO", "MCP", "Housing Agency"],

	},
	page: {
		Summary: `
			John needs help getting enhanced care management, along with food assistance, disability benefits, and other general assistance. 
            He also needs support transitioning from being unhoused to temporary housing, then to recuperative care, and finally permanent housing.
		`,

		Transactions: [
			"Referral for Enhanced Care Management",
			"Authorizations for ECM Services and Recuperative Care",
			"Hospital Admit and Discharge",
			"Community Supports Enrollment"
		],
		artifacts: [
			{
				title: "Use Case Overview",
				pdf: "https://drive.google.com/file/d/1SdLJSpaIEHf8nVa9J_7JXwKBYIoImrEH/preview"
			},
			{
				title: "Data Set Requirements",
				embed_link: "https://docs.google.com/spreadsheets/d/e/2PACX-1vRyI9ZJpFYl2e7142nzu5yAniNMVu-lyBlSnYo_AUmAUWP1x5-YAoC3OscgJ5hZ7YWAjJrqfVuMNum2/pubhtml"
			},
			{
				title: "Synthetic Data",
				files: [
					{ name: "H_CS_MIF_AUTH_STATUS.xlsx", path: "https://drive.google.com/file/d/1-EhAkeR-ge93Q6CvP1EVxyoGDvrAz6Aq/view?usp=drive_link" },
					{ name: "H_ECM-Referral.pdf", path: "https://drive.google.com/file/d/1-EhAkeR-ge93Q6CvP1EVxyoGDvrAz6Aq/view?usp=drive_link" },
					{ name: "H_Housing-Navigation-Tenancy-Referral.pdf", path: "https://drive.google.com/file/d/1w21sBPurTvPcK6B0lV6FRdCFQq_ZXPYN/view?usp=drive_link" },
					{ name: "H_Recuperative-Care-Referral.pdf", path: "https://drive.google.com/file/d/1SimppgCIFhC62FHS_hdikfH_qdZc8zwV/view?usp=drive_link" },
					{ name: "H_UC2A_ADT-A01.hl7", path: "https://drive.google.com/file/d/1bvv-t3mfxmeSeAd-K_CgiDMD_WgCtKDA/view?usp=drive_link" },
					{ name: "H_UC2A_ADT-A03.hl7", path: "https://drive.google.com/file/d/1ibo0Hr4g9H5GxHnLEfvcHhvm5PvOINJC/view?usp=drive_link" },
				]
			},
			{
				title: "Validation Report",
				description: "(Coming Soon)"
			}
		]
	}
}

export default john_lee;