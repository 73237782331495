import React, { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { Button, View } from '@aws-amplify/ui-react';
import Spin from '../Spinner/Spinner';

const ApiDetail = ({ api }) => {
	const [queryResponse, setQueryResponse] = useState('');
	const [error, setError] = useState(null);
	const [authToken, setAuthToken] = useState(null); // State to store the OAuth token

	// Function to fetch OAuth token
	const fetchAuthToken = async () => {
		const authDetails = api.page?.example_auth;
		console.log("authDetails: ", authDetails);
		if (!authDetails) return null; // No auth required if not present

		try {
			const { url, headers, body } = authDetails;
			const response = await axios.post(url, new URLSearchParams(body), { headers });
			console.log("auth response: ", response);
			const token = response.data.access_token; // Assuming the token is in `access_token`
			if (!token) throw new Error('No token returned from OAuth');

			setAuthToken(token); // Save the token
			return token;
		} catch (err) {
			setError(`OAuth Error: ${err.message}`);
			throw err; // Propagate the error
		}
	};

	// Function to fetch API data
	const fetchApiData = async (token = null) => {
		const exampleQuery = api.page?.example_query;
		if (!exampleQuery) return;

		const { url, method, headers, params } = exampleQuery;
		const updatedHeaders = { ...headers };


		// If a token is provided, add it to the Authorization header in Bearer format
		if (token) {
			updatedHeaders.Authorization = `Bearer ${token}`;
		}
		console.log("updatedHeaders: ", updatedHeaders);

		try {
			console.log("querying api: ", api);
			const response = await axios({
				url,
				method,
				headers: updatedHeaders,
				params
			});
			console.log("response: ", response);
			setQueryResponse(JSON.stringify(response.data, null, 2));
			return response;
		} catch (err) {
			setError(`API Error: ${err.message}`);
			throw err;
		}
	};

	// Use react-query for API data fetching
	const { isLoading, refetch } = useQuery({
		queryKey: [api.slug],
		queryFn: async () => {
			// If OAuth is required, first fetch the token
			let token = authToken;
			if (api.page?.example_auth && !authToken) {
				token = await fetchAuthToken(); // Get the token if not already obtained
			}

			// Then, query the API with the token (if available)
			return fetchApiData(token);
		},
		enabled: false, // Disable automatic fetch
		onError: (err) => {
			setError(err.message);
		}
	});

	// Handle sending the query (with optional OAuth)
	const handleSendQuery = async () => {
		setError(null); // Clear previous errors
		setQueryResponse(''); // Clear previous responses
		refetch(); // Trigger the API request
	};

	return (
		<View padding="20px" style={{ fontFamily: 'Arial, sans-serif', lineHeight: '1.6' }}>
			{/* Title Section */}
			<section style={{ backgroundColor: '#f0f4f8', padding: '20px', borderRadius: '10px', marginBottom: '20px' }}>
				<h1 style={{ fontSize: '2em', color: '#1A202C' }}>{api.table?.Title}</h1>
				<h3 style={{ fontSize: '1.2em', color: '#718096' }}>Categories: <span style={{ color: '#2B6CB0' }}>{api.table?.Categories?.join(', ')}</span></h3>
				{/* <h3 style={{ fontSize: '1.2em', color: '#718096' }}>Categories: <span style={{ color: '#2B6CB0' }}>{api.table?.Categories?.join(', ')}</span></h3> */}
				<h3 style={{ fontSize: '1.2em', color: '#718096' }}>Protocols: <span style={{ color: '#2B6CB0' }}>{api.table?.Protocols?.join(', ')}</span></h3>
			</section>

			{/* Summary Section */}
			<section style={{ backgroundColor: '#ffffff', padding: '20px', borderRadius: '10px', border: '1px solid #E2E8F0', marginBottom: '20px' }}>
				<h2 style={{ color: '#2D3748', borderBottom: '2px solid #E2E8F0', paddingBottom: '10px' }}>Summary:</h2>
				<p style={{ whiteSpace: 'pre-wrap', textIndent: '2em', marginLeft: '2em', color: '#4A5568' }}>{api.page?.Summary}</p>
			</section>

			{/* Docs, Authentication, Dataflow */}
			<section style={{ backgroundColor: '#EDF2F7', padding: '20px', borderRadius: '10px', marginBottom: '20px' }}>
				<h3 style={{ color: '#2D3748', borderBottom: '1px solid #CBD5E0', paddingBottom: '10px' }}>Documentation & Details:</h3>
				{api.page?.docs && (
					<p style={{ marginBottom: '10px' }}>
						Docs: <a href={api.page?.docs} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'underline', color: '#2B6CB0' }}>{api.page?.docs || "Pending API Documentation"}</a>
					</p>
				)}
				<p style={{ color: '#2D3748' }}>
					Authentication: <span style={{ fontWeight: 'bold', color: '#2B6CB0' }}>{api.page?.Authentication?.join(', ')}</span>
				</p>
				<p style={{ color: '#2D3748' }}>
					Dataflow: <span style={{ fontWeight: 'bold', color: '#2B6CB0' }}>{api.page?.Dataflow}</span>
				</p>
			</section>

			{/* Example Query Section */}
			{api.page?.example_query && (
				<section style={{ backgroundColor: '#E6FFFA', padding: '20px', borderRadius: '10px', marginBottom: '20px', border: '1px solid #C6F6D5' }}>
					<h2 style={{ color: '#38A169', borderBottom: '2px solid #68D391', paddingBottom: '10px' }}>Example Query: {api.page.example_query.name}</h2>

					<Button
						variation="primary"
						onClick={handleSendQuery}
						isDisabled={isLoading}
						isLoading={isLoading}
						loadingText='Loading'
						style={{ backgroundColor: '#3182CE', color: '#fff', marginBottom: '10px' }}
					>
						{isLoading ? 'Loading...' : 'Send Query'}
					</Button>

					{isLoading && <Spin size="large" />}

					{queryResponse && (
						<pre
							style={{
								background: '#F7FAFC',
								// padding: '15px',
								borderRadius: '10px',
								overflowX: 'auto',
								// marginTop: '10px',
								fontSize: '0.9em',
								color: '#2D3748',
								height: 'min-content'
							}}
						>
							{queryResponse}
						</pre>
					)}

					{error && (
						<p style={{ color: 'red', marginTop: '10px' }}>
							Error: {error}
						</p>
					)}
				</section>
			)}
		</View>
	);
};

export default ApiDetail;
