import React, { useState, useEffect, useContext } from 'react';
import { Button, Flex, Icon, View, Text } from '@aws-amplify/ui-react';
import { useNavigate, useParams } from 'react-router-dom'; // Import useNavigate hook
import { useAuthenticator } from '@aws-amplify/ui-react';
import { generateClient } from 'aws-amplify/api';
import * as queries from '../graphql/queries.js';

import Spin from '../components/Spinner/Spinner.js';
import { dynamoAPI, determineForm } from '../data/dsaFetch.js';
import font from '../css/fonts.module.css';


import { SessionContext } from '../App.js';
import Modal from '../components/Modal/Modal.js';
import MicroModal from 'micromodal';

import UserInfoC4BHCreateForm from '../ui-components/UserInfoC4BHCreateForm.jsx';
import RegistrationForm from '../components/Registration/RegistrationForm.js';
import DsaData from "../components/DsaData.js";
import { generateUUID } from '../data/util.js';

async function getDsaData(participant_id) {
	console.log("Getting DSA data for", participant_id);
	try {
		// const dsaData = [{DxFID: "DXF000000", Type: 'Acute Care Settings', Requests_for_Notifications_of_ADT_Events: "SELF", Information_Delivery: "SELF", Request_for_Information: "SELF"}] // FOR DEBUG
		const dsaData = await dynamoAPI(participant_id);
		console.log("Got DSA data response: ", dsaData);
		return dsaData || []; // Return fetched data or an empty array if null
	} catch (error) {
		console.error('Error fetching data from DynamoDB:', error);
		return [];
	}
}

function filterFormParts(dsa_data) {
	const x = determineForm(dsa_data);
	console.log("filtered form parts: ", x);
	return x;
}

MicroModal.init();

const DxfRegistration = () => {
	const navigate = useNavigate();

	const { user } = useAuthenticator((context) => [context.user]);
	// console.log(user)
	// let session = currentSession()
	const { session, isLoading, setIsLoading, isAdmin, isC4BH, checkSession } = useContext(SessionContext);

	const [submittedDxFID, setSubmittedDxFID] = useState(false);
	const [p_id, setPID] = useState("");
	const [dsa_data, setDsaData] = useState({});
	const { id } = useParams();

	const [form_parts, setFormParts] = useState([]);
	useEffect(() => {
		// console.log("got new form parts: ", form_parts)
		if (form_parts.length > 0) {
			setSubmittedDxFID(true);
		}
	}, [form_parts]);

	useEffect(() => {
		if (id) {
			console.log("got url id: ", id);
			setPID(id);
			submit_dxf_pick(null, id);
		}
	}, []);


	const [modalParams, setModalParams] = useState({
		title: '',
		message: '',
		onClose: () => { },
	});

	async function sleep(ms) {
		return new Promise((resolve) => setTimeout(resolve, ms));
	}

	const checkIsAdmin = async () => {
		setIsLoading(true);
		await checkSession();
		if (isAdmin) {
			console.log("User is an admin");
			return true;
		}
		return false;
	};

	const dxfPickChange = (fields) => {

	};

	const submit_dxf_pick = (event, id = null) => {
		try {
			const pick_element = document.getElementById("dxfPicker");
			if (id) {
				const input = pick_element.getElementsByTagName("input")[0];
				console.log("found input: ", input);
				input._valueTracker.setValue("=" + id);
				console.log("found input: ", input);
			}
			console.log("submitting url id", id, p_id);
			pick_element.requestSubmit();
		} catch (e) {
			console.error("error dom submitting dxfPicker ", e);
		}
	};

	const showDxfForm = async (fields) => {
		if (fields && fields.DxFID) {
			console.log("successfully submitted dxf participant selection, ", fields);
			//TODO CHeck if the dxf participant id is valid
			let participant_id = fields.DxFID;
			await checkIsAdmin();
			try {
				const fetchedDsaData = await getDsaData(participant_id);
				console.log("got dsa data", fetchedDsaData);
				setDsaData(fetchedDsaData[0]);
				if (fetchedDsaData[0]) {
					setFormParts(filterFormParts(fetchedDsaData).concat(["Hipaa"]));
				} else {
					console.error("dsa data isn't valid", dsa_data);
				}
			} catch (error) {
				console.error("Error fetching DSA data:", error);
				// Handle errors appropriately (e.g., display an error message)
			}
			setPID(participant_id);
			//todo call api to request to add user to dxfAdmin group, verify by c4bhAdmin
		}
	};

	const updateDxFPickSubmit = (fields) => {
		// console.log("updating dxf picker: ",  fields)
		const updatedFields = {};
		Object.assign(updatedFields, fields);
		if (id) {
			updatedFields.DxFID = id;
		} else {
			updatedFields.DxFID = fields.DxFID.split("=")[1];
		}
		updatedFields.User = user?.signInDetails.loginId;
		// console.log("idToken",session.tokens.idToken)
		updatedFields.UserIdToken = session?.tokens.idToken.toString();
		updatedFields.id = generateUUID();
		console.log("submitting form", updatedFields);
		return updatedFields;
	};

	const show_form_status_modal = (form_statuses) => {
		const errors = form_parts.filter((part) => form_statuses[part] != 1);
		console.log("checking errors for modal: ", errors);
		let close_func = () => { };
		if (errors.length > 0) {
			setModalParams({
				title: 'Error!',
				message: `Error submitting form: ${errors}`
			});

			close_func = () => {
				const dom_id = document.getElementById(`${errors[0]}`);
				const elementTop = dom_id.offsetTop;
				const headerHeight = document.getElementById("app-header").offsetHeight; // Get header height
				// console.log(`.${errors[0]} `,dom_id, " scrollto ", (elementTop - headerHeight))
				if (dom_id) {
					window.scrollTo(0, elementTop - headerHeight);
					// dom_id.scrollIntoView({ block: "start" });
				}
			};
		} else {
			setModalParams({
				title: 'Success!',
				message: 'Thank you for registering your DxF Participant with the Connecting for Better Health Sandbox!'
			});

			close_func = () => {
				navigate("/");
				window.scrollTo(0, 0);
			};
		}
		MicroModal.show('modal-1', {
			// https://micromodal.vercel.app/#configuration
			onClose: close_func,
		});
	};

	const formError = (event, x) => {
		console.error("error submitting form: ", event, x);
	};

	// useEffect(() => {
	// 	console.log("Reg page loaded session: ", session)
	// }, [session]);

	return (

		<div className="main-container" style={{ padding: "73px 60px" }}>

			<main>
				<Modal {...modalParams} />
				{isLoading ? (
					<Spin></Spin>
				) : (
					<div style={{ display: Flex, justifyContent: 'left' }}>
						{/* dxf participant selector */}
						{!submittedDxFID &&
							<div>
								<h2 className={`${font.h2}`}>Sandbox Registration</h2>
								<Text
									fontSize="16px"
									fontWeight="400"
									color="rgba(13,26,38,1)"
									lineHeight="24px"
									textAlign="left"
									display="block"
									width="100%"
									position="relative"
									whiteSpace="pre-wrap"
									fontFamily="Montserrat"
									marginBottom=".5in"
								>
									Please locate your organization by searching for all or part of the name in the box below.


								</Text>
								<UserInfoC4BHCreateForm
									id="dxfPicker"
									value={p_id}
									onChange={dxfPickChange}
									onSubmit={(fields) => updateDxFPickSubmit(fields)}
									onSuccess={(fields) => { showDxfForm(fields); }}
									onError={formError}
								/>
								<Text
									fontSize="16px"
									fontWeight="400"
									color="rgba(13,26,38,1)"
									lineHeight="24px"
									textAlign="left"
									display="block"
									width="100%"
									position="relative"
									whiteSpace="pre-wrap"
									fontFamily="Montserrat"
									marginBottom=".5rem"
								>If you don’t find your organization in the drop down list when registering, this indicates that your organization has not signed the DxF Data Sharing Agreement (DSA) or that your organization hasn't indicated exchange capabilities in the DxF Participant Directory. Sign into the <a href="https://signdxf.powerappsportals.com" style={{ color: 'blue', textDecoration: 'underline' }}>CalHHS DSA Signing Portal and Participant Directory</a> and complete the questions for your organization so they will be available in the Sandbox. If you don’t know the log in credentials used to sign the DSA, you can reach out to CDII by email at <a href="" style={{ color: 'blue', textDecoration: 'underline' }}>cdii@chhs.ca.gov</a>.</Text>
								<br />
								<Button
									variation="primary"
									loadingText=""

									onClick={submit_dxf_pick}
								>
									Register
								</Button>

							</div>
						}
						{submittedDxFID &&
							<div> {/* these forms depend on dsa data */}
								<DsaData participant_id={p_id} dsa_data={dsa_data} />
								<hr></hr>

								{form_parts.length > 0 && (
									<RegistrationForm
										participant_id={p_id}
										form_parts={form_parts}
										submit_callback={show_form_status_modal}
									/>
								)}
							</div>
						}


					</div>
				)}
			</main>
		</div>

	);
};
export default DxfRegistration;




