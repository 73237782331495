import { Flex, Text } from "@aws-amplify/ui-react";
import S3FileDeleteButton from "./S3FileDeleteButton";
import S3FileDownloadButton from "./S3FileDownloadButton";

const FileListItem = ({ file, is_deletable = false }) => {
	if (!file) return (<></>);

	return (
		<Flex
			gap="0"
			direction="row"
			width="100%"
			height="56px"
			justifyContent="space-between"
			alignItems="center"
			position="relative"
			backgroundColor="rgba(245, 245, 245, 1)" // Light gray background
			border="1px solid rgba(200, 200, 200, 1)" // Subtle border
			borderRadius="4px" // Rounded corners
			padding="10px" // Add some padding
		>
			<Text
				fontSize="16px"
				fontWeight="400"
				color="rgba(0,0,0,1)"
				lineHeight="24px"
				textAlign="left"
				display="block"
				position="relative"
				whiteSpace="pre-wrap"
				flex="1" // Make the text take the available space
			>
				{file.name}
			</Text>

			{/* Wrapping buttons in a flex container to align them to the right */}
			<Flex direction="row" gap="8px" justifyContent="flex-end" alignItems="center">
				<S3FileDownloadButton file={file} />
				{is_deletable && (<S3FileDeleteButton file={file} />)}
			</Flex>
		</Flex>
	);
};

export default FileListItem;
