
import './Spinner.css';
import nameLogo from '../../images/icons/c4bh_logo_small.ico';

const Spin = () => {


  return (
   
    <div className="spinner-container">
        <div className="spinner">

        <img src={nameLogo} style={{ maxHeight : 60 }} alt="Connecting for Better Health" />
        </div>

    </div>
  );
};

export default Spin;