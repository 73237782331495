import React, { useEffect, useState } from 'react';
import { dynamoAPI, determineForm } from '../data/dsaFetch.js';
import Spin from '../components/Spinner/Spinner.js';
import { useNavigate, useParams } from 'react-router-dom'; // Import useNavigate hook

import RegistrationForm from '../components/Registration/RegistrationForm.js'


const ViewRegistration = () => {
	let { participant_id } = useParams();
	console.log("view dxf id registration: ", participant_id)

	const [isLoading, setisLoading] = useState(true)
	const [formParts, setFormParts] = useState([])
	async function getDsaData(participant_id) {
		console.log("Getting DSA data for", participant_id);
		try {
			// const dsaData = [{DxFID: "DXF000000", Type: 'Acute Care Settings', Requests_for_Notifications_of_ADT_Events: "SELF", Information_Delivery: "SELF", Request_for_Information: "SELF"}] // FOR DEBUG
			const dsaData = await dynamoAPI(participant_id);
			console.log("Got DSA data response: ", dsaData);
			return dsaData || []; // Return fetched data or an empty array if null
		} catch (error) {
			console.error('Error fetching data from DynamoDB:', error);
			return [];
		}
	}

	function filterFormParts(dsa_data) {
		const x = determineForm(dsa_data);
		console.log("filtered form parts: ", x)
		return x;
	}

	const getFormParts = async () => {
		try {
			const fetchedDsaData = await getDsaData(participant_id);
			console.log("got dsa data", fetchedDsaData);
			if (fetchedDsaData[0]) {
				setFormParts(filterFormParts(fetchedDsaData).concat(["Hipaa"]));
			} else {
				console.error("dsa data isn't valid", fetchedDsaData)
			}
			setisLoading(false)
		} catch (error) {
			console.error("Error fetching DSA data:", error);
			// Handle errors appropriately (e.g., display an error message)
		}
	}
	useEffect(() => {
		getFormParts()
	}, [])

	return (
		<div>
			{isLoading ? (
				<Spin></Spin>
			) : (
				<RegistrationForm
					is_static={true}
					participant_id={participant_id}
					form_parts={formParts}
				/>
			)}
		</div>
	)
}

export default ViewRegistration