const cerner_va_providers = {
	slug: "cerner_va_providers",
	table: {
		Title: "VA Provider Directory",
		Categories: ["Providers"],
		Protocols: ["FHIR", "Da Vinci PDex"],
		Authentication: ["API key"],
	},
	page: {
		Summary: `
https://api.va.gov/internal/docs/provider-directory-r4/v0/openapi.json

The Provider Directory API allows you to develop applications that return lists of VA providers and information such as provider locations, specialties, and more.

Background
This API satisfies the Centers for Medicare & Medicaid Services (CMS) requirement that federal programs make provider directory information available through a public-facing API endpoint on the payer’s website.
This API uses HL7’s Fast Healthcare Interoperability Resources (FHIR) resources and follows the HL7 Da Vinci PDex Plan Net Implementation Guide. FHIR solutions are built from a standard set of modular components called resources, which can be assembled and implemented according to a variety of methods.

Technical Overview
The Provider Directory API allows queries that return data in FHIR format. Data is available 24-48 hours after entry.

Authentication and Authorization
API requests are authorized through a symmetric API token, provided in an HTTP header with name apikey.
Important: To get production access, you must either work for VA or have specific VA agreements in place. If you have questions, contact us.

Test Data
The database powering our sandbox environment is populated with test provider information that contains no PHI or PII.
		`,
		docs: "https://developer.va.gov/explore/api/provider-directory/docs?version=current",
		Endpoint: "https://sandbox-api.va.gov/services/provider-directory/v0/r4/{resource_type}/{id}",
		Dataflow: "Download",
		Authentication: ["API key"],
		example_query: {
			name: 'Query first 3 VA Healthcare Providers in California',
			method: 'GET',
			url: 'https://sandbox-api.va.gov/services/provider-directory/v0/r4/Location',
			headers: {
				'apikey': process.env.REACT_APP_VA_PROVIDERS_API_KEY,
				'accept': 'application/json'
			},
			params: {
				_id: 'I2-JI7HH7SBZOCSIS7F7DI6BLHY4VNVELJM77KAYURLD5ZZWRQ2EY7Q0000',
			}
		},
	}
}

export default cerner_va_providers;