import React, { useState, useEffect } from 'react';
import { Table, TableBody, TableCell, TableHead, TableRow, View, Autocomplete, Button } from '@aws-amplify/ui-react';
import { useNavigate } from 'react-router-dom';

Object.map = (object, mapFn) => {
	return Object.keys(object).reduce(function (result, key) {
		result[key] = mapFn(object[key], key);
		return result;
	}, {});
};

const SearchableTable = ({ datas, url_prefix }) => {
	// console.log("datas: ", datas)
	const navigate = useNavigate();
	const [filteredUsecases, setFilteredUsecases] = useState(Object.entries(datas));
	const [searchTerms, setSearchTerms] = useState({});
	const [columnOptions, setColumnOptions] = useState({});

	// Extract all column names dynamically based on the `table` field in datas
	const getColumnNames = () => {
		const allColumns = new Set();
		Object.values(datas).forEach(datum => {
			Object.keys(datum.table).forEach(key => allColumns.add(key));
		});
		return Array.from(allColumns);
	};

	const columns = getColumnNames();

	// useEffect(() => {
	// 	const unique = [...new Set(data.map(item => item.group))]; // [ 'A', 'B']
	// 	const titles = [...new Set(Object.values(usecases).map(usecase => usecase.description))];
	// 	const categories = [...new Set(Object.values(usecases).map(usecase => usecase.table.Category))];
	// 	const actors = [...new Set(Object.values(usecases).flatMap(usecase => usecase.table.Actors))];
	// 	console.log("titles, categories, actors: ", titles, categories, actors);

	// 	// Map options to the required format
	// 	setTitleOptions(titles.map(title => ({ id: title, label: title })));
	// 	setCategoryOptions(categories.map(category => ({ id: category, label: category })));
	// 	setActorsOptions(actors.map(actor => ({ id: actor, label: actor })));
	// 	setFilteredUsecases(Object.entries(usecases)); // Reset the filtered list on usecases change
	// }, [datas]);

	// Generate options for autocomplete inputs based on the values in each column
	useEffect(() => {
		const newColumnOptions = {};
		columns.forEach(column => {
			const options = [...new Set(
				Object.values(datas)
					.map(datum => datum.table[column])
					.filter(Boolean)
					.flat()
			)]
				; // Ensure we handle arrays correctly
			newColumnOptions[column] = options.map(option => ({ id: option, label: option }));
		});
		setColumnOptions(newColumnOptions);
		setFilteredUsecases(Object.entries(datas));
	}, [datas]);

	// Filter the datas based on the search terms entered for each column
	const handleFilter = () => {
		const filtered = Object.entries(datas).filter(([_slug, datum]) => {
			return columns.every(column => {
				const searchTerm = searchTerms[column]?.toLowerCase() || '';
				const value = Array.isArray(datum.table[column])
					? datum.table[column].join(', ').toLowerCase()
					: (datum.table[column] || '').toLowerCase();
				return value.includes(searchTerm);
			});
		});
		setFilteredUsecases(filtered);
	};

	// Update the search term for a given column and trigger filtering
	const handleSearchChange = (column, value) => {
		console.log("column: ", column);
		setSearchTerms(prev => ({
			...prev,
			[column]: value || ''
		}));
	};

	// Debounce the filter to avoid rapid filtering during typing
	let timeoutId;
	const debounce = (func, delay) => {
		return (...args) => {
			clearTimeout(timeoutId);
			timeoutId = setTimeout(() => func(...args), delay);
		};
	};
	const debouncedFilter = debounce(handleFilter, 250);

	useEffect(() => {
		debouncedFilter();
	}, [searchTerms]);

	return (
		<View padding="20px">
			<Table
				variation="striped"
				highlightOnHover="true"
				backgroundColor="var(--amplify-colors-background-primary)"
				boxShadow="var(--amplify-shadows-medium)"
				borderRadius="var(--amplify-radii-small)"
			>
				<TableHead>
					<TableRow>
						{columns.map(column => (
							<TableCell key={column} as="th" style={{ minWidth: '150px' }}>
								<Autocomplete
									label={`Search ${column}`}
									placeholder={column}
									options={columnOptions[column] || []}
									onSelect={({ label }) => handleSearchChange(column, label)}
									onChange={({ label }) => handleSearchChange(column, label)}
									onClear={() => handleSearchChange(column, '')}
								/>
							</TableCell>
						))}
						<TableCell as="th" style={{ fontWeight: 'bold' }}></TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{filteredUsecases.map(([slug, usecase], index) => (
						<TableRow
							key={index}
							style={{ cursor: 'pointer' }}
							onClick={() => navigate(`/${url_prefix}/${slug}`)}
							title="Open use case"
						>
							{columns.map(column => (
								<TableCell key={column}>
									{Array.isArray(usecase.table[column])
										? usecase.table[column].join(', ')
										: usecase.table[column]}
								</TableCell>
							))}
							<TableCell>
								<Button
									variation="primary"
									size="small"
									onClick={(e) => {
										e.stopPropagation(); // Prevent triggering the row click
										navigate(`/${url_prefix}/${slug}`);
									}}
								>
									Open
								</Button>
							</TableCell>
						</TableRow>
					))}
				</TableBody>
			</Table>
		</View>
	);
};

export default SearchableTable;
