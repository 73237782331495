import { View } from "@aws-amplify/ui-react"

const FileDownloadList = ({ files, renderItem, children }) => {
	console.log("files: ", files)
	return (
		<div style={{ backgroundColor: "white" }}>
			{children}
			<View padding="20px 0">
				<ul>
					{files.map((file) => renderItem(file))}
				</ul>
			</View>
		</div >
	)
}
export default FileDownloadList;