const mei_lin = {
	slug: "mei_lin",
	table: {
		Title: "Enhanced Care Management Referral and Care Coordination",
		Category: "Enhanced Care Management",
		Persona: "Mei Lin",
		Actors: ["ED", "Hospital", "CBO", "MCO", "QHIO", "Intermediary", "PCP"],

	},
	page: {
		Summary: `
			Mei experiences strong stomach pains, a complication of her
			diabetes, and is driven by a friend, who she has been couch
			surfing with, to a local hospital where she is admitted and
			receives appropriate care. When she is discharged, she is
			referred to enroll in ECM and Community Supports to prevent
			future re-admission to the hospital and to stabilize her housing
			situation.
		`,

		Transactions: [
			"ED admit and subsequent discharge w/ ECM enrollment (using QHIO)",
			"Hospital admit and subsequent discharge (using non-QHIO intermediary)",
			"Community Supports Referral",
			"MCP Review of the ECM Care Plan"
		],
		artifacts: [
			{
				title: "Use Case Overview",
				pdf: "https://drive.google.com/file/d/1UYGBAvxPmHRwyrXjrD8XW12cwykzRUdg/preview"
			},
			{
				title: "Data Set Requirements",
				embed_link: "https://docs.google.com/spreadsheets/d/1lRwQFxvnzSirkPcCH-nguxqCvOL6KsHC/pubhtml?gid=788357733&single=true"
			},
			{
				title: "Synthetic Data",
				files: [
					{ name: "ECMRCC_UC1-4_Care_Plan.pdf", path: "https://drive.google.com/file/d/1h-c8XlXO5rmI-vmNsxZymql3Nbev_0za/view?usp=sharing" },
					{ name: "ECMRCC_UC1-4_Care_Plan.xml", path: "https://drive.google.com/file/d/1qdWNoBLUrFFGh3zRy3nvkRvULhEyceLg/view?usp=sharing" },
					{ name: "ECMRCC_UC1A_ADT-A01.hl7", path: "https://drive.google.com/file/d/1mkyTVOHVyNhVZAR47d3AK3KVfG7mVyEu/view?usp=sharing" },
					{ name: "ECMRCC_UC1A_ADT-A03.hl7", path: "https://drive.google.com/file/d/1y02Rh2SC25Ipp9fZ9Jsh0ZIhHy5mwwBo/view?usp=sharing" },
					{ name: "ECMRCC_UC1A_Discharge_Summary.pdf", path: "https://drive.google.com/file/d/1lunIQVmQW308eXL9gvvRyb0OP3W8rpSd/view?usp=sharing" },
					{ name: "ECMRCC_UC1A_Discharge_Summary.xml", path: "https://drive.google.com/file/d/1OVwu-Ml3k_g0fZ7mKDAWXI1Qz0pi4eaL/view?usp=sharing" },
					{ name: "ECMRCC_UC2A_ADT-A01.hl7", path: "https://drive.google.com/file/d/1kOZS2svErnAaTumF03tnMY8CD-m670Iv/view?usp=sharing" },
					{ name: "ECMRCC_UC2A_ADT-A02.hl7", path: "https://drive.google.com/file/d/1coTtTEUXblxVIpdbt7Q9kJtCh3s7bHBF/view?usp=sharing" },
					{ name: "ECMRCC_UC2B_ADT-A03.hl7", path: "https://drive.google.com/file/d/12b7yq1c8RMdlTQ7EQN-PN-00YCZfklnC/view?usp=sharing" },
					{ name: "ECMRCC_UC2B_Discharge_Summary.pdf", path: "https://drive.google.com/file/d/1LPvF43E4e3OxMx3zPIV2lb03ynGxuU25/view?usp=sharing" },
					{ name: "ECMRCC_UC2B_Discharge_Summary.xml", path: "https://drive.google.com/file/d/1QBi19oi0dviqUxu3Ept_uN6rCiUbaOPm/view?usp=sharing" },
				]
			},
			{
				title: "Validation Report",
				pdf: "https://drive.google.com/file/d/1DrGrEz-A6qq7TD-EjM9pWplQfBj1be0C/preview"
			}
		]
	}
}

export default mei_lin;