import React, { useEffect, useState, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { SessionContext } from '../App.js';
import Spin from '../components/Spinner/Spinner.js';
import { determineForm, dynamoAPI } from '../data/dsaFetch.js';
import DsaData from "../components/DsaData.js";
import RegistrationReport from '../components/Registration/RegistrationReport.js';
import FileDownloadList from '../components/Files/FileDownloadList.js';
import FileUploader from "../components/Files/FileUploader.js";
import FileListItem from "../components/Files/FileListItem.js";
import font from '../css/fonts.module.css';
import { list } from 'aws-amplify/storage';
import { generateUUID } from '../data/util.js';
import { Flex, View, Card } from '@aws-amplify/ui-react';  // Adding Amplify Card for consistent styling

const ProfilePage = () => {
	let { participant_id } = useParams();
	console.log("view dxf id registration: ", participant_id);

	const [is_member, setIsMember] = useState(false);
	const { session, isAdmin, isC4BH, checkSession } = useContext(SessionContext);
	const [formParts, setFormParts] = useState([]);
	const [dsa_data, setDsaData] = useState();
	const [isLoading, setIsLoading] = useState(true);
	const [isFilesLoading, setFilesLoading] = useState(true);
	const [fileError, setFileError] = useState('');
	const [error, setError] = useState('');
	const [sampleFiles, setSampleFiles] = useState(null);

	async function getDsaData(participant_id) {
		try {
			const dsaData = await dynamoAPI(participant_id);
			return dsaData || [];
		} catch (error) {
			console.error('Error fetching data from DynamoDB:', error);
			return [];
		}
	}

	function filterFormParts(dsa_data) {
		const x = determineForm(dsa_data);
		return x;
	}

	const getFormParts = async () => {
		try {
			const fetchedDsaData = await getDsaData(participant_id);
			setDsaData(fetchedDsaData[0]);
			if (fetchedDsaData[0]) {
				setFormParts(filterFormParts(fetchedDsaData).concat(["Hipaa"]));
				setIsLoading(false);
			}
		} catch (error) {
			console.error("Error fetching DSA data:", error);
		}
	};

	const get_files = async () => {
		try {
			setFilesLoading(true);
			const result = await list({ path: `public/SampleFiles/${participant_id}/` });
			const files_mapped = result.items
				.filter(item => !item.path.endsWith('/'))
				.map(item => {
					const parts = item.path.split('/').filter(item => item);
					const file_name = parts.slice(-1)[0];
					const file_id = parts.slice(-1)[0] + generateUUID();
					return { id: file_id, path: item.path, name: file_name };
				});
			setSampleFiles(files_mapped);
			setFilesLoading(false);
		} catch (error) {
			console.log(error);
			setFileError(error);
		}
	};

	useEffect(() => {
		getFormParts();
		get_files();
	}, []);

	useEffect(() => {
		if (session && session['custom:member_of_dxf'])
			setIsMember(session['custom:member_of_dxf'] === participant_id);
	}, [session]);

	if (isLoading) return <Spin />;
	if (error) return <div>Error: {error}</div>;

	return (
		<Flex
			gap="0"
			direction="column"
			width="100%"
			justifyContent="flex-start"
			// alignItems="center"
			overflow="hidden"
			position="relative"
			backgroundColor="rgba(255,255,255,1)"
		>
			<Flex
				gap="15px"
				direction="column"
				justifyContent="flex-start"
				// alignItems="center"
				shrink="0"
				alignSelf="stretch"
				position="relative"
				padding="73px 60px 73px 60px"
			>
				<h2 className={font.h2}>Profile</h2>
				<DsaData dsa_data={dsa_data} participant_id={participant_id} />
				<Card variation="elevated" padding="20px" marginBottom="20px">

					<RegistrationReport participant_id={participant_id} form_parts={formParts} />
				</Card>

				{/* Sample Files Section */}
				<Card variation="elevated" padding="20px">
					<h1>Sample Files</h1>
					{isFilesLoading ? (
						<Spin />
					) : fileError ? (
						<div>
							<h3>Error: </h3>
							<p>{fileError.message}</p>
						</div>
					) : (
						<FileDownloadList
							files={sampleFiles}
							renderItem={(file) => (
								<FileListItem key={file.id} file={file} is_deletable={is_member} />
							)}
						>
							{is_member && (
								<FileUploader uploadDirectory={`public/SampleFiles/${participant_id}`} />
							)}
						</FileDownloadList>
					)}
				</Card>
			</Flex>
		</Flex>
	);
};

export default ProfilePage;
