import { Flex, Button, Text } from "@aws-amplify/ui-react";

import pdf from "../images/DxF Sandbox Project Charter 11.23.23 (1).pdf";
import dxfvisual from "../images/Screenshot 2024-04-18 at 10.58.45 AM.png";
import dxfvisualtwo from "../images/Screenshot 2024-04-18 at 10.59.11 AM.png";

import useCasePDF from "../data/UsecaseDatas/estella_ruiz/DxF Use Case_Estella Ruiz.pdf"
import validationPFDOld from "../data/UsecaseDatas/estella_ruiz/DxF Sandbox MVP Validation Report.pdf"
import syntheticTextOld from "../data/UsecaseDatas/estella_ruiz/EstellaRuiz_C-CDA Referral.txt"
import syntheticJSONOld from "../data/UsecaseDatas/estella_ruiz/EstellaRuiz_JSON.json"
import sytheticHL7Old from "../data/UsecaseDatas/estella_ruiz/Estella-Ruiz_HL7-V2_ADT-A01.hl7"

const useCaseData = [
  {
    id: "1",
    title: "DxF Use Case",
    children: [
      {
        id: "1A",
        title: <iframe
          src={useCasePDF}
          width="100%"
          height="800px"
          fontFamily="Montserrat"
          style={{ border: 'none', marginBottom: 72 }}
          title="DxF Sandbox Project Charter"
        />
      },
    ],
  },
  {
    id: "2",
    title: "Data Set Requirements",
    children: [
      {
        id: "2A",
        title: (
          <iframe
            width="100%"
            height="600px"
            style={{ border: 'none', marginBottom: 72 }}
            src="https://docs.google.com/spreadsheets/d/e/2PACX-1vSZvUtQhR3Si5eAlc9uFXV8BGgQVUQwQkyQovvyGsEBZhdzkoLzim248GXc8k0ABr_lVTohzRFsFpN_/pubhtml?gid=788357733&amp;single=true&amp;widget=true&amp;headers=false"></iframe>
        ),
      },
    ],
  },
  {
    id: "3",
    title: "Synthetic Data",
    children: [
      {
        id: "3A",
        title: (
          <Flex
            width="100%"
            direction="column"
            justifyContent="flex-start"
            alignItems="center"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="1.5rem 2rem"
          >
            <Flex
              gap="0"
              direction="row"
              width="100%"
              height="56px"
              justifyContent="space-between"
              alignItems="center"
              position="relative"
            >
              <Text
                fontSize="16px"
                fontWeight="400"
                color="rgba(0,0,0,1)"
                lineHeight="24px"
                textAlign="left"
                display="block"
                shrink="0"
                position="relative"
                whiteSpace="pre-wrap"
              >
                Estella Ruiz_C-CDA Referral (Consult Document Closing Referral).
                txt
              </Text>
              <a href={syntheticTextOld} download="Estella Ruiz_C-CDA Referral.txt" target='_blank'>
                <Button variation="primary"
                >Download</Button>
              </a>

            </Flex>
            <Flex
              gap="0"
              direction="row"
              width="100%"
              height="56px"
              justifyContent="space-between"
              alignItems="center"
              position="relative"
            >
              <Text
                fontSize="16px"
                fontWeight="400"
                color="rgba(0,0,0,1)"
                lineHeight="24px"
                textAlign="left"
                display="block"
                shrink="0"
                position="relative"
                whiteSpace="pre-wrap"
              >
                Estella Ruiz_JSON.json
              </Text>
              <a href={syntheticJSONOld} download="EstellaRuiz.json" target='_blank'>
                <Button variation="primary"
                >Download</Button>
              </a>
            </Flex>
            <Flex
              gap="0"
              direction="row"
              width="100%"
              height="56px"
              justifyContent="space-between"
              alignItems="center"
              position="relative"
            >
              <Text
                fontSize="16px"
                fontWeight="400"
                color="rgba(0,0,0,1)"
                lineHeight="24px"
                textAlign="left"
                display="block"
                shrink="0"
                position="relative"
                whiteSpace="pre-wrap"
              >
                Estella-Ruiz_HL7-V2_ADT-A01.17
              </Text>
              <a href={sytheticHL7Old} download="Estella-Ruiz_HL7-V2_ADT-A01.hl7" target='_blank'>
                <Button variation="primary"
                >Download</Button>
              </a>
            </Flex>
          </Flex>
        ),
      },
    ],
  },
  {
    id: "4",
    title: "Validation Report",
    children: [
      {
        id: "4A",
        title: <iframe
          src={validationPFDOld}
          width="100%"
          height="800px"
          style={{ border: 'none', marginBottom: 72 }}
          title="DxF Sandbox Project Charter"
        />,
      },
    ],
  },
];

export default useCaseData;
