import pdf from "../images/DxF Sandbox Project Charter 11.23.23 (1).pdf";
import dxfvisual from '../images/Screenshot 2024-04-18 at 10.58.45 AM.png';
import dxfvisualtwo from '../images/Frame 10 1.png';


const backgroundData = [
    {
        id: "1",
        title: "Project Charter",
        children: [
          {
            id: "1A",
            title: <iframe 
            src={pdf} 
            width="100%" 
            height="800px"
            style={{ border: 'none', marginBottom:72, fontFamily: "Montserrat" }}
            title="DxF Sandbox Project Charter"
          />,
          },
          
        ],
      },
      {
        id: "2",
        title: "Functional Architecture",
        children: [
          {
            id: "2A",
            title: <img style={{marginBottom:72, fontFamily: "Montserrat"}} src={dxfvisual} alt="Connecting for Better Health" />,
          },
          
        ],
      },
      {
        id: "3",
        title: "Technical Architecture",
        children: [
          {
            id: "3A",
            title: <img src={dxfvisualtwo} alt="Connecting for Better Health" />,
          },
          
        ],
      }
  ];

  export default backgroundData;