const nextgen_person_lookup = {
	slug: "nextgen_person_lookup",
	table: {
		Title: "NextGen Person Lookup",
		Categories: ["Patient"],
		Protocols: ["FHIR"],
		Authentication: ["OAuth"],
	},
	page: {
		Summary: `
Gets a list of persons/patients based on various search criteria. Since these results are returned from a /persons/ endpoint, the "id" of each result (whose value is a guid) is a personId and can be used as the value of personId in all other routes that require a personId.

Using the /persons/lookup route:

-At least one query parameter must be provided as lookup criteria.
-Use of multiple criteria is allowed, and will results will include person records matching all criteria.
-The two "quickSearch" parameters are exceptions to the above; see the quickSearchId & quickSearchInput parameter descriptions for details.

		`,
		docs: "https://www.nextgen.com/api/enterprise-uscdi-documentation#/Patient%20Demographics/%7B%7BbaseUrl%7D%7D%2Fpersons%2Flookup",
		Endpoint: "https://nativeapi.nextgen.com/nge/prod/nge-api/api/persons/lookup",
		Dataflow: "Download",
		Authentication: ["OAuth"],
		example_auth: {
			url: 'https://nativeapi.nextgen.com/nge/prod/nge-oauth/token',
			headers: {
				accept: '*/*',
				'Content-Type': "application/x-www-form-urlencoded"
			},
			body: {
				client_id: process.env.REACT_APP_NEXTGEN_CLIENT_ID,
				client_secret: process.env.REACT_APP_NEXTGEN_CLIENT_SECRET,
				site_id: process.env.REACT_APP_NEXTGEN_SITE_ID,
				grant_type: "client_credentials",
			}
		},
		example_query: {
			name: "Look up a person with the last name 'Test'",
			method: 'GET',
			url: 'https://nativeapi.nextgen.com/nge/prod/nge-api/api/persons/lookup',
			headers: {
				accept: 'application/json',
				Authorization: 'Bearer <insert>',
				'x-ng-sessionid': "MDAwMDF8MDAwMXwwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMHwwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMHx8NDU2MzQ="

			},
			params: {
				lastName: 'Test',
				excludeExpired: 'true',
				searchPatientsOnly: 'true',
			}
		},
	}
}
export default nextgen_person_lookup;