const hello = "world"
console.log("hello: ", hello)

const DevPage = () => {

	console.log(process.env)
	return (
		<div>
		  <small>You are running this application in <b>{process.env.NODE_ENV}</b> mode.</small>
		  <h>
			console.logs:
			<div>
				{Object.keys(process.env).map(function(key) {
					return (<p>{key} = {process.env[key]?process.env[key].toString():"undefined"}</p>)
				})}
			</div>
		  </h>
		  <form>
			<input type="hidden" defaultValue={process.env.REACT_APP_NOT_SECRET_CODE} />
		  </form>
		</div>
	  );
}

export default DevPage;