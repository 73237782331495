// src/data/ApiDatas/C4BH/summary.js
const c4bhSummary = {
	provider: "DxF Community Sandbox",
	logo: "https://connectingforbetterhealth.com/wp-content/uploads/2022/11/C4BH_full_logo_4x_966x109.png",
	provider_description: `
The Data Exchange Framework (DxF) Community Sandbox is a real-world testing environment for California DxF Participants to identify, test, and validate data exchange implementation strategies as they plan, design, and adjust their current workflows to meet state requirements.

The DxF Sandbox allows health providers, community-based organizations, health plans, public health agencies, and social service organizations to pursue cutting-edge data sharing approaches with meaningful cross-sector collaboration.

Thanks to the following organizations who are part of our co-design team and have given generously of their time and expertise to iteratively build this community asset.
	`,
	table: {
		Title: "DxF Community Sandbox",
		Categories: ["Patient, Encounter, Practitioner"],
		Protocols: ["MLLP", "FHIR", "sFTP"],
		Authentication: ["API key", "Username/Password", "OAuth"],
	},
};

export default c4bhSummary;
