import React, { useEffect, useState } from 'react';
import { Button } from '@aws-amplify/ui-react';
import { remove } from 'aws-amplify/storage';

const S3FileDeleteButton = ({ file }) => {
	const { path, _name } = file

	const [awaiting, setAwaiting] = useState(false);
	const [isError, setIsError] = useState(false);

	const delete_file = async () => {
		try {
			setIsError(false)
			setAwaiting(true)
			await remove({
				path: path,
				// Alternatively, path: ({identityId}) => `protected/${identityId}/album/2024/1.jpg`
			});
			setAwaiting(false)
		} catch (error) {
			setIsError(true)
			console.log('Error ', error);
		}
	}

	return (
		<Button
			// style={getButtonStyle()}
			onClick={delete_file}
			isLoading={awaiting}
			colorTheme={isError ? "error" : null}
			variation="primary"
		>
			{isError ? 'Error! Retry?' : `Delete`}
		</Button>
	);
};

export default S3FileDeleteButton;
