import { useParams } from 'react-router-dom';
import { Flex, Text, Accordion } from '@aws-amplify/ui-react';
import font from '../css/fonts.module.css'
import ApiDetail from '../components/APIs/ApiDetail';
import SearchableTable from '../components/SearchableTable';
import ProviderPage from '../components/APIs/ProviderPage';

const providersContext = require.context('../data/ApiDatas', true, /\/.+\/.+\.js$/);

Object.map = (object, mapFn) => {
	return Object.keys(object).reduce(function (result, key) {
		result[key] = mapFn(object[key], key)
		return result
	}, {})
}

const apiData = providersContext.keys().reduce((acc, key) => {
	const pathParts = key.split('/');
	// console.log("pathParts: ", pathParts)
	const providerName = pathParts.slice(-2)[0]; // Extract provider name from path
	const fileName = pathParts.slice(-1)[0];

	if (!acc[providerName]) {
		acc[providerName] = { apis: {} };
	}

	const json = providersContext(key).default;

	if (fileName == 'summary.js' || json.provider) {
		acc[providerName].summary = json;
	} else {
		acc[providerName].apis[json.slug] = json;
	}
	console.log("json: ", json)

	return acc;
}, {});



console.log("apiData: ", apiData)

const ApiPage = () => {
	const { provider } = useParams();
	console.log("provider: ", provider)

	if (provider) {
		const provider_data = apiData[provider];
		return (
			<div>
				<ProviderPage provider_data={provider_data}></ProviderPage>
			</div>
		)
	} else {
		return (
			<Flex
				gap="0"
				direction="column"
				width="100%"
				height="100%"
				justifyContent="flex-start"
				// alignItems="center"
				overflow="hidden"
				position="relative"
				backgroundColor="rgba(255,255,255,1)"
			>
				<Flex
					gap="15px"
					direction="column"
					justifyContent="flex-start"
					// alignItems="center"
					shrink="0"
					alignSelf="stretch"
					position="relative"
					padding="73px 60px 73px 60px"
				>
					<h2 className={`${font.h2}`}>APIs</h2>

					<SearchableTable datas={Object.map(apiData, (provider_data) => { return provider_data.summary })} url_prefix={`apis`} />
				</Flex >
			</Flex >
		);
	}
};

export default ApiPage;
