import React, { useEffect, useState } from 'react';
import { Button } from '@aws-amplify/ui-react';
import { getUrl } from 'aws-amplify/storage';
import { downloadData } from 'aws-amplify/storage';

const S3FileDownloadButton = ({ file }) => {
	const { path, name, is_path_direct_url } = file;

	const [awaiting, setAwaiting] = useState(false);
	const [downloadUrl, setDownloadUrl] = useState("");
	const [progress, setProgress] = useState(0);
	const [isError, setIsError] = useState(false);

	const downloadFile = async () => {
		setIsError(false); // Reset error state
		setProgress(0); // Reset progress at the start

		const progressCallback = (progressEvent) => {
			const progressInPercentage = Math.round((progressEvent.loaded / progressEvent.total) * 100);
			setProgress(progressInPercentage);
		};

		try {
			await Storage.get(path, {
				download: true,
				progressCallback,
			});
			setProgress(100); // Ensure it's fully green when download completes
		} catch (error) {
			setIsError(true); // Set error state if there's an issue
			console.error('Download failed:', error);
		}
	};

	const getButtonStyle = () => {
		if (isError) {
			// Set red color for error
			return {
				background: `linear-gradient(to right, red 100%, #ffefef 0%)`,
			};
		}

		// Set gradient from green (based on progress)
		return {
			background: `linear-gradient(to right, green ${progress}%, #ddd ${progress}%)`,
		};
	};

	const get_signed_url = async (filePath) => {
		setAwaiting(true);
		try {
			const getUrlResult = await getUrl({
				path: filePath,
				// Alternatively, path: ({identityId}) => `protected/${identityId}/album/2024/1.jpg`
				options: {
					validateObjectExistence: true,  // Check if object exists before creating a URL
					expiresIn: 1800, // validity of the URL, in seconds. defaults to 900 (15 minutes) and maxes at 3600 (1 hour)
					// useAccelerateEndpoint: true // Whether to use accelerate endpoint
				},
			});
			setDownloadUrl(getUrlResult.url.href);
			console.log('signed URL: ', getUrlResult.url);
			console.log('URL expires at: ', getUrlResult.expiresAt);
		} catch (e) {
			console.error(e);
		} finally {
			setAwaiting(false);
		}
	};

	useEffect(() => {
		if (is_path_direct_url)
			setDownloadUrl(path);
		else
			get_signed_url(path);
	}, []);

	return (
		<a href={downloadUrl} download={name} target='_blank'>
			<Button
				// style={getButtonStyle()}
				// onClick={downloadFile}
				isLoading={awaiting}
				colorTheme={isError ? "error" : null}
				variation="primary"
			>
				{isError ? 'Error! Retry?' : `Download`}
			</Button>
		</a >
	);
};

export default S3FileDownloadButton;
