import { useContext, useEffect, useState } from 'react'
import { SessionContext } from '../../App.js'
import flex from '../../css/flex.module.css'
import { Button } from '@aws-amplify/ui-react';
import { useNavigate } from 'react-router-dom';

import { generateClient } from 'aws-amplify/api';
import * as queries from '../../graphql/queries.js';

import Spin from '../Spinner/Spinner.js';


const RegistrationReport = ({ participant_id, form_parts }) => {
	console.log("reg form with parts: ", form_parts)
	const [isLoading, setIsLoading] = useState(true);
	const client = generateClient();


	const [form_datas, setFormDatas] = useState({})//'TechCBO', 'TechAcute', 'TechInt', 'NotifADTUno', 'NotifADTDos', 'Info', 'RequestUno', 'RequestDos'

	function isEmpty(obj) {
		// Check if the object itself is empty
		if (Object.keys(obj).length === 0) {
			return true;
		}

		// Check if all the properties are empty objects
		for (const key in obj) {
			if (Object.keys(obj[key]).length !== 0) {
				return false;
			}
		}

		return true;
	}

	useEffect(() => {
		console.log("got form datas: ", form_datas)
		setIsLoading(false)
	}, [form_datas])

	useEffect(() => {
		console.log("got new loading state ", isLoading)
	}, [isLoading])

	const getFormData = async (form_part) => {
		let query;
		switch (form_part) {
			case 'TechCBO':
				query = queries.listTechCBOS
				break;
			case 'TechAcute':
				query = queries.listTechAcutes
				break;
			case 'TechInt':
				query = queries.listTechIntermediaries
				break;
			case 'NotifADTUno':
				query = queries.listNotificationsADTUnos
				break;
			case 'NotifADTDos':
				query = queries.listNotificationsADTDos
				break;
			case 'Info':
				query = queries.listInformationDeliveries
				break;
			case 'RequestUno':
				query = queries.listRequestInfoUnos
				break;
			case 'RequestDos':
				query = queries.listRequestInfoDos
				break;
			case 'Hipaa':
				query = queries.listHIPAAS
				break;
		}
		console.log("querying amplify for ", form_part, participant_id)
		const response = await client.graphql({
			query: query,
			variables: {
				filter: {
					DxFID: {
						eq: participant_id
					}
				}
			},
		});
		// Check for errors in the response
		if (!response.data) {
			throw new Error(`Failed to fetch data for ${form_part}`);
		}
		// console.log("got response: ", response)

		// Handle null values within the response data
		const data = response.data[Object.keys(response.data)[0]]
		const formattedData = {
			...response.data[form_part], // Destructure and rename "listTechCBOS" to "TechCBO" (optional)
			items: data.items?.map(item => ({
				...item,
				UserIdToken: item.UserIdToken || "" // Set a default value for missing UserIdToken
			}))
		};
		// console.log("formattedData: ", formattedData)
		const sortedItems = formattedData.items.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));
		// console.log("sortedItems: ", sortedItems)

		return sortedItems[0] ?? {};
	}

	function hasUnauthorizedError(error) {
		// Check if the errors array exists and has at least one element
		if (!error || !error.errors || error.errors.length === 0) {
			return false; // No errors or empty errors array
		}

		// Use some/any method to check if any error has errorType "Unauthorized"
		return error.errors.some(errorObject => errorObject.errorType === "Unauthorized");
	}

	useEffect(() => {
		// setIsLoading(true)
		console.log("checking for previous submits ", form_parts)
		const promises = form_parts.map(getFormData);
		Promise.all(promises)
			.then(allFormData => {
				const new_data = form_parts.reduce((acc, form_part, index) => {
					return { ...acc, [form_part]: allFormData[index] };
				}, {});
				console.log("setting new: ", new_data)
				setFormDatas(new_data);
			})
			.catch(error => {
				console.error("Error fetching form data:", error);
				setIsLoading(false)
				// Handle errors appropriately, maybe set a loading state
			});
	}, [])


	return (
		<div>
			<div id="UpdateForm">
				<h1>DxF Community Sandbox Registration:</h1>
				{/* Technology Section */}
				{(form_parts.includes("TechAcute") || form_parts.includes("TechCBO") || form_parts.includes("TechInt")) && (
					<>
						<h2><i>Technology:</i></h2>
						{form_parts.includes("TechAcute") && (
							<div>
								<p><b>What Electronic Health Record platform do you use? </b>{form_datas["TechAcute"]?.EHR_Vendors || "Not provided"}</p>
								<p><b>Is this EHR platform certified?</b> {form_datas["TechAcute"] ? (form_datas["TechAcute"].EHR_Vendors ? 'Yes' : 'No') : 'No'}</p>
							</div>
						)}
						{form_parts.includes("TechCBO") && (
							<div>
								<p><b>Customer Management System: </b>{form_datas["TechCBO"]?.Customer_Management_System || "Not provided"}</p>
							</div>
						)}
						{form_parts.includes("TechInt") && (
							<div>
								<p><b>Integration Engine Used: </b>{form_datas["TechInt"]?.IntIntegrationEngineUsed || "Not provided"}</p>
								<p><b>Clinical Data Repository Used: </b>{form_datas["TechInt"]?.IntClinicalDataRepoUsed || "Not provided"}</p>
							</div>
						)}
					</>
				)}

				{/* ADT Event Notification Section */}
				{(form_parts.includes("NotifADTUno") || form_parts.includes("NotifADTDos")) && (
					<>
						<h2><i>ADT Event Notification:</i></h2>
						{form_parts.includes("NotifADTUno") && (
							<div>
								<p><b>By which methods are you able to receive ADT Event Notifications?</b></p>
								<ul>
									<li>Direct Secure Email: {form_datas["NotifADTUno"]?.ReceiveADTDirectSecureEmail ? 'Yes' : 'No'}</li>
									<li>sFTP: {form_datas["NotifADTUno"]?.ReceiveADTsFTP ? 'Yes' : 'No'}</li>
									<li>Point to Point (i.e., MLLP/VPN): {form_datas["NotifADTUno"]?.ReceiveADTPointToPoint ? 'Yes' : 'No'}</li>
									<li>HTTPS - non-FHIR (web services, API, etc.): {form_datas["NotifADTUno"]?.ReceiveADTHTTPSnonFHIR ? 'Yes' : 'No'}</li>
								</ul>
								<p><b>By which methods are you able to send ADT Event Notifications?</b></p>
								<ul>
									<li>Direct Secure Email: {form_datas["NotifADTDos"]?.SendInfoDirectSecureEmail ? 'Yes' : 'No'}</li>
									<li>sFTP: {form_datas["NotifADTDos"]?.SendInfosFTP ? 'Yes' : 'No'}</li>
									<li>Point to Point (i.e., MLLP/VPN): {form_datas["NotifADTDos"]?.SendInfoMLLP_HTTPS ? 'Yes' : 'No'}</li>
									<li>HTTPS - FHIR: {form_datas["NotifADTDos"]?.SendADTHTTPS_FHIR ? 'Yes' : 'No'}</li>
								</ul>
							</div>
						)}
					</>
				)}

				{/* Information Delivery Section */}
				{form_parts.includes("Info") && (
					<div>
						<h2><i>Information Delivery:</i></h2>
						<ul>
							<li>Direct Secure Email: {form_datas["Info"]?.RecieveInfoDirectSecureEmail ? 'Yes' : 'No'}</li>
							<li>sFTP: {form_datas["Info"]?.RecieveInfosFTP ? 'Yes' : 'No'}</li>
							<li>HL7 V2: {form_datas["Info"]?.RecieveInfoHL7V2 ? 'Yes' : 'No'}</li>
							<li>Other: {form_datas["Info"]?.RecieveInfoOther ? 'Yes' : 'No'} (Describe: {form_datas["Info"]?.RecieveInfoOther_Describe || "Not provided"})</li>
						</ul>
						<p><b>Third Party Referral Platform: </b>{form_datas["Info"]?.RecieveInfoThirdPartyPlatform || "Not provided"}</p>
					</div>
				)}

				{/* HIPAA Section */}
				<div id="HIPAA">
					<h2><i>Other Information:</i></h2>
					<p><b>What is your HIPAA coverage?</b></p>
					<ul>
						<li>Covered Entity: {form_datas["Hipaa"]?.CoveredHIPAA ? 'Yes' : 'No'}</li>
						<li>Hybrid Entity: {form_datas["Hipaa"]?.HybridHIPAA ? 'Yes' : 'No'}</li>
						<li>Business Associate of one or more Covered Entities: {form_datas["Hipaa"]?.AssociateHIPAA ? 'Yes' : 'No'}</li>
						<li>Not a Covered Entity, Hybrid Entity, or Business Associate: {form_datas["Hipaa"]?.NotCoveredHIPAA ? 'Yes' : 'No'}</li>
					</ul>
				</div>

			</div>
		</div >
	);

}

export default RegistrationReport;