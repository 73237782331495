const nextgen_patient = {
	slug: "nextgen_patient",
	table: {
		Title: "NextGen Patient Data",
		Categories: ["Patient"],
		Protocols: ["FHIR"],
		Authentication: ["OAuth"],
	},
	page: {
		Summary: `
Fetches a bundled resource containing the valid patients mentioned in the request parameters.
FHIR Resource: Patient.name.given
    First Name
    Middle Name
FHIR Resource: Patient.name.family
    Last Name
FHIR Resource: Patient.name
    Previous Name
FHIR Resource: Patient.name.suffix
    Suffix
FHIR Resource: US Core Birth Sex Extension
    Birth Sex
FHIR Resource: Patient.birthDate
    Date of Birth
FHIR Resource: US Core Race Extension
    Race
FHIR Resource: US Core Ethnicity Extension
    Ethnicity
FHIR Resource: Patient.communication
    Preferred Language
FHIR Resource: Patient.address
    Address
FHIR Resource: Patient.telecom
    Phone Number
		`,
		docs: "https://www.nextgen.com/api/nge-fhir-r4#/Patient/get_Patient",
		Endpoint: "https://fhir.nextgen.com/nge/prod/fhir-api-r4/fhir/r4/",
		Dataflow: "Download",
		Authentication: ["OAuth"],
		example_auth: {
			url: 'https://nativeapi.nextgen.com/nge/prod/nge-oauth/token',
			headers: {
				accept: '*/*',
				'Content-Type': "application/x-www-form-urlencoded"
			},
			body: {
				client_id: process.env.REACT_APP_NEXTGEN_CLIENT_ID,
				client_secret: process.env.REACT_APP_NEXTGEN_CLIENT_SECRET,
				site_id: process.env.REACT_APP_NEXTGEN_SITE_ID,
				grant_type: "client_credentials",
			}
		},
		example_query: {
			name: "Query for the Adult Female Test patient",
			method: 'GET',
			url: 'https://fhir.nextgen.com/nge/prod/fhir-api-r4/fhir/R4/Patient/0bf1cdec-9c44-4bec-b07f-fe91ca1cee94',
			headers: {
				accept: 'application/json',
				Authorization: 'Bearer <insert>',
				'x-ng-sessionid': "MDAwMDF8MDAwMXwwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMHwwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMHx8NDU2MzQ=",
				'x-enterprise-id': "00001",
				'x-practice-id': "0001"
			},
			params: {

			}
		},
	}
}
export default nextgen_patient;