import { getEnvURL } from '../../../envUtils';
const practitioner_api = getEnvURL('REACT_APP_FETCH_CDR_PRACTITIONER_DATA');

const smile_api_practitioner = {
    slug: "Practitioner",
    table: {
        Title: "Practitioner",
        Categories: ["Healthcare"],
        Protocols: ["http"],
        Authentication: ["Basic Auth"], 
    },
    page: {
        Summary: `
        http://ec2-100-25-223-189.compute-1.amazonaws.com:8000/Practitioner

Background

The SMILE Practitioner API offers comprehensive access to healthcare practitioner profiles across various locations. This API is crucial for retrieving detailed information about practitioners, such as their qualifications, contact details, and the services they provide.

Authentication and Authorization

Access to the API is secured through Basic Authentication, requiring credentials to be included in the HTTP header of each request. This ensures that only authorized users can retrieve sensitive practitioner information.

Technical Overview

This API is designed to serve data relevant to healthcare service providers, including:

- **Identifiers**: Unique provider numbers that distinguish each practitioner within the system.

- **Names**: Full names of practitioners, including given names and family names.

- **Contact Details**: Work-related contact information, enabling communication or further inquiry.

- **Location Information**: Address details which include line, city, state, postal code, and country, crucial for understanding the geographical distribution and accessibility of healthcare services.

- **Real-Time Data**: The API provides information that is consistently updated, reflecting the most current details available about each practitioner.

Data is fetched in real-time, ensuring that the responses contain the most up-to-date information available, with historical data provided for context and completeness.

Summary of API Usage

This API is particularly useful for healthcare administrators, policy makers, and health IT systems that need to integrate detailed practitioner information. It supports various applications, from internal hospital management systems to public-facing healthcare provider directories.

        `, 
        Endpoint: practitioner_api,
        Authentication: ["Basic Auth"], 
        Dataflow: ["JSON"],
        example_query: {
            name: 'Query Practitioners',
            method: 'GET',
            url: practitioner_api,
            headers: {
                'Authorization': `Basic ${btoa('admin:password')}`, 
                'accept': 'application/json'
            },
        },
    }
}

export default smile_api_practitioner;
