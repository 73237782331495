import { useContext, useEffect, useState } from 'react';
import { SessionContext } from '../../App.js';
import flex from '../../css/flex.module.css';
import { Button } from '@aws-amplify/ui-react';
import { useNavigate } from 'react-router-dom';


import TechAcuteCreateForm from '../../ui-components/TechAcuteCreateForm.jsx';
import TechCBOCreateForm from '../../ui-components/TechCBOCreateForm.jsx';
import TechIntermediariesCreateForm from '../../ui-components/TechIntermediariesCreateForm.jsx';
import NotificationsADTUnoCreateForm from '../../ui-components/NotificationsADTUnoCreateForm.jsx';
import NotificationsADTDosCreateForm from '../../ui-components/NotificationsADTDosCreateForm.jsx';
import RequestInfoUnoCreateForm from '../../ui-components/RequestInfoUnoCreateForm.jsx';
import RequestInfoDosCreateForm from '../../ui-components/RequestInfoDosCreateForm.jsx';
import InformationDeliveryCreateForm from '../../ui-components/InformationDeliveryCreateForm.jsx';
import HIPAACreateForm from '../../ui-components/HIPAACreateForm.jsx';

import { generateClient } from 'aws-amplify/api';
import * as queries from '../../graphql/queries.js';

import TechAcuteUpdateForm from '../../ui-components/TechAcuteUpdateForm.jsx';
import TechCBOUpdateForm from '../../ui-components/TechCBOUpdateForm.jsx';
import TechIntermediariesUpdateForm from '../../ui-components/TechIntermediariesUpdateForm.jsx';
import NotificationsADTUnoUpdateForm from '../../ui-components/NotificationsADTUnoUpdateForm.jsx';
import NotificationsADTDosUpdateForm from '../../ui-components/NotificationsADTDosUpdateForm.jsx';
import RequestInfoUnoUpdateForm from '../../ui-components/RequestInfoUnoUpdateForm.jsx';
import RequestInfoDosUpdateForm from '../../ui-components/RequestInfoDosUpdateForm.jsx';
import InformationDeliveryUpdateForm from '../../ui-components/InformationDeliveryUpdateForm.jsx';
import HIPAAUpdateForm from '../../ui-components/HIPAAUpdateForm.jsx';
import Spin from '../Spinner/Spinner.js';


const RegistrationForm = ({ participant_id, form_parts, submit_callback, is_static = false }) => {
	console.log("reg form with parts: ", form_parts);
	const { session } = useContext(SessionContext);
	const [isLoading, setIsLoading] = useState(true);
	const [isUpdate, setIsUpdate] = useState(false);
	const { isAdmin, isC4BH } = useContext(SessionContext);
	const navigate = useNavigate();
	const client = generateClient();


	const [form_datas, setFormDatas] = useState({});//'TechCBO', 'TechAcute', 'TechInt', 'NotifADTUno', 'NotifADTDos', 'Info', 'RequestUno', 'RequestDos'

	function isEmpty(obj) {
		// Check if the object itself is empty
		if (Object.keys(obj).length === 0) {
			return true;
		}

		// Check if all the properties are empty objects
		for (const key in obj) {
			if (Object.keys(obj[key]).length !== 0) {
				return false;
			}
		}

		return true;
	}

	useEffect(() => {
		console.log("got form datas: ", form_datas);
		setIsLoading(false);
		if (!isEmpty(form_datas)) {
			setIsUpdate(true);
		}
	}, [form_datas]);

	useEffect(() => {
		console.log("got new loading state ", isLoading);
	}, [isLoading]);

	const getFormData = async (form_part) => {
		let query;
		switch (form_part) {
			case 'TechCBO':
				query = queries.listTechCBOS;
				break;
			case 'TechAcute':
				query = queries.listTechAcutes;
				break;
			case 'TechInt':
				query = queries.listTechIntermediaries;
				break;
			case 'NotifADTUno':
				query = queries.listNotificationsADTUnos;
				break;
			case 'NotifADTDos':
				query = queries.listNotificationsADTDos;
				break;
			case 'Info':
				query = queries.listInformationDeliveries;
				break;
			case 'RequestUno':
				query = queries.listRequestInfoUnos;
				break;
			case 'RequestDos':
				query = queries.listRequestInfoDos;
				break;
			case 'Hipaa':
				query = queries.listHIPAAS;
				break;
		}
		console.log("querying amplify for ", form_part, participant_id);
		const response = await client.graphql({
			query: query,
			variables: {
				filter: {
					DxFID: {
						eq: participant_id
					}
				}
			},
		});
		// Check for errors in the response
		if (!response.data) {
			throw new Error(`Failed to fetch data for ${form_part}`);
		}
		// console.log("got response: ", response)

		// Handle null values within the response data
		const data = response.data[Object.keys(response.data)[0]];
		const formattedData = {
			...response.data[form_part], // Destructure and rename "listTechCBOS" to "TechCBO" (optional)
			items: data.items?.map(item => ({
				...item,
				UserIdToken: item.UserIdToken || "" // Set a default value for missing UserIdToken
			}))
		};
		// console.log("formattedData: ", formattedData)
		const sortedItems = formattedData.items.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));
		// console.log("sortedItems: ", sortedItems)

		return sortedItems[0] ?? {};
	};

	function hasUnauthorizedError(error) {
		// Check if the errors array exists and has at least one element
		if (!error || !error.errors || error.errors.length === 0) {
			return false; // No errors or empty errors array
		}

		// Use some/any method to check if any error has errorType "Unauthorized"
		return error.errors.some(errorObject => errorObject.errorType === "Unauthorized");
	}

	useEffect(() => {
		// setIsLoading(true)
		console.log("checking for previous submits ", form_parts);
		const promises = form_parts.map(getFormData);
		Promise.all(promises)
			.then(allFormData => {
				const new_data = form_parts.reduce((acc, form_part, index) => {
					return { ...acc, [form_part]: allFormData[index] };
				}, {});
				console.log("setting new: ", new_data);
				setFormDatas(new_data);
			})
			.catch(error => {
				console.error("Error fetching form data:", error);
				setIsLoading(false);
				if (hasUnauthorizedError(error))
					setIsUpdate(true);
				// Handle errors appropriately, maybe set a loading state
			});
	}, []);

	const request_admin = async () => {
		console.log("requesting admin access");
	};

	const updateRegistrationSubmitFields = (fields) => {
		const updatedFields = {};
		Object.assign(updatedFields, fields);
		// updatedFields.DxFID = participant_id
		// // console.log("idToken",session.tokens.idToken)
		// updatedFields.UserIdToken = session?.tokens.idToken.toString();
		// console.log("submitting form with dxf id", updatedFields.DxFID)
		return updatedFields;
	};

	const submit_DxF_form = () => {
		console.log("submitting form parts ", form_parts);
		for (const form_part of form_parts) {
			try {
				console.log("submitting form ", form_part);
				const elements = document.getElementsByClassName(form_part);
				const forms = Array.from(elements).filter(element => element.tagName === "FORM");
				console.log("form", forms);
				if (forms[0]) {
					forms[0].requestSubmit();
				}
				else {
					console.error("No form found for: ", form_part);
				}
			} catch (e) {
				console.error("error dom submitting form ", e);
			}
		}
		// const w = document.getElementsByClassName("Hipaa")[0]
		// w.requestSubmit();
		// debugger;
	};

	const [form_statuses, setFormStatuses] = useState({
		TechCBO: 0,
		TechAcute: 0,
		TechInt: 0,
		NotifADTUno: 0,
		NotifADTDos: 0,
		Info: 0,
		RequestUno: 0,
		RequestDos: 0,
		Hipaa: 0
	});

	//todo refactor to DRY with a macro or something https://parenscript.common-lisp.dev/reference.html
	const formSuccessTechCBO = (event) => {
		setFormStatuses((prevStatuses) => ({
			...prevStatuses,
			TechCBO: 1
		}));
	};

	const formErrorTechCBO = (event, err) => {
		setFormStatuses((prevStatuses) => ({
			...prevStatuses,
			TechCBO: event + ", " + err
		}));
	};

	const formSuccessTechAcute = (event) => {
		setFormStatuses((prevStatuses) => ({
			...prevStatuses,
			TechAcute: 1
		}));
	};

	const formErrorTechAcute = (event, err) => {
		setFormStatuses((prevStatuses) => ({
			...prevStatuses,
			TechAcute: event + ", " + err
		}));
	};

	const formSuccessTechInt = (event) => {
		setFormStatuses((prevStatuses) => ({
			...prevStatuses,
			TechInt: 1
		}));
	};

	const formErrorTechInt = (event, err) => {
		setFormStatuses((prevStatuses) => ({
			...prevStatuses,
			TechInt: event + ", " + err
		}));
	};

	const formSuccessADTUno = (event) => {
		setFormStatuses((prevStatuses) => ({
			...prevStatuses,
			NotifADTUno: 1
		}));
	};

	const formErrorADTUno = (event, err) => {
		setFormStatuses((prevStatuses) => ({
			...prevStatuses,
			NotifADTUno: event + ", " + err
		}));
	};

	const formSuccessADTDos = (event) => {
		setFormStatuses((prevStatuses) => ({
			...prevStatuses,
			NotifADTDos: 1
		}));
	};

	const formErrorADTDos = (event, err) => {
		setFormStatuses((prevStatuses) => ({
			...prevStatuses,
			NotifADTDos: event + ", " + err
		}));
	};

	const formSuccessInfo = (event) => {
		setFormStatuses((prevStatuses) => ({
			...prevStatuses,
			Info: 1
		}));
	};

	const formErrorInfo = (event, err) => {
		setFormStatuses((prevStatuses) => ({
			...prevStatuses,
			Info: event + ", " + err
		}));
	};

	const formSuccessReqUno = (event) => {
		setFormStatuses((prevStatuses) => ({
			...prevStatuses,
			RequestUno: 1
		}));
	};

	const formErrorReqUno = (event, err) => {
		setFormStatuses((prevStatuses) => ({
			...prevStatuses,
			RequestUno: event + ", " + err
		}));
	};

	const formSuccessReqDos = (event) => {
		setFormStatuses((prevStatuses) => ({
			...prevStatuses,
			RequestDos: 1
		}));
	};

	const formErrorReqDos = (event, err) => {
		setFormStatuses((prevStatuses) => ({
			...prevStatuses,
			RequestDos: event + ", " + err
		}));
	};

	const formSuccessHipaa = (event) => {
		console.log("success hipaa");
		setFormStatuses((prevStatuses) => ({
			...prevStatuses,
			Hipaa: 1
		}));
	};

	const formErrorHipaa = (event, err) => {
		console.error("error hipaa: ", err, err.stack);
		// debugger;
		setFormStatuses((prevStatuses) => ({
			...prevStatuses,
			Hipaa: event + ", " + err
		}));
	};

	useEffect(() => {
		const allChecked = form_parts.every((part) => form_statuses[part] !== 0);
		console.log("checking parts: ", form_parts, " for errors i n statuses: ", form_statuses, "checked? ", allChecked);
		if (form_parts.length > 0 && Object.keys(form_statuses).length > 0 && allChecked) {
			console.log("all checked, running callback");
			submit_callback(form_statuses);
		}
	}, [form_parts, form_statuses]); // Dependency on form_statuses


	return (
		<div className={is_static ? 'no-click' : ''}>
			{isLoading ? (
				<Spin></Spin>
			) : !isUpdate ? (
				<div id="CreateForm">
					<div id="TECH">
						{(form_parts.includes("TechAcute") || form_parts.includes("TechCBO") || form_parts.includes("TechInt")) && (
							<h2><i>Technology:</i></h2>
						)
						}
						{form_parts.includes("TechAcute") && (
							<TechAcuteCreateForm
								className={"TechAcute " + (form_statuses["TechAcute"] == 1 || form_statuses["TechAcute"] == 0 ? '' : 'highlighted')}
								onSubmit={updateRegistrationSubmitFields}
								onSuccess={formSuccessTechAcute}
								onError={formErrorTechAcute}
							/>
						)}

						{form_parts.includes("TechCBO") && (
							<TechCBOCreateForm
								className={"TechCBO " + (form_statuses["TechCBO"] == 1 || form_statuses["TechCBO"] == 0 ? '' : 'highlighted')}
								onSubmit={updateRegistrationSubmitFields}
								onSuccess={formSuccessTechCBO}
								onError={formErrorTechCBO}
							/>
						)}

						{form_parts.includes("TechInt") && (
							<TechIntermediariesCreateForm
								className={"TechInt " + (form_statuses["TechInt"] == 1 || form_statuses["TechInt"] == 0 ? '' : 'highlighted')}
								onSubmit={updateRegistrationSubmitFields}
								onSuccess={formSuccessTechInt}
								onError={formErrorTechInt} />
						)}
					</div>

					<div id="REQ">
						{(form_parts.includes("RequestUno") || form_parts.includes("RequestDos")) && (
							<div>
								<h2><i>Request for Information:</i></h2>
							</div>)}

						{form_parts.includes("RequestUno") && (<div>
							<RequestInfoUnoCreateForm
								className={"RequestUno " + (form_statuses["RequestUno"] == 1 || form_statuses["RequestUno"] == 0 ? '' : 'highlighted')}
								onSubmit={updateRegistrationSubmitFields}
								onSuccess={formSuccessReqUno}
								onError={formErrorReqUno}
							/>
						</div>)}

						{form_parts.includes("RequestDos") && (
							<RequestInfoDosCreateForm
								className={"RequestDos " + (form_statuses["RequestDos"] == 1 || form_statuses["RequestDos"] == 0 ? '' : 'highlighted')}
								onSubmit={updateRegistrationSubmitFields}
								onSuccess={formSuccessReqDos}
								onError={formErrorReqDos}
							/>
						)}

						<div id="DLV">
							{form_parts.includes("Info") && (
								<div>
									<div style={{ display: 'inline-flex', flexDirection: 'row' }}>
										<h2><i>Information Delivery:</i></h2>
									</div>

									<InformationDeliveryCreateForm
										className={"Info" + (form_statuses["Info"] == 1 || form_statuses["Info"] == 0 ? '' : 'highlighted')}
										onSubmit={updateRegistrationSubmitFields}
										onSuccess={formSuccessInfo}
										onError={formErrorInfo} />
								</div>)}
						</div>

						<div id="ADT">
							{(form_parts.includes("NotifADTUno") || form_parts.includes("NotifADTDos")) && (
								<div>
									<div className={`${flex.row}`}>
										<h2><i>ADT Event Notification:</i></h2>
									</div></div>
							)}

							{form_parts.includes("NotifADTUno") && (
								<NotificationsADTUnoCreateForm
									className={"NotifADTUno " + (form_statuses["NotifADTUno"] == 1 || form_statuses["NotifADTUno"] == 0 ? '' : 'highlighted')}
									onSubmit={updateRegistrationSubmitFields}
									onSuccess={formSuccessADTUno}
									onError={formErrorADTUno}
								/>
							)}

							{form_parts.includes("NotifADTDos") && (
								<NotificationsADTDosCreateForm
									className={"NotifADTDos " + (form_statuses["NotifADTDos"] == 1 || form_statuses["NotifADTDos"] == 0 ? '' : 'highlighted')}
									onSubmit={updateRegistrationSubmitFields}
									onSuccess={formSuccessADTDos}
									onError={formErrorADTDos}
								/>
							)}
						</div>

					</div>

					<div id="HIPAA"></div>
					<h2><i>Other Information:</i></h2>
					<HIPAACreateForm
						className={"Hipaa " + (form_statuses["Hipaa"] == 1 || form_statuses["Hipaa"] == 0 ? '' : 'highlighted')}
						onSubmit={updateRegistrationSubmitFields}
						onSuccess={formSuccessHipaa}
						onError={formErrorHipaa}
					/>
					{!is_static && (<>
						<Button
							variation="primary"
							type="submit"
							loadingText=""
							onClick={submit_DxF_form}
						>
							{/* <IconSave /> */}
							Submit
						</Button>

						<Button
							variation="primary"
							loadingText=""
							style={{ marginLeft: '10px' }}
							onClick={() => navigate(0)}
						>
							Cancel
						</Button></>
					)}
				</div>//createform
			) : (
				<div >
					{(isAdmin || isC4BH || is_static) ? (
						<div id="UpdateForm">
							<div id="TECH">
								{(form_parts.includes("TechAcute") || form_parts.includes("TechCBO") || form_parts.includes("TechInt")) && (
									<h2><i>Technology:</i></h2>
								)}
								{form_parts.includes("TechAcute") && (
									<TechAcuteUpdateForm
										techAcute={form_datas["TechAcute"]}
										className={"TechAcute" + (form_statuses["TechAcute"] == 1 || form_statuses["TechAcute"] == 0 ? '' : 'highlighted')}
										onSubmit={updateRegistrationSubmitFields}
										onSuccess={formSuccessTechAcute}
										onError={formErrorTechAcute}
									/>
								)}
								{form_parts.includes("TechCBO") && (
									<TechCBOUpdateForm
										techCBO={form_datas["TechCBO"]}
										className={"TechCBO " + (form_statuses["TechCBO"] == 1 || form_statuses["TechCBO"] == 0 ? '' : 'highlighted')}
										onSubmit={updateRegistrationSubmitFields}
										onSuccess={formSuccessTechCBO}
										onError={formErrorTechCBO}
									/>
								)}
								{form_parts.includes("TechInt") && (
									<TechIntermediariesUpdateForm
										techIntermediaries={form_datas["TechInt"]}
										className={"TechInt " + (form_statuses["TechInt"] == 1 || form_statuses["TechInt"] == 0 ? '' : 'highlighted')}
										onSubmit={updateRegistrationSubmitFields}
										onSuccess={formSuccessTechInt}
										onError={formErrorTechInt}
									/>
								)}
							</div>

							<div id="REQ">
								{(form_parts.includes("RequestUno") || form_parts.includes("RequestDos")) && (
									<div>
										<h2><i>Request for Information:</i></h2>
									</div>)}

								{form_parts.includes("RequestUno") && (<div>
									<RequestInfoUnoUpdateForm
										requestInfoUno={form_datas["RequestUno"]}
										className={"RequestUno " + (form_statuses["RequestUno"] == 1 || form_statuses["RequestUno"] == 0 ? '' : 'highlighted')}
										onSubmit={updateRegistrationSubmitFields}
										onSuccess={formSuccessReqUno}
										onError={formErrorReqUno}
									/>
								</div>)}

								{form_parts.includes("RequestDos") && (
									<RequestInfoDosUpdateForm
										requestInfoDos={form_datas["RequestDos"]}
										className={"RequestDos " + (form_statuses["RequestDos"] == 1 || form_statuses["RequestDos"] == 0 ? '' : 'highlighted')}
										onSubmit={updateRegistrationSubmitFields}
										onSuccess={formSuccessReqDos}
										onError={formErrorReqDos}
									/>
								)}
							</div>
							<div id="DLV">
								{form_parts.includes("Info") && (
									<div>
										<div style={{ display: 'inline-flex', flexDirection: 'row' }}>
											<h2><i>Information Delivery:</i></h2>
										</div>

										<InformationDeliveryUpdateForm
											informationDelivery={form_datas["Info"]}
											className={"Info " + (form_statuses["Info"] == 1 || form_statuses["Info"] == 0 ? '' : 'highlighted')}
											onSubmit={updateRegistrationSubmitFields}
											onSuccess={formSuccessInfo}
											onError={formErrorInfo}
										/>
									</div>)}
							</div>

							<div id="ADT">
								{(form_parts.includes("NotifADTUno") || form_parts.includes("NotifADTDos")) && (
									<div>
										<div className={`${flex.row}`}>
											<h2><i>ADT Event Notification:</i></h2>
										</div></div>
								)}

								{form_parts.includes("NotifADTUno") && (
									<NotificationsADTUnoUpdateForm
										notificationsADTUno={form_datas["NotifADTUno"]}
										className={"NotifADTUno " + (form_statuses["NotifADTUno"] == 1 || form_statuses["NotifADTUno"] == 0 ? '' : 'highlighted')}
										onSubmit={updateRegistrationSubmitFields}
										onSuccess={formSuccessADTUno}
										onError={formErrorADTUno}
									/>
								)}

								{form_parts.includes("NotifADTDos") && (
									<NotificationsADTDosUpdateForm
										notificationsADTDos={form_datas["NotifADTDos"]}
										className={"NotifADTDos " + (form_statuses["NotifADTDos"] == 1 || form_statuses["NotifADTDos"] == 0 ? '' : 'highlighted')}
										onSubmit={updateRegistrationSubmitFields}
										onSuccess={formSuccessADTDos}
										onError={formErrorADTDos}
									/>
								)}
							</div>



							<div id="HIPAA">
								<h2><i>Other Information:</i></h2>
								<HIPAAUpdateForm
									hIPAA={form_datas["Hipaa"]}
									className={"Hipaa " + (form_statuses["Hipaa"] == 1 || form_statuses["Hipaa"] == 0 ? '' : 'highlighted')}
									onSubmit={updateRegistrationSubmitFields}
									onSuccess={formSuccessHipaa}
									onError={formErrorHipaa}
								/>
								{!is_static && (<>
									<Button
										variation="primary"
										type="submit"
										loadingText=""
										onClick={submit_DxF_form}
									>
										Save
									</Button>
									<Button
										variation="primary"
										loadingText=""
										style={{ marginLeft: '10px' }}
										onClick={() => navigate(0)}
									>
										Cancel
									</Button></>
								)}
							</div>
						</div>
					) : (
						<div>
							<a href="/ContactUs" style={{ color: "blue" }}>Request Admin Access to edit this participant's registration</a>
							<br />
						</div>
					)
					}
				</div>
			)}

		</div>
	);
};

export default RegistrationForm;