import { Flex } from "@aws-amplify/ui-react";
import React, { useState, useEffect } from "react";
import { useTable, useSortBy } from "react-table";
import { getEnvURL } from "../../envUtils";

function iso8601ToMonthDayYear(isoString) {
	const date = new Date(isoString);
	return dateToMonthDayYear(date)
}

function dateToMonthDayYear(date) {
	const month = date.toLocaleString("default", { month: "long" });
	const day = date.getDate();
	const year = date.getFullYear();
	return `${month} ${day}, ${year}`;
}

const UserData = () => {
	const [users, setUsers] = useState([]);
	const [isLoading, setLoading] = useState(true);
	//dev: http://localhost:3001/cognito-auth-events?username=${encodeURIComponent(username)} prod: https://sbx.connectingforbetterhealth.com/api/cognito-auth-events?username=${encodeURIComponent(username)}
	//sign in dates
	const cutoff_date = new Date('2024-01-01');
	const fetchSignInDate = (username) => {
		const uri = `${getEnvURL("REACT_APP_COGNITO_AUTH_EVENTS_API")}?username=${encodeURIComponent(username)}`
		return fetch(uri)
			.then(response => response.json())
			.then(data => {
				const signInEvents = (data.AuthEvents || []).filter(event => event.EventType === "SignIn" && event.CreationDate);

				if (signInEvents.length === 0) {
					return new Date(-1)//'No Sign-Ins';
				}

				// Ensure we're dealing with valid dates
				const validSignInEvents = signInEvents.filter(event => event.CreationDate != null);

				const latestSignIn = validSignInEvents.reduce((latest, event) => {
					return new Date(event.CreationDate) > new Date(latest.CreationDate) ? event : latest;
				}, validSignInEvents[0]);

				return latestSignIn ? new Date(latestSignIn.CreationDate) : new Date(1);//iso8601ToMonthDayYear(latestSignIn.CreationDate) : 'No Sign-Ins';//
			})
			.catch(error => {
				console.error("Error fetching sign-in data for", username, ":", error);
				return 'Failed to fetch data';	// Return a failure message on error
			});
	};

	//dev: REACT_APP_COGNITO_USERS_API=http://localhost:3001/cognito-users prod: REACT_APP_COGNITO_USERS_API=https://sbx.connectingforbetterhealth.com/api/cognito-users
	// Fetch all users
	useEffect(() => {
		const uri = getEnvURL("REACT_APP_COGNITO_USERS_API")
		fetch(uri)
			.then(response => response.json())
			.then(data => {
				if (!data || !data.users || !Array.isArray(data.users)) {
					throw new Error("Data is not in expected format");
				}
				return data.users.map(user => {
					const attributes = user.Attributes.reduce((acc, attr) => {
						acc[attr.Name] = attr.Value;
						return acc;
					}, {});
					return {
						userName: user.Username,
						email: attributes.email,
						emailVerified: attributes.email_verified === "true" ? "Yes" : "No",
						confirmationStatus: user.UserStatus,
						status: user.Enabled ? "Enabled" : "Disabled",
						createDate: new Date(user.UserCreateDate),
						lastModifiedDate: new Date(user.UserLastModifiedDate)
					};
				});
			})
			.then(users => Promise.all(users.map(user =>
				fetchSignInDate(user.email).then(signInDate => ({ ...user, lastSignInDate: signInDate }))
			)))
			.then(usersWithSignInDates => {
				setUsers(usersWithSignInDates);
				setLoading(false);
			})
			.catch(error => {
				console.error("Error fetching user data:", error);
				setLoading(false);
			});
	}, []);
	//table structure
	const columns = React.useMemo(() => [
		{ Header: "Email address", accessor: "email", sortType: 'alphanumeric', },
		{ Header: "Created At", accessor: "createDate", Cell: ({ value }) => value > cutoff_date ? dateToMonthDayYear(value) : "None", sortType: 'datetime', },
		{ Header: "Last Modified", accessor: "lastModifiedDate", Cell: ({ value }) => value > cutoff_date ? dateToMonthDayYear(value) : "None", sortType: 'datetime', },
		{ Header: "Email verified", accessor: "emailVerified", sortType: 'alphanumeric', },
		{ Header: "Confirmation status", accessor: "confirmationStatus", sortType: 'alphanumeric', },
		{ Header: "Status", accessor: "status", sortType: 'alphanumeric', },
		{ Header: "User name", accessor: "userName", sortType: 'alphanumeric', },
		{ Header: "Last Sign-In", accessor: "lastSignInDate", Cell: ({ value }) => value > cutoff_date ? dateToMonthDayYear(value) : "None", sortType: 'datetime', }
	], []);

	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		rows,
		prepareRow
	} = useTable(
		{
			columns,
			data: users,
		},
		useSortBy
	);


	const SortingState = []

	if (isLoading) return <div>Loading...</div>;

	//html
	return (
		<main style={{ alignItems: "safe center", maxWidth: "100%", maxHeight: "100%" }}>
			<h2>User Data</h2>
			<div>User Count: {users.length}</div>
			<div style={{ overflow: "scroll", maxWidth: "100%" }}>
				<table {...getTableProps()} style={{ border: "solid 1px blue" }}>
					<thead>
						{headerGroups.map(headerGroup => (
							<tr {...headerGroup.getHeaderGroupProps()}>
								{headerGroup.headers.map(column => (
									<th {...column.getHeaderProps(column.getSortByToggleProps())}>
										<Flex alignItems={"center"}>
											{column.render("Header")}
											<span>
												{column.isSorted
													? column.isSortedDesc
														? ' 🔽'
														: ' 🔼'
													: '↕️'}
											</span>
										</Flex>
									</th>
								))}
							</tr>
						))}
					</thead>
					<tbody {...getTableBodyProps()}>
						{rows.map(row => {
							prepareRow(row);
							return (
								<tr {...row.getRowProps()}>
									{row.cells.map(cell => (
										<td {...cell.getCellProps()} style={{ padding: "10px", border: "solid 1px gray", background: "white" }}>
											{cell.render("Cell")}
										</td>
									))}
								</tr>
							);
						})}
					</tbody>
				</table>
			</div>
		</main>
	);
};

export default UserData;

