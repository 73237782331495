import React, { useContext, useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { Tabs, Flex } from '@aws-amplify/ui-react';
import UserData from "../components/UserData/UserData";
import { SessionContext } from '../App';
// import Graph from "./Graph";
import GraphVisualizer from '../components/Graph/Ogma.js';
import ApiVisualization from "../components/Graph/ApiOgma.js";

const AdminPage = () => {
	const { session, isC4BH } = useContext(SessionContext); // Destructure correctly if session is an object containing other props
	const [tab, setTab] = useState('4');
	const [isSessionLoading, setSessionLoading] = useState(true);
	const [canViewOgma, setCanViewOgma] = useState(false);
	const navigate = useNavigate();

	useEffect(() => {
		// console.log('session is:',session);
		if (!session) {
			console.log("AdminPage: Waiting for session data...");
			return;
		}

		// When session data is available or null
		setSessionLoading(false);

		if (!isC4BH && !isSessionLoading) {
			console.warn("AdminPage: User is not part of the 'Admin' group, redirecting.");
			navigate('/');
			return;
		}

		setCanViewOgma(!(session.email.includes("cambridge-intelligence")));

		console.info("AdminPage: User is verified as an admin.");
	}, [session, navigate, isSessionLoading]);

	if (!session) {
		console.log("AdminPage: Waiting for session data...");
		return <p>AdminPage: Waiting for session data...</p>;
	}

	return (
		<Tabs
			style={{
				width: "95%",
				height: "100%",
				maxWidth: "100%",
				margin: "0px 2% 0px 2%",
				display: "inline-block"
			}}
			value={tab}
			onValueChange={(tab) => setTab(tab)}
			isLazy={true}
			items={[
				{
					label: 'User Data',
					value: '1',
					content: (
						// <></>)
						<UserData></UserData>
					),
				},
				{
					label: 'Bug Reports',
					value: '2',
					content: (
						// <></>)
						<Flex
							direction={"column"}
							alignItems={"center"}
							height={"max-content"}
						>
							View Bug Reports <a href="https://docs.google.com/spreadsheets/d/1pNmNjmSReFzYU0RyGaVQ2prZFlOrTwceL5f0BPNkBeU/edit?usp=sharing">Open in gSheets</a>
							<iframe src="https://docs.google.com/spreadsheets/d/e/2PACX-1vTFI_TMTIU5Tp4Hy53KPrszSR_EM7jJFzSNZQSpvZRImW9SXZQnIbrdV9AYiodXxFOCUcI9ZXbCYjR8/pubhtml?widget=true&amp;headers=false"
								style={{ width: "100%", height: "80rem" }}
							></iframe>
						</Flex>
					),
				},
				{
					label: 'Beta Test',
					value: '3',
					content: (<div>
						<p>We will upload new changes here for you to test out before we deploy them to production.</p>
						<a href="http://18.234.44.193/"> Open beta test website</a>
					</div>
					),
				},
				{
					label: 'Network View (OGMA_Neo4j)',
					value: '4',
					content:
						!canViewOgma ? (
							<p>Permission Denied</p>
						) : (
							<Flex
								direction={"column"}
								style={{ width: '100%', height: '100%' }}
							>
								<GraphVisualizer use_neptune={false} />
							</Flex>
						),
				},
				{
					label: 'Api View (OGMA_CSV)',
					value: '5',
					content:
					!canViewOgma ? (
						<p>Permission Denied</p>
					) : (
						<Flex
							direction={"column"}
							style={{ width: '100%', height: '100%' }}
						>
							<ApiVisualization />
						</Flex>
					),				


				},
				{
					label: 'Network View\n(OGMA_Neptune)',
					value: '6',
					content:
						!canViewOgma ? (
							<p>Permission Denied</p>
						) : (
							<Flex
								direction={"column"}
								style={{ width: '100%', height: '100%' }}
							>
								<GraphVisualizer use_neptune={true} />
							</Flex>
						),
				},
				{
					label: 'Network View (yWorks)',
					value: '7',
					content:
						!canViewOgma ? (
							<p>Permission Denied</p>
						) : (
							<Flex
								direction={"column"}
								style={{ width: '100%', height: '100%' }}
							>
								Not implemented yet
							</Flex>
						),
				},
				{
					label: 'Network View (NeoDash)',
					value: '8',
					content: (
						<a href="/neodash/"> Open NeoDash</a>
					),
				},

			]}
		/>
	);
};

export default AdminPage;


