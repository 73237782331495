
const Annotation = ({ node }) => {
	const node_data = node.data || node.getData();
	console.log("node: ", node, node_data);

	const participantName = node_data?.Participant_Name_PrimaryOrganization ?? node_data?.name ?? "no name";
	const nodeId = node_data?.Participant_ID ?? node?._index ?? "no id";

	// console.log("participantName: ", participantName);
	// console.log("nodeId: ", nodeId);

	const style = {
		// top: `${position.y - 70 + getFormHeight("graph-filters") + getFormHeight("graph-outer-flexbox") + getFormHeight("amplify-tabs__list") + 12}px `,
		// left: `calc(${position.x + (20 + size * zoom * 30) + 2}px + var(--side-nav) + 5%)`,
	};
	return (
		// '<div class="arrow"></div>' +
		// '<div class="ogma-tooltip-header">' +
		// node.getId() +
		// '</div>' +
		// '<div class="ogma-tooltip-body">' +
		// '<code><pre>' +
		// JSON.stringify(node.getData(), undefined, 2) +
		// '</pre></code>' +
		// '</div>'
		<div className="annotation" style={style}>
			<div className="annotation-arrow" />
			<div className="annotation-header">{participantName} : {nodeId} </div>
			{node_data && Object.entries(node_data)
				.sort(([keyA], [keyB]) => keyA.localeCompare(keyB))
				.map(([key, value]) => {
					// neptune stores the data strangely sometimes
					const v = value.low ? value.low : (value.day ? value.toLocaleString() : value);
					return (
						<AnnotationRow key={key} title={key} content={v} />
					);
				})}
		</div>
	);
};

const AnnotationRow = ({ title, content }) => {
	return (
		<div className="annotation-row">
			<div className="annotation-column"><b>{title}: </b></div>
			<div>{content}</div>
		</div>
	);
};

export default Annotation;