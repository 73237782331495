/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { CheckboxField, Flex, Grid, Text } from "@aws-amplify/ui-react";
import { fetchByPath, getOverrideProps, validateField } from "./utils";
import { generateClient } from "aws-amplify/api";
import { getHipaaa } from "../graphql/queries";
import { updateHipaaa } from "../graphql/mutations";
const client = generateClient();
export default function HipaaaUpdateForm(props) {
  const {
    id: idProp,
    hipaaa: hipaaaModelProp,
    onSuccess,
    onError,
    onSubmit,
    onValidate,
    onChange,
    overrides,
    ...rest
  } = props;
  const initialValues = {
    CoveredHipaa: false,
    HybridHipaa: false,
    AssociateHipaa: false,
    NotCoveredHipaa: false,
  };
  const [CoveredHipaa, setCoveredHipaa] = React.useState(
    initialValues.CoveredHipaa
  );
  const [HybridHipaa, setHybridHipaa] = React.useState(
    initialValues.HybridHipaa
  );
  const [AssociateHipaa, setAssociateHipaa] = React.useState(
    initialValues.AssociateHipaa
  );
  const [NotCoveredHipaa, setNotCoveredHipaa] = React.useState(
    initialValues.NotCoveredHipaa
  );
  const [errors, setErrors] = React.useState({});
  const resetStateValues = () => {
    const cleanValues = hipaaaRecord
      ? { ...initialValues, ...hipaaaRecord }
      : initialValues;
    setCoveredHipaa(cleanValues.CoveredHipaa);
    setHybridHipaa(cleanValues.HybridHipaa);
    setAssociateHipaa(cleanValues.AssociateHipaa);
    setNotCoveredHipaa(cleanValues.NotCoveredHipaa);
    setErrors({});
  };
  const [hipaaaRecord, setHipaaaRecord] = React.useState(hipaaaModelProp);
  React.useEffect(() => {
    const queryData = async () => {
      const record = idProp
        ? (
            await client.graphql({
              query: getHipaaa.replaceAll("__typename", ""),
              variables: { id: idProp },
            })
          )?.data?.getHipaaa
        : hipaaaModelProp;
      setHipaaaRecord(record);
    };
    queryData();
  }, [idProp, hipaaaModelProp]);
  React.useEffect(resetStateValues, [hipaaaRecord]);
  const validations = {
    CoveredHipaa: [],
    HybridHipaa: [],
    AssociateHipaa: [],
    NotCoveredHipaa: [],
  };
  const runValidationTasks = async (
    fieldName,
    currentValue,
    getDisplayValue
  ) => {
    const value =
      currentValue && getDisplayValue
        ? getDisplayValue(currentValue)
        : currentValue;
    let validationResponse = validateField(value, validations[fieldName]);
    const customValidator = fetchByPath(onValidate, fieldName);
    if (customValidator) {
      validationResponse = await customValidator(value, validationResponse);
    }
    setErrors((errors) => ({ ...errors, [fieldName]: validationResponse }));
    return validationResponse;
  };
  return (
    <Grid
      as="form"
      rowGap="15px"
      columnGap="15px"
      padding="20px"
      onSubmit={async (event) => {
        event.preventDefault();
        let modelFields = {
          CoveredHipaa: CoveredHipaa ?? null,
          HybridHipaa: HybridHipaa ?? null,
          AssociateHipaa: AssociateHipaa ?? null,
          NotCoveredHipaa: NotCoveredHipaa ?? null,
        };
        const validationResponses = await Promise.all(
          Object.keys(validations).reduce((promises, fieldName) => {
            if (Array.isArray(modelFields[fieldName])) {
              promises.push(
                ...modelFields[fieldName].map((item) =>
                  runValidationTasks(fieldName, item)
                )
              );
              return promises;
            }
            promises.push(
              runValidationTasks(fieldName, modelFields[fieldName])
            );
            return promises;
          }, [])
        );
        if (validationResponses.some((r) => r.hasError)) {
          return;
        }
        if (onSubmit) {
          modelFields = onSubmit(modelFields);
        }
        try {
          Object.entries(modelFields).forEach(([key, value]) => {
            if (typeof value === "string" && value === "") {
              modelFields[key] = null;
            }
          });
          await client.graphql({
            query: updateHipaaa.replaceAll("__typename", ""),
            variables: {
              input: {
                id: hipaaaRecord.id,
                ...modelFields,
              },
            },
          });
          if (onSuccess) {
            onSuccess(modelFields);
          }
        } catch (err) {
          if (onError) {
            const messages = err.errors.map((e) => e.message).join("\n");
            onError(modelFields, messages);
          }
        }
      }}
      {...getOverrideProps(overrides, "HipaaaUpdateForm")}
      {...rest}
    >
      <Text
        children="Please select all that apply:"
        {...getOverrideProps(overrides, "SectionalElement0")}
      ></Text>
      <CheckboxField
        label="Covered Entity"
        name="CoveredHipaa"
        value="CoveredHipaa"
        isDisabled={false}
        checked={CoveredHipaa}
        defaultValue={CoveredHipaa}
        onChange={(e) => {
          let value = e.target.checked;
          if (onChange) {
            const modelFields = {
              CoveredHipaa: value,
              HybridHipaa,
              AssociateHipaa,
              NotCoveredHipaa,
            };
            const result = onChange(modelFields);
            value = result?.CoveredHipaa ?? value;
          }
          if (errors.CoveredHipaa?.hasError) {
            runValidationTasks("CoveredHipaa", value);
          }
          setCoveredHipaa(value);
        }}
        onBlur={() => runValidationTasks("CoveredHipaa", CoveredHipaa)}
        errorMessage={errors.CoveredHipaa?.errorMessage}
        hasError={errors.CoveredHipaa?.hasError}
        {...getOverrideProps(overrides, "CoveredHipaa")}
      ></CheckboxField>
      <CheckboxField
        label="Hybrid Entity"
        name="HybridHipaa"
        value="HybridHipaa"
        isDisabled={false}
        checked={HybridHipaa}
        defaultValue={HybridHipaa}
        onChange={(e) => {
          let value = e.target.checked;
          if (onChange) {
            const modelFields = {
              CoveredHipaa,
              HybridHipaa: value,
              AssociateHipaa,
              NotCoveredHipaa,
            };
            const result = onChange(modelFields);
            value = result?.HybridHipaa ?? value;
          }
          if (errors.HybridHipaa?.hasError) {
            runValidationTasks("HybridHipaa", value);
          }
          setHybridHipaa(value);
        }}
        onBlur={() => runValidationTasks("HybridHipaa", HybridHipaa)}
        errorMessage={errors.HybridHipaa?.errorMessage}
        hasError={errors.HybridHipaa?.hasError}
        {...getOverrideProps(overrides, "HybridHipaa")}
      ></CheckboxField>
      <CheckboxField
        label="Business Associate of one or more Covered Entities"
        name="AssociateHipaa"
        value="AssociateHipaa"
        isDisabled={false}
        checked={AssociateHipaa}
        defaultValue={AssociateHipaa}
        onChange={(e) => {
          let value = e.target.checked;
          if (onChange) {
            const modelFields = {
              CoveredHipaa,
              HybridHipaa,
              AssociateHipaa: value,
              NotCoveredHipaa,
            };
            const result = onChange(modelFields);
            value = result?.AssociateHipaa ?? value;
          }
          if (errors.AssociateHipaa?.hasError) {
            runValidationTasks("AssociateHipaa", value);
          }
          setAssociateHipaa(value);
        }}
        onBlur={() => runValidationTasks("AssociateHipaa", AssociateHipaa)}
        errorMessage={errors.AssociateHipaa?.errorMessage}
        hasError={errors.AssociateHipaa?.hasError}
        {...getOverrideProps(overrides, "AssociateHipaa")}
      ></CheckboxField>
      <CheckboxField
        label="Not a a Covered Entity, Hybrid Entity, or Business Associate"
        name="NotCoveredHipaa"
        value="NotCoveredHipaa"
        isDisabled={false}
        checked={NotCoveredHipaa}
        defaultValue={NotCoveredHipaa}
        onChange={(e) => {
          let value = e.target.checked;
          if (onChange) {
            const modelFields = {
              CoveredHipaa,
              HybridHipaa,
              AssociateHipaa,
              NotCoveredHipaa: value,
            };
            const result = onChange(modelFields);
            value = result?.NotCoveredHipaa ?? value;
          }
          if (errors.NotCoveredHipaa?.hasError) {
            runValidationTasks("NotCoveredHipaa", value);
          }
          setNotCoveredHipaa(value);
        }}
        onBlur={() => runValidationTasks("NotCoveredHipaa", NotCoveredHipaa)}
        errorMessage={errors.NotCoveredHipaa?.errorMessage}
        hasError={errors.NotCoveredHipaa?.hasError}
        {...getOverrideProps(overrides, "NotCoveredHipaa")}
      ></CheckboxField>
      <Flex
        justifyContent="space-between"
        {...getOverrideProps(overrides, "CTAFlex")}
      >
        <Flex
          gap="15px"
          {...getOverrideProps(overrides, "RightAlignCTASubFlex")}
        ></Flex>
      </Flex>
    </Grid>
  );
}
