import { getEnvURL } from '../../../envUtils';
const encounter_api = getEnvURL('REACT_APP_FETCH_CDR_ENCOUNTER_DATA');

const smile_api_encounter = {
    slug: "Encounter",
    table: {
        Title: "Encounter",
        Categories: ["Healthcare"],
        Protocols: ["http"],
        Authentication: ["Basic Auth"], // Adjust according to your API's auth method
    },
    page: {
        Summary: `
        http://ec2-100-25-223-189.compute-1.amazonaws.com:8000/Encounter

Background

The SMILE Encounter API captures comprehensive data about patient encounters in healthcare settings, detailing every interaction that patients have with healthcare facilities. It is designed to support healthcare providers by delivering essential information about patient visits, including admission details, associated healthcare locations, and the involvement of specific practitioners.

Authentication and Authorization

Access to the Encounter API is secured through Basic Authentication, ensuring that the information is only accessible by authorized users. This method requires credentials to be included in the HTTP header of each request, safeguarding patient confidentiality and data integrity.

Technical Overview

The Encounter API provides a robust dataset encompassing various elements of a patient encounter:

- **Encounter Details**: Includes identifiers, status, and classification of encounters such as Inpatient or Emergency, reflecting the nature and urgency of each visit.

- **Location Information**: Describes the specific physical locations within a healthcare facility where encounters occur, such as hospital units, rooms, and beds, providing a granular view of patient location during each encounter.

- **Practitioner Involvement**: Details the practitioners involved in each encounter, which is crucial for understanding who attended to the patient and the roles they played during the healthcare delivery process.

- **Financial and Administrative Extensions**: Contains financial class information and expected admit datetime among other details, which are vital for administrative and billing purposes.

- **Comprehensive Timeline**: Captures the period of the encounter, offering timestamps for the start and end of each patient interaction.

- **Diagnostic and Treatment Information**: Includes reason codes that describe the purpose or necessity of the encounter, such as treatments for specific symptoms or conditions.

Summary of API Usage

This API is essential for hospital information systems, healthcare analytics platforms, and administrative personnel who manage patient care logistics. It facilitates the tracking and management of patient flows within facilities, aids in resource allocation, and supports detailed reporting on healthcare services. The data provided by the Encounter API can also be used to enhance patient care quality and operational efficiency in healthcare settings.

        `,
        Endpoint: encounter_api,
        Authentication: ["Basic Auth"], // Specify the correct authentication method
        Dataflow: ["JSON"],
        example_query: {
            name: 'Query encounters',
            method: 'GET',
            url: encounter_api,
            headers: {
                'Authorization': `Basic ${btoa('admin:password')}`, // Adjust the environment variable as necessary
                'accept': 'application/json'
            },
        },
    }
}

export default smile_api_encounter;