const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

export async function exponentialBackoff(task, maxSeconds) {
	const maxMs = maxSeconds * 1000;
	let attempt = 0;
	let totalWaitTime = 0;

	while (totalWaitTime < maxMs) {
		try {
			// Attempt the task
			return await task();
		} catch (error) {
			attempt++;
			// Calculate the wait time using exponential backoff with jitter
			const waitTime = Math.min(1000 * Math.pow(2, attempt), maxMs - totalWaitTime);
			console.log(`Attempt ${attempt} failed. Retrying in ${waitTime / 1000} seconds...`);

			await sleep(waitTime);
			totalWaitTime += waitTime;

			// If we exceed the max time, throw an error
			if (totalWaitTime >= maxMs) {
				throw new Error(`Max retry time of ${maxSeconds} seconds exceeded`);
			}
		}
	}
}

export const generateUUID = () => {
	const combinedString = performance.now().toString(36) + Math.random().toString(36).slice(2, 15);
	const uuid = btoa(combinedString).replace(/\W+/g, '-').replace(/\W/g, ''); // Print the generated ID to the console
	// console.log("uuid", uuid)
	return uuid;
}

Object.filter = (obj, predicate) =>
	Object.keys(obj)
		.filter(key => predicate(obj[key]))
		.reduce((res, key) => (res[key] = obj[key], res), {});

// returns a new object with the values at each key mapped using mapFn(value)
Object.map = (object, mapFn) => {
	return Object.keys(object).reduce(function (result, key) {
		result[key] = mapFn(object[key], key)
		return result
	}, {})
}