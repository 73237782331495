import React, { Component } from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import { Autocomplete, Button, Flex } from '@aws-amplify/ui-react';
import Ogma from '@linkurious/ogma';
import neo4j from 'neo4j-driver';
import { getEnvURL } from '../../envUtils';
import './Ogma.css';
import Annotation from './annotation';

class GraphVisualizer extends Component {
	constructor(props) {
		super(props);
		console.log("props: ", props);
		this.state = {
			isLoading: false,
			error: null,
			nodeCount: 0,
			participantCount: 0,
			exchangeCount: 0,
			// showModal: false,
			// modalContent: null,
			queryType: 'participants',  // Default query type
			api_params: {},
			counts: {},
			participant_properties: {},
		};
		this.USE_NEPTUNE = this.props.use_neptune ?? process.env.NODE_ENV == 'production';
		this.ogma = null;
		this.graphContainer = React.createRef();
	}

	componentDidMount() {
		this.initOgma();
		this.fetchAndDisplayGraph();
	}

	componentWillUnmount() {
		if (this.ogma) {
			this.ogma.destroy();
		}
	}


	initOgma() {
		if (!this.graphContainer.current) {
			console.error("Container not ready");
			return;
		}
		this.ogma = new Ogma({ container: this.graphContainer.current });
		this.updateNodeStyles();
		this.updateEdgeStyles();

		this.ogma.tools.tooltip.onNodeClick(
			node => {
				return renderToStaticMarkup(<Annotation node={node} />);
			},
			{ className: 'ogma-tooltip' }
		);

		// Modify the click event to correctly identify nodes
		// this.ogma.events.on('click', (event) => {
		//     const target = event.target;
		//     try {
		//         if (target && target.isNode && target.isNode()) {
		//             this.showModal(target);
		//         } else {
		//             this.setState({ showModal: false });
		//         }
		//     } catch (error) {
		//         console.error("Error handling click:", error);
		//         this.setState({ showModal: false });
		//     }
		// });


	}

	//takes a list of objects and creates
	//one object with a list of each of the values for each field of an object in the input
	mergeObjectsToFieldLists(objects,
		{ sort, dedup, convert } = { sort: true, dedup: true, convert: (val, key = 0) => { return { id: val, label: val, prop: key }; } }
	) {
		//console.log("merge ",objects)
		const mergedData = {};
		const seen = [];
		for (const obj of objects) {
			if (!obj.data) { continue; }
			for (const [key, value] of Object.entries(obj.data)) {
				if (!mergedData[key]) {
					mergedData[key] = [];
				}
				if (!dedup || !seen.includes(value)) {
					mergedData[key].push(convert(value, key));
					seen.push(value);
				}
			}
		}
		// console.log("mergedData: ", mergedData)
		if (sort) { // Sort each value list
			function compare(a, b) {
				if (a.label < b.label) {
					return -1;
				}
				if (a.label > b.label) {
					return 1;
				}
				return 0;
			}
			for (const key in mergedData) {
				mergedData[key].sort(compare);//(a, b) => a.label.localeCompare(b.label)); // Sort numbers in ascending order
			}
		}

		return mergedData;
	}
	calculate_filter_props = (items) => {
		console.log("counting props from ", items.length);
		const newCounts = {};
		items.forEach((item) => {
			if (!item.data) { return; }
			// console.log("Counting node: ", item, " = ", newCounts[item.data.labels[0]])
			// Access and increment the count for the key
			newCounts[item.data.labels[0]] = (newCounts[item.data.labels[0]] || 0) + 1;

			// Return the updated state object
			// return newCounts;
			// if (n < 100) {console.log("made node ", re_node); n++}
		});
		this.setState({ counts: newCounts });
		const node_properties = this.mergeObjectsToFieldLists(items);
		//console.log("part props = ", node_properties)
		this.setState({ participant_properties: node_properties });
	};


	updateNodeStyles = () => {
		this.ogma.styles.addNodeRule({
			text: node => {
				const data = node.getData();
				// Modify logic to handle connected queryType specifically
				if ((this.state.queryType === 'exchanges' || (this.state.queryType === 'connected' && data.labels.includes('Exchange')))) {
					return data.name || data.organization; // Display name or organization for exchanges
				} else if (this.state.queryType === 'participants' || (this.state.queryType === 'connected' && data.labels.includes('Participant'))) {
					return data.name || data.organization; // Display name or organization for participants
				} else {
					return data.organization; // Default to organization if no specific type
				}
			},
			radius: node => {
				const data = node.getData();
				// Enlarge the radius for 'Exchange' nodes specifically in the 'connected' view
				if (this.state.queryType === 'connected' && data.labels.includes('Exchange')) {
					return 75; // Larger radius for emphasis
				} else {
					return 10; // Default radius for other nodes
				}
			}, // Example radius
			color: node => {
				const data = node.getData();
				// Apply color based on the labels
				if (data.labels.includes('Participant')) {
					return 'blue'; // Color for 'Participant'
				} else if (data.labels.includes('Exchange')) {
					return 'green'; // Color for 'Exchange'
				} else {
					return 'gray'; // Default color for other types
				}
			}
		});
	};

	updateEdgeStyles = () => {
		this.ogma.styles.addEdgeRule({
			color: edge => {
				const edgeType = edge.getData().type; // Access the type from edge data
				// Assign colors based on the type
				switch (edgeType) {
					case "Communicates_REQ":
						return 'orange';
					case "Communicates_DLV":
						return 'red';
					case "Communicates_ADT":
						return 'purple';
					case "Information_Delivery":
						return 'red'
					case "Request_for_Information":
						return 'orange'
					case "Requests_for_Notifications_of_ADT_Events":
						return 'purple'
					default:
						return 'blue'; // Default color if none of the types match
				}
			},
			width: 2, // Set the width of the edges
			text: {
				content: edge => {
					// Display edge type as a tooltip
					const edgeType = edge.getData().type || 'Unknown Type';
					return edgeType;
				},
				minVisibleSize: 10 // Only show text for edges if the zoom level is appropriate
			}
		});
	};

	filterGraph = (graph, queryType, queryParams) => {
        console.log("filtering graph: ", graph, " by params: ", queryParams);        
        //if (!graph.nodes || Object.keys(graph.nodes).length < 1 || !queryParams || queryParams.length < 1)
		//return; 
		if (!graph.nodes || Object.keys(graph.nodes).length < 1) return;

        let filteredNodes;
        const seenEdges = new Set();
        const seenNodes = new Set();

        if (queryType === "connected") {
            //TODO: fix the neptune query or contribute deduplication code to the cypher interface on neptune
            filteredNodes = Object.values(graph.nodes).filter((item) => {
              
			
				if(queryParams.hasOwnProperty("ZIP_code Clean")){
					console.log("INSIDE")
					let node_seen = seenNodes[item.id] ?? false;
				
				seenNodes[item.id] = true;
				// console.log("start filter item: ", item)
				if (!item.data) { return true; } //don't modify links
				// if (item.data.labels.includes("Exchange")) { return true; }
				let is_exchange = item.data.labels.includes("Exchange");
				let param_matches = item.data.labels.includes("Exchange") || Object.entries(queryParams).reduce((result, entry) => {
					let [key, value] = entry;
					// console.log("k: v", key, value)
					return result && (!value || item.data[key] == value);
				}, true);
				
				return (is_exchange || param_matches) && !node_seen;
				}else{
  					// Skip nodes without data
  					if (!item.data) return false;

  					// Check if the node was already seen
  					if (seenNodes.has(item.id)) return false;

  					const isExchange = item.data.labels.includes("Exchange"); // Node is an Exchange
  					const isParticipant = item.data.labels.includes("Participant");
  					
					if(queryParams.hasOwnProperty("City Clean")){

						if(isExchange){
							if(item?.data['City Clean'] === queryParams['City Clean']){
								console.log("AWESOME")
							}
						}

						const paramMatches = Object.entries(queryParams).every(([key, value]) => {
							// return !value || item.data[key] === value; // Check if all query parameters match
							return !value || item.data[key] === value  // Check if all query parameters match
						});	

						if (isExchange || isParticipant) {
							  seenNodes.add(item.id); // Mark node as seen
							  return true;
  						}
					}else{
						if (isExchange || isParticipant) {
							seenNodes.add(item.id); // Mark node as seen
							return true;
						} 
					}
  					 
  					return false;
				}
            });
        
        } else if (queryType !== "method") {
            filteredNodes = Object.values(graph.nodes).map((item) => {
                if (!item.data) return item; // Don't modify nodes without data
    
                const isSelected = Object.entries(queryParams).every(([key, value]) => {
                    return !value || item.data[key] === value;
                });
                item.is_selected = isSelected || true;
                return item;
            });
            
        } else {
            filteredNodes = graph.nodes;
        }
        
		console.log("JUST AFTER filteredNodes  : ", filteredNodes)
    const filteredNodeIds = new Set(filteredNodes.map(node => node.id)); // IDs of filtered nodes

		console.log("JUST AFTER filteredNodeIds  : ", filteredNodeIds)
        let filteredEdges;
	
		  // Filter edges to include only those connected to filtered nodes
		filteredEdges  = Object.values(graph.edges).filter((edge) => {
			const sourceExists = filteredNodeIds.has(edge.source);
			const targetExists = filteredNodeIds.has(edge.target);
		
			const matchesCommType = !queryParams.comm_type || edge.data?.type === queryParams.comm_type;
			if (sourceExists && targetExists && matchesCommType) {
				const edgeKey = `${edge.source}-${edge.target}`;
				if (!seenEdges.has(edgeKey)) {
					seenEdges.add(edgeKey); // Deduplicate edges
					return true;
				}
			}
		return false;
		});
  

    console.log("comm_type Filter:", queryParams.comm_type);
    console.log("filter result: ", filteredNodes, filteredEdges);
    console.log("Filtered Nodes Count:", filteredNodes.length);
    console.log("Filtered Edges Count:", filteredEdges.length);
    return { nodes: filteredNodes, edges: filteredEdges };
};

	makeQuery(queryType, queryParams = {}) {
		const urlParams = [`queryType=${queryType}`];

		// Add each parameter if it has a value
		for (const [key, value] of Object.entries(queryParams)) {
			if (value) {
				// console.log("key" , key);
				// console.log("value" , value);
				urlParams.push(`${key}=${encodeURIComponent(value)}`);
			}
		}

		// Join url params with '&' and add leading '?'
		const queryString = urlParams.length > 0 ? `?${urlParams.join('&')}` : '';
		console.log("queryString: ", queryString);
		return queryString;
	};

	removeOverlap = () => {
		this.ogma.layouts.force({
			gravity: 0,
			charge: 0,
			edgeStrength: 0,
			elasticity: 0.02,
			locate: true // Automatically recenter the graph
		}).then(() => {
			console.log("Overlap removal layout applied.");
		});
	};




	fetchAndDisplayGraph = async () => {
		if (this.state.isLoading)
			return;
		const neo4j_api = this.USE_NEPTUNE ? getEnvURL("REACT_APP_NEPTUNE_API") : getEnvURL("REACT_APP_NEO4J_API");
		console.log(" AAAAAA neo4j_api : ", neo4j_api)
		this.setState({ isLoading: true });

		// const queryString = `?queryType=${encodeURIComponent(queryType)}`;

		try {
			const { queryType } = this.state;
			console.log(" AAAAAA queryType : ", queryType)
			console.log(" AAAAAA this.state.api_params : ", this.state.api_params)

			const uri = neo4j_api + this.makeQuery(queryType, this.state.api_params);
			console.log(" AAAAAA uri : ", uri)
			// const uri = getEnvURL('REACT_APP_NEO4J_API');
			const response = await fetch(`${uri}`);
			if (!response.ok) {
				throw new Error(`HTTP error! status: ${response.status}`);
			}
			const data = await response.json();
			if (!data || !data.rawItems) {
				throw new Error("Data fetch returned no or incorrect data");
			}

			const graph = this.parseNeo4jResult(data.rawItems);
			console.log(" AAAAAA graph : ", graph)
			const filtered_graph = this.filterGraph(graph, queryType, this.state.api_params);
			console.log(" AAAAAA filtered_graph.nodes.length : ", filtered_graph.nodes.length)
			console.log(" AAAAAA filtered_graph.edges.length : ", filtered_graph.edges.length)
			this.calculate_filter_props(filtered_graph.nodes);
			if (!filtered_graph) {
				this.setState({ nodeCount: 0, participantCount: 0, exchangeCount: 0, isLoading: false, error: null });
				return;
			}
			const { nodes, edges } = filtered_graph;

			if (nodes.length) {
				this.ogma.clearGraph();
				// console.log("Filtered Nodes:", nodes);
                // console.log("Filtered Edges:", edges);

				await this.ogma.setGraph({ nodes, edges });
				console.log('Graph data set, now applying layout...');

				// this.updateNodeStyles();  // Update node styles based on the latest data
				// this.updateEdgeStyles();
				this.updateNodeStyles(filtered_graph.nodes);
				this.updateEdgeStyles(filtered_graph.edges);
				
				// Layout configuration
				await this.applyLayout(queryType);
				this.removeOverlap();
				//console.log('node', nodes);
				// Counting nodes based on type when connected
				if (queryType === 'connected') {
					const participantCount = nodes.filter(node => node.data.labels[0] === "Participant").length;
					const exchangeCount = nodes.filter(node => node.data.labels[0] === "Exchange").length;
					// console.log(`Participant Count: ${participantCount}`);
					// console.log(`Exchange Count: ${exchangeCount}`);
					this.setState({ nodeCount: nodes.length, participantCount, exchangeCount, isLoading: false, error: null });
				} else {
					this.setState({ nodeCount: nodes.length, isLoading: false, error: null });
				}
			} else {
				console.log("No nodes were added due to missing data.");
				this.setState({ isLoading: false, error: null });
			}
		} catch (error) {
			console.error('Failed to fetch graph data:', error);
			this.setState({ error: 'Failed to load graph data.', isLoading: false });
		}
	};

	applyLayout = async (queryType) => {
		let layoutConfig = {
			nodeDistance: 150, // Default values
			repulsionStrength: 20000,
			maxIterations: 1000
		};
		if (queryType === 'exchanges') {
			layoutConfig.nodeDistance = 300;
		} else if (queryType === 'connected') {
			layoutConfig.nodeDistance = 500; // Greater distance for better distinction
		}
		await this.ogma.layouts.force(layoutConfig);
		console.log('Layout applied, now locating graph...');
		this.ogma.view.locateGraph();
		console.log('Graph located');
	};






	componentDidUpdate(prevProps, prevState) {
		// Check if queryType has changed and update styles accordingly
		// if (prevState.queryType !== this.state.queryType) {
		// 	this.updateNodeStyles();
		// 	this.fetchAndDisplayGraph();
		// }
	}


	handleQueryTypeChange = (event) => {
		const newQueryType = event.target.value;
		this.setState({ queryType: newQueryType });
		//, () => {
		// this.updateNodeStyles();  // Update styles based on the new query type
		// this.fetchAndDisplayGraph();  // Refetch the graph with the new query type
		// });
	};


	handleRequery() {
		this.fetchAndDisplayGraph();
	}

	// handleParamSelect = (option) => {
	// 	this.setState({ api_params: { ...this.api_params, // Direct reference to the instance variable
	// [option.prop]: option.label } }); // Update specific key-value pair
	// };
	handleParamSelect = (option) => {
		console.log("optionf : ", option)
		this.setState((prevState) => ({
			api_params: {
				...prevState.api_params, // Reference to the latest state
				[option.prop]: option.label
			}
		}));
	};
	

	handleParamSelectForCommType = (option) => {
		console.log("optionf : ", option)
		this.setState((prevState) => ({
			api_params: {
				...prevState.api_params,
				[option.prop]: option.id
			}
		}));
	};
	

	// const handleParamChange = (option) => {
	// 	setApiParams({ ...api_params, [option.prop]: option.label }); // Update specific key-value pair
	// };

	handleParamClear(prop) {
		// setApiParams({ ...api_params, [prop]: "" }); // Update specific key-value pair
		console.log("prop: ", prop);
		this.setState({
			api_params: {
				[prop]: undefined
			}
		});
	};





	parseNeo4jResult(result) {
		console.log("parsing graph data: ", result);
		const nodes = [];
		let edges = [];
		result.forEach(record => {
		  record._fields.forEach(value => {
			if (value.labels) { // It's a node
			  const { longitude, latitude, ...cleanProperties } = value.properties;
			  let nodeColor = 'Blue'; // Default color
			  if (value.labels.includes('Exchange')) { // Check if it's an 'Exchange' node
				nodeColor = 'Green'; // Specific color for 'exchange' nodes
			  }
			  // Adding labels to the data object
			  const nodeData = {
				...cleanProperties, // Spread existing properties
				labels: value.labels // Add labels array
			  };
			  const nodeId = (this.USE_NEPTUNE ? value.identity.low : value.identity).toString();
			  nodes.push({
				id: nodeId,
				data: nodeData, // Now includes labels
				attributes: {
				  color: nodeColor,
				  radius: 5
				}
			  });
			  console.log('Node ID:', nodeId);
			} else if (value.type) { // It's a relationship
			  const edgeData = {
				...value.properties, // Spread existing edge properties
				type: value.type // Add type
			  };
			  const sourceId = (value.start.low ?? value.start).toString();
			  const targetId = (value.end.low ?? value.end).toString();
			  edges.push({
				id: `${sourceId}-${targetId}`,
				source: sourceId,
				target: targetId,
				data: edgeData,
				attributes: {}
			  });
			  console.log('Edge Source:', sourceId, 'Edge Target:', targetId);
			}
		  });
		});
	  
		// Ensure edges only reference existing nodes
		const nodeIds = new Set(nodes.map(node => node.id));
		edges = edges.filter(edge => {
		  const sourceExists = nodeIds.has(edge.source);
		  const targetExists = nodeIds.has(edge.target);
	  
		  if (!sourceExists) {
			console.warn(`Edge ${edge.id} has a source node ${edge.source} that does not exist.`);
		  }
		  if (!targetExists) {
			console.warn(`Edge ${edge.id} has a target node ${edge.target} that does not exist.`);
		  }
		  return sourceExists && targetExists;
		});
	  
		return { nodes, edges };
	  }



	// renderModal() {
	//     if (!this.state.showModal) return null;

	//     const { modalContent } = this.state;
	//     return (
	//         <div className="modal" style={{ position: 'absolute', top: '20%', left: '20%', width: '60%', height: '60%', background: 'white', padding: '20px', border: '1px solid #ccc', overflowY: 'auto' }}>
	//             <h1>Node Details</h1>
	//             <table>
	//                 {Object.entries(modalContent).map(([key, value]) => (
	//                     <tr key={key}>
	//                         <td><strong>{key}</strong></td>
	//                         <td>{value}</td>
	//                     </tr>
	//                 ))}
	//             </table>
	//             <button onClick={() => this.setState({ showModal: false })}>Close</button>
	//         </div>
	//     );
	// }


	render() {
		const { isLoading, error, nodeCount, queryType, participantCount, exchangeCount } = this.state;
		return (
			<div>
				{/* Control panel for selecting graph type and displaying node count */}
				<div style={{ padding: '20px', backgroundColor: '#f0f0f0' }}>
					{/* <select value={queryType} onChange={this.handleQueryTypeChange}>
						<option value="participants">Participants</option>
						<option value="exchanges">Exchange</option>
						<option value="connected">Connected</option>
					</select>
					{queryType !== 'connected' && (
						<span style={{ marginLeft: '10px' }}>Node Count: {nodeCount}</span>
					)}
					{queryType === 'connected' && (
                        <>
                            <span style={{ marginLeft: '10px' }}>Participant Count: {participantCount}</span>
                            <span style={{ marginLeft: '10px' }}>Exchange Count: {exchangeCount}</span>
                        </>
                    )} */}
					<div className='graph-outer-flexbox'>
						<Flex
							direction={"column"}
							gap={"0px"}
						>
							<Button
								isLoading={this.state.isLoading}
								isDisabled={false}
								// type="submit"
								onClick={this.fetchAndDisplayGraph}
								loadingText="Cancel"
								variation="primary"
								title="Query to request a new graph type or to filter out grayed nodes"
							>
								{this.state.error ? "Error" : "Query"}
							</Button>

							<select onChange={this.handleQueryTypeChange}>
								<option value="participants">participants</option>
								<option value="exchanges">exchanges</option>
								<option value="connected">connected</option>
								<option value="method">method</option>
							</select>
						</Flex>
						<Flex
							direction={"column"}
							gap={"0px"}
							className={"toggle-set"}
						>
							{/* <Button onClick={toggleMap}>
							Toggle Map
						</Button>
						<Button
							onClick={() => {
								console.log("toggling labels");
								setState((prevState) => { return { ...prevState, labels_enabled: !prevState.labels_enabled }; });
							}}
							variation="primary"
						>
							Toggle Labels
						</Button> */}
							{/* <Autocomplete
							label="Autocomplete group_by"
							options={Object.keys(participant_properties).length > 0 ? Object.keys(participant_properties).reduce((result, key) => {
								result.push({ prop: key, label: key });
								return result;
							}, []) : []}
							size="small"
							name="group_by"
							placeholder="Group By"
							id="group_by"
							onSelect={handleGroupBySelect}
							onClear={() => { handleGroupByClear(); }}
						/> */}
						</Flex>


						<table style={{ minWidth: "fit-content", tableLayout: "fixed" }}>
							<thead>
								<tr>
									<th>Type</th>
									<th>Count</th>
								</tr>
							</thead>
							<tbody>
								{Object.entries(this.state.counts).map(([type, count]) => (
									<tr key={type}>
										<td>{type}</td>
										<td>{count}</td>
									</tr>
								))}
							</tbody>
						</table>

						<form className="graph-inner-flexbox">
							{this.state.queryType == "connected" &&
								<div
									className="graph-filter-autocomplete"
								>
									<Autocomplete
										label="Autocomplete comm_type"
										options={[
											{ id: "Communicates_REQ", label: "Request", prop: "comm_type" },
											{ id: "Communicates_DLV", label: "Delivery", prop: "comm_type" },
											{ id: "Communicates_ADT", label: "ADT", prop: "comm_type" }
										]}
										size="small"
										name="comm_type"
										placeholder="Communication Type"
										id="comm_type"
										onSelect={this.handleParamSelectForCommType}
										onClear={() => { this.handleParamClear('comm_type'); }}
									/>
								</div>}{this.state.queryType == "connected" &&
									<div
										className="graph-filter-autocomplete"
									>
										<Autocomplete
											label="Autocomplete exchange_name"
											options={[
												// { id: "NONE SELECTED", label: "NONE SELECTED", prop: "exchange_name" },
												{ id: "Manifest MedEx", label: "Manifest MedEx", prop: "exchange_name" },
												{ id: "Long Health", label: "Long Health", prop: "exchange_name" },
												// { id: "NOT APPLICABLE", label: "NOT APPLICABLE", prop: "exchange_name" },
												{ id: "CommonWell Health Alliance", label: "CommonWell Health Alliance", prop: "exchange_name" },
												{ id: "SELF", label: "SELF", prop: "exchange_name" },
												{ id: "Los Angeles Network for Enhanced Services (LANES)", label: "Los Angeles Network for Enhanced Services (LANES)", prop: "exchange_name" },
												{ id: "Carequality", label: "Carequality", prop: "exchange_name" },
												{ id: "ONBOARDING TO QHIO", label: "ONBOARDING TO QHIO", prop: "exchange_name" },
												{ id: "Orange County Partners in Health HIE", label: "Orange County Partners in Health HIE", prop: "exchange_name" },
												{ id: "Cozeva", label: "Cozeva", prop: "exchange_name" },
												{ id: "SacValley MedShare", label: "SacValley MedShare", prop: "exchange_name" },
												{ id: "San Diego Health Connect", label: "San Diego Health Connect", prop: "exchange_name" },
												{ id: "OTHER", label: "OTHER", prop: "exchange_name" },
												{ id: "eHealth Exchange", label: "eHealth Exchange", prop: "exchange_name" },
												{ id: "Serving Communities Health Information Organization", label: "Serving Communities Health Information Organization", prop: "exchange_name" },
												{ id: "Health Gorilla", label: "Health Gorilla", prop: "exchange_name" },
												{ id: "DirectTrust", label: "DirectTrust", prop: "exchange_name" }
											]}
											size="small"
											name="exchange_name"
											placeholder="Exchange"
											id="exchange_name"
											onSelect={this.handleParamSelect}
											onClear={() => { this.handleParamClear('exchange_name'); }}
										/>
									</div>
							}{this.state.queryType != "exchanges" && this.state.queryType != "method" &&
								<div
									className="graph-filter-autocomplete"
								>
									<Autocomplete
										label="Autocomplete participant_type"
										options={this.state.participant_properties.Type ?? []}
										size="small"
										name="participant_type"
										placeholder="Type"
										id="participant_type"
										onSelect={this.handleParamSelect}
										onClear={() => { this.handleParamClear('Type'); }}
									/>
								</div>}
							{this.state.queryType != "exchanges" && this.state.queryType != "method" &&
								<div
									className="graph-filter-autocomplete"
								>
									<Autocomplete
										label="Autocomplete participant_sub_type"
										options={this.state.participant_properties.Sub_Type ?? []}
										size="small"
										name="participant_sub_type"
										placeholder="Sub Type"
										id="participant_sub_type"
										onSelect={this.handleParamSelect}
										onClear={() => { this.handleParamClear('Sub_Type'); }}
									/>
								</div>}
							{this.state.queryType != "exchanges" && this.state.queryType != "method" &&
								<div
									className="graph-filter-autocomplete"
								>
									<Autocomplete
										label="Autocomplete participant_zip_code"
										options={this.state.participant_properties["ZIP_code Clean"] ?? []}
										size="small"
										name="participant_zip_code"
										placeholder="Zip Code"
										id="participant_zip_code"
										onSelect={this.handleParamSelect}
										onClear={() => { this.handleParamClear("ZIP_code Clean"); }}
									/>
								</div>}
							{this.state.queryType != "exchanges" && this.state.queryType != "method" &&
								<div
									className="graph-filter-autocomplete"
								>
									<Autocomplete
										label="Autocomplete participant_city"
										options={this.state.participant_properties["City Clean"] ?? []}
										size="small"
										name="participant_city"
										placeholder="Clean City"
										id="participant_city"
										onSelect={this.handleParamSelect}
										onClear={() => { this.handleParamClear("City Clean"); }}
									/>
								</div>}
							{this.state.queryType != "exchanges" && this.state.queryType != "method" &&
								<div
									className="graph-filter-autocomplete"
								>
									<Autocomplete
										label="Autocomplete participant_county"
										options={this.state.participant_properties.County ?? []}
										size="small"
										name="participant_county"
										placeholder="County"
										id="participant_county"
										onSelect={this.handleParamSelect}
										onClear={() => { this.handleParamClear('County'); }}
									/>
								</div>}
							{this.state.queryType != "exchanges" && this.state.queryType != "method" &&
								<div>
									{/* <label htmlFor="participant_category">Participant Category:</label> */}
									<Autocomplete
										label="Autocomplete participant_category"
										options={this.state.participant_properties.DxF_Program_Category ?? []}
										size="small"
										name="participant_category"
										placeholder="Category"
										id="participant_category"
										onSelect={this.handleParamSelect}
										onClear={() => { this.handleParamClear('DxF_Program_Category'); }}
									/>
								</div>
							}
							{this.state.queryType == "method" &&
								<div>
									{/* <label htmlFor="participant_category">Participant Category:</label> */}
									<Autocomplete
										label="Autocomplete method_type"
										options={[
											{ id: "Direct Secure Email", label: "Direct Secure Email", prop: "method_type" },
											{ id: "sFTP", label: "sFTP", prop: "method_type" },
											{ id: "IHE", label: "IHE", prop: "method_type" },
											{ id: "Point to Point", label: "Point to Point", prop: "method_type" },
											{ id: "HL7 v2", label: "HL7 v2", prop: "method_type" },
											{ id: "FHIR", label: "FHIR", prop: "method_type" },
											{ id: "Other", label: "Other", prop: "method_type" },
											{ id: "Third Party Referral", label: "Third Party Referral", prop: "method_type" },
											{ id: "Portal", label: "Portal", prop: "method_type" },
										]}
										size="small"
										name="method_type"
										placeholder="Method"
										id="method_type"
										onSelect={this.handleParamSelect}
										onClear={() => { this.handleParamClear('method'); }}
									/>
									<p>Press Query again to filter</p>
								</div>
							}
						</form>
					</div>
				</div>

				{/* Container for graph visualization */}
				<div ref={this.graphContainer} style={{ width: '100%', height: '800px' }}>
					{isLoading && <p>Loading graph...</p>}
					{error && <p style={{ color: 'red' }}>{error}</p>}
					{/* Graph will be rendered inside this container */}
				</div>
			</div >
		);
	}
}

export default GraphVisualizer;


// Export the static method separately if needed
export const filterGraph = GraphVisualizer.filterGraph;