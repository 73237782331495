import axios from "axios";
import { getEnvURL } from "../../envUtils.js";

const s3_bucket = process.env.REACT_APP_DEFAULT_S3_BUCKET
console.log("process.env: ", process.env)

const FileUploader = ({ uploadDirectory = "public" }) => {
	console.log("uploadDirectory: ", uploadDirectory)

	const handleUpload = (selectedFile) => {
		console.log("selectedFile: ", selectedFile)
		if (!selectedFile || !uploadDirectory) {
			alert("Please select a file and a directory.");
			return;
		}
		console.log("uploading file", selectedFile, " to ", s3_bucket, "/", uploadDirectory)

		const formData = new FormData();
		formData.append("file", selectedFile);
		formData.append("directory", uploadDirectory);
		const upload_uri = `${getEnvURL("REACT_APP_FTP_UPLOAD_API")}?bucket=${s3_bucket}`
		axios.post(upload_uri, formData, {
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		})
			.then(() => {
				alert('File uploaded successfully');
			})
			.catch(error => {
				console.error('Error uploading file:', error);
				alert('Failed to upload file.');
			});
	};

	return (
		<div className="ftp-upload" style={{ margin: '20px 0' }}>
			<h2 style={{ margin: "5px" }}>Upload Files:</h2>
			Select file:
			<input type="file" onChange={event => handleUpload(event.target.files[0])} />
		</div>
	)
}
export default FileUploader;