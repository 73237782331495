/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  Button,
  Flex,
  Grid,
  SelectField,
  TextAreaField,
  TextField,
} from "@aws-amplify/ui-react";
import { StorageManager } from "@aws-amplify/ui-react-storage";
import {
  fetchByPath,
  getOverrideProps,
  processFile,
  validateField,
} from "./utils";
import { generateClient } from "aws-amplify/api";
import { createBugReport } from "../graphql/mutations";
import { Field } from "@aws-amplify/ui-react/internal";
const client = generateClient();
export default function BugReportForm(props) {
  const {
    clearOnSuccess = true,
    onSuccess,
    onError,
    onSubmit,
    onValidate,
    onChange,
    overrides,
    ...rest
  } = props;
  const initialValues = {
    RequestType: "",
    Title: "",
    Description: "",
    Screenshot: [],
  };
  const [RequestType, setRequestType] = React.useState(
    initialValues.RequestType
  );
  const [Title, setTitle] = React.useState(initialValues.Title);
  const [Description, setDescription] = React.useState(
    initialValues.Description
  );
  const [Screenshot, setScreenshot] = React.useState(initialValues.Screenshot);
  const [errors, setErrors] = React.useState({});
  const resetStateValues = () => {
    setRequestType(initialValues.RequestType);
    setTitle(initialValues.Title);
    setDescription(initialValues.Description);
    setScreenshot(initialValues.Screenshot);
    setErrors({});
  };
  const validations = {
    RequestType: [{ type: "Required" }],
    Title: [{ type: "Required" }],
    Description: [{ type: "Required" }],
    Screenshot: [],
  };
  const runValidationTasks = async (
    fieldName,
    currentValue,
    getDisplayValue
  ) => {
    const value =
      currentValue && getDisplayValue
        ? getDisplayValue(currentValue)
        : currentValue;
    let validationResponse = validateField(value, validations[fieldName]);
    const customValidator = fetchByPath(onValidate, fieldName);
    if (customValidator) {
      validationResponse = await customValidator(value, validationResponse);
    }
    setErrors((errors) => ({ ...errors, [fieldName]: validationResponse }));
    return validationResponse;
  };
  return (
    <Grid
      as="form"
      rowGap="15px"
      columnGap="15px"
      padding="20px"
      onSubmit={async (event) => {
        event.preventDefault();
        let modelFields = {
          RequestType,
          Title,
          Description,
          Screenshot,
        };
        const validationResponses = await Promise.all(
          Object.keys(validations).reduce((promises, fieldName) => {
            if (Array.isArray(modelFields[fieldName])) {
              promises.push(
                ...modelFields[fieldName].map((item) =>
                  runValidationTasks(fieldName, item)
                )
              );
              return promises;
            }
            promises.push(
              runValidationTasks(fieldName, modelFields[fieldName])
            );
            return promises;
          }, [])
        );
        if (validationResponses.some((r) => r.hasError)) {
          return;
        }
        if (onSubmit) {
          modelFields = onSubmit(modelFields);
        }
        try {
          Object.entries(modelFields).forEach(([key, value]) => {
            if (typeof value === "string" && value === "") {
              modelFields[key] = null;
            }
          });
          await client.graphql({
            query: createBugReport.replaceAll("__typename", ""),
            variables: {
              input: {
                ...modelFields,
              },
            },
          });
          if (onSuccess) {
            onSuccess(modelFields);
          }
          if (clearOnSuccess) {
            resetStateValues();
          }
        } catch (err) {
          if (onError) {
            const messages = err.errors.map((e) => e.message).join("\n");
            onError(modelFields, messages);
          }
        }
      }}
      {...getOverrideProps(overrides, "BugReportForm")}
      {...rest}
    >
      <SelectField
        label="What kind of request would you like to submit?"
        placeholder="Please select an option"
        isDisabled={false}
        value={RequestType}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              RequestType: value,
              Title,
              Description,
              Screenshot,
            };
            const result = onChange(modelFields);
            value = result?.RequestType ?? value;
          }
          if (errors.RequestType?.hasError) {
            runValidationTasks("RequestType", value);
          }
          setRequestType(value);
        }}
        onBlur={() => runValidationTasks("RequestType", RequestType)}
        errorMessage={errors.RequestType?.errorMessage}
        hasError={errors.RequestType?.hasError}
        {...getOverrideProps(overrides, "RequestType")}
      >
        <option
          children="Bug report"
          value="BUG_REPORT"
          {...getOverrideProps(overrides, "RequestTypeoption0")}
        ></option>
        <option
          children="Enhancement request"
          value="ENHANCEMENT_REQUEST"
          {...getOverrideProps(overrides, "RequestTypeoption1")}
        ></option>
        <option
          children="Contact us"
          value="CONTACT_US"
          {...getOverrideProps(overrides, "RequestTypeoption2")}
        ></option>
        <option
          children="Question"
          value="QUESTION"
          {...getOverrideProps(overrides, "RequestTypeoption3")}
        ></option>
        <option
          children="Other"
          value="OTHER"
          {...getOverrideProps(overrides, "RequestTypeoption4")}
        ></option>
        <option
          children="Access request"
          value="ACCESS_REQUEST"
          {...getOverrideProps(overrides, "RequestTypeoption5")}
        ></option>
      </SelectField>
      <TextField
        label={
          <span style={{ display: "inline-flex" }}>
            <span>Title</span>
            <span style={{ color: "red" }}>*</span>
          </span>
        }
        isRequired={true}
        isReadOnly={false}
        value={Title}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              RequestType,
              Title: value,
              Description,
              Screenshot,
            };
            const result = onChange(modelFields);
            value = result?.Title ?? value;
          }
          if (errors.Title?.hasError) {
            runValidationTasks("Title", value);
          }
          setTitle(value);
        }}
        onBlur={() => runValidationTasks("Title", Title)}
        errorMessage={errors.Title?.errorMessage}
        hasError={errors.Title?.hasError}
        {...getOverrideProps(overrides, "Title")}
      ></TextField>
      <TextAreaField
        label={
          <span style={{ display: "inline-flex" }}>
            <span>Description</span>
            <span style={{ color: "red" }}>*</span>
          </span>
        }
        isRequired={true}
        isReadOnly={false}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              RequestType,
              Title,
              Description: value,
              Screenshot,
            };
            const result = onChange(modelFields);
            value = result?.Description ?? value;
          }
          if (errors.Description?.hasError) {
            runValidationTasks("Description", value);
          }
          setDescription(value);
        }}
        onBlur={() => runValidationTasks("Description", Description)}
        errorMessage={errors.Description?.errorMessage}
        hasError={errors.Description?.hasError}
        {...getOverrideProps(overrides, "Description")}
      ></TextAreaField>
      <Field
        errorMessage={errors.Screenshot?.errorMessage}
        hasError={errors.Screenshot?.hasError}
        label={"Related Files (Screenshots, etc)"}
        isRequired={false}
        isReadOnly={false}
      >
        <StorageManager
          onUploadSuccess={({ key }) => {
            setScreenshot((prev) => {
              let value = [...prev, key];
              if (onChange) {
                const modelFields = {
                  RequestType,
                  Title,
                  Description,
                  Screenshot: value,
                };
                const result = onChange(modelFields);
                value = result?.Screenshot ?? value;
              }
              return value;
            });
          }}
          onFileRemove={({ key }) => {
            setScreenshot((prev) => {
              let value = prev.filter((f) => f !== key);
              if (onChange) {
                const modelFields = {
                  RequestType,
                  Title,
                  Description,
                  Screenshot: value,
                };
                const result = onChange(modelFields);
                value = result?.Screenshot ?? value;
              }
              return value;
            });
          }}
          processFile={processFile}
          accessLevel={"public"}
          acceptedFileTypes={[]}
          isResumable={false}
          showThumbnails={true}
          maxFileCount={10}
          maxSize={1000000000}
          {...getOverrideProps(overrides, "Screenshot")}
        ></StorageManager>
      </Field>
      <Flex
        justifyContent="space-between"
        {...getOverrideProps(overrides, "CTAFlex")}
      >
        <Button
          children="Clear"
          type="reset"
          onClick={(event) => {
            event.preventDefault();
            resetStateValues();
          }}
          {...getOverrideProps(overrides, "ClearButton")}
        ></Button>
        <Flex
          gap="15px"
          {...getOverrideProps(overrides, "RightAlignCTASubFlex")}
        >
          <Button
            children="Submit"
            type="submit"
            variation="primary"
            isDisabled={Object.values(errors).some((e) => e?.hasError)}
            {...getOverrideProps(overrides, "SubmitButton")}
          ></Button>
        </Flex>
      </Flex>
    </Grid>
  );
}
