import React from 'react';
import { useParams } from 'react-router-dom';
// @ts-ignore
import UsecaseDetail from '../components/Usecases/UsecaseDetail';
import { Flex, Text } from '@aws-amplify/ui-react';
import font from '../css/fonts.module.css'
import SearchableTable from '../components/SearchableTable';

// Dynamically import use cases
const usecasesContext = require.context('../data/UsecaseDatas', true, /\/.+\.js$/);
const usecasesData = usecasesContext.keys().reduce((acc, key) => {
	const usecase = usecasesContext(key).default;
	acc[usecase.slug] = usecase;
	return acc;
}, {});
console.log("usecasesData: ", usecasesData, usecasesContext.keys())
const UsecasePage = () => {
	const { usecase_id } = useParams();

	if (usecase_id) {
		const usecase = usecasesData[usecase_id];
		return usecase ? <UsecaseDetail usecase={usecase} /> : <Text>Use Case Not Found</Text>;
	} else {

		return (
			<Flex
				gap="0"
				direction="column"
				width="100%"
				height="100%"
				justifyContent="flex-start"
				// alignItems="center"
				overflow="hidden"
				position="relative"
				backgroundColor="rgba(255,255,255,1)"
			>
				<Flex
					gap="15px"
					direction="column"
					justifyContent="flex-start"
					// alignItems="center"
					shrink="0"
					alignSelf="stretch"
					position="relative"
					padding="73px 60px 73px 60px"
				>
					<h2 className={`${font.h2}`}>Use Cases</h2>

					<Text
						fontSize="16px"
						fontWeight="400"
						color="rgba(13,26,38,1)"
						lineHeight="24px"
						textAlign="left"
						display="block"
						width="100%"
						position="relative"
						whiteSpace="pre-wrap"
						fontFamily="Montserrat"
					>
						The DxF Sandbox hosts a “Use Case Library” co-developed by organizations with on-the-ground experience, backed by best-in-class practices, and aligned with nationally-recognized data standards. These priority use cases provide organizations with well-reasoned and defined rules for priority data exchange interactions between people and systems to enable efficient and effective implementation of these workflows.


					</Text>

					<SearchableTable datas={usecasesData} url_prefix={"UseCase"} />
				</Flex>
			</Flex>
		)
	}
};
export default UsecasePage;