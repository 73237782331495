import React, { useRef, useState } from "react";
import MyIcon from "../../ui-components/MyIcon";





const SubLevelComp = ({ item, renderNestedLevels }) => {
  const [selected, setselected] = React.useState("");

  const toggle = () => {
    setselected(selected === "" ? "active" : "");
  };

  const hasChidlren = (item) => {
    // console.log(item)
    return Array.isArray(item.children) && item.children.length > 0;// && item.children[0].id != '';
  };

  return (
    <div className="accordion-wrapper"
      style={{
        padding: "0px !important"
      }}
    >
      <div
        onClick={() => toggle()}
        style={{
            fontSize: "1.2rem",
            fontWeight: "500",
            fontFamily: "Montserrat",
            color: "#0A2851",
            cursor: hasChidlren(item) ? "pointer" : ""
         }}
        className="accordion"
      >
        {item.title}{" "}
        {hasChidlren(item) &&
            <span>
        <MyIcon
          width="28px"
          height="28px"
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          overflow="hidden"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          type={"arrow_up"}
          className={`arrow ${selected === "active" ? 'active' : 'inactive'}`}

        ></MyIcon>

            </span>}
      </div>
      {selected && (
        <div >
          {hasChidlren(item) && renderNestedLevels(item)}

        </div>
      )}
    </div>
  );
};

function Accordion(props) {
  const renderNestedLevels = (props) => {
    // console.log(props)
    return props.children.map((item, itemIndex) => (
    <SubLevelComp
        item={item}
        renderNestedLevels={renderNestedLevels}
        key={itemIndex}
    />
    ));


  };

  return renderNestedLevels(props);
}

export default Accordion;
