// src/data/ApiDatas/Cerner/summary.js
const nextgenSummary = {
	provider: "NextGen",
	logo: "https://upload.wikimedia.org/wikipedia/commons/0/03/NG_Logo_1024x768.png",
	provider_description: `
The NextGen API Developer Program helps vendors and clients develop API-powered applications compatible with the NextGen Enterprise platform. The goal is to make it easy to develop and integrate with us.

Patient Access APIs for Regulatory Compliance
The NextGen Enterprise EHR meets 2015 Certification requirements for patient access to their clinical data. Our Patient Access platform can help develop applications that enable patients to retrieve their clinical information from an API-enabled NextGen Healthcare organization. There is no charge to develop a Patient Access app; visit developer.nextgen.com for more info.

Open Access Developer Program
This program is for clients who want to develop their own API application for internal use. If a client is willing to share their API application with other clients, they can use the API Distribution Program to release it. There is no fee to develop or deploy an application under this program.
	`,
	table: {
		Title: "NextGen",
		Categories: ["Organizational", "Patient"],
		Protocols: ["json", "FHIR R4", "FHIR DSTU2", "FHIR Bulk"],
		Authentication: ["OAuth", "Client Authorization"],
	},
};

export default nextgenSummary;
