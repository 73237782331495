import React, { useEffect, useState } from 'react';
import Ogma from '@linkurious/ogma'; // make sure Ogma is installed

const ApiVisualization = () => {
  const [graphData, setGraphData] = useState(null);

  const API_BASE_URL =
  process.env.NODE_ENV === 'production'
  ? 'https://sbx.connectingforbetterhealth.com'
  : 'http://localhost:3001';

  useEffect(() => {
 
    fetch(`${API_BASE_URL}/api/load-api-graph-data`)
      .then(response => response.json())
      .then(data => {
        console.log("Graph data fetched", data);
        setGraphData(data);
      })
      .catch(error => console.error('Error fetching graph data:', error));
  }, []);

  useEffect(() => {
    if (graphData) {
      console.log("Initializing Ogma with data", graphData);
      initializeOgma(graphData);
    }
  }, [graphData]);

  const initializeOgma = (data) => {
    const ogma = new Ogma({
      container: 'graph-container'
    });

    ogma.setGraph({
        nodes: data.nodes.map(node => ({
          id: node['~id'],
          attributes: { 
            text: {
              content: node.name, // Changed from `node.name` to use an object for more options
              size: 20, // Adjust text size as needed
              color: 'black', // Optional: Change text color
              font: 'Arial' // Optional: Change font type
          },
            radius: node.name === "DxF Sandbox" ? 30 : 15,
            color: 'blue'
        
        
        }
        })),
        edges: data.relationships.map(edge => {
            // Function to determine color based on api_type
            const getColorByApiType = (apiType) => {
                switch (apiType) {
                    case 'Facilities': return 'orange';
                    case 'Patients': return 'green';
                    case 'Providers': return 'purple';
                    default: return 'grey'; // Default color
                }
            };

            return {
                id: edge['~id'],
                source: edge['~start_node_id'],
                target: edge['~end_node_id'],
                attributes: {
                  text: {
                    content: edge.api_type, // Changed from `node.name` to use an object for more options
                    size: 15, // Adjust text size as needed
                    color: 'black', // Optional: Change text color
                    font: 'Arial' // Optional: Change font type
                },
                    color: getColorByApiType(edge.api_type), // Set color dynamically
                    shape: {
                        head: 'arrow'
                    },
                    opacity: 0.55
                }
            };
        })
    });
    

    ogma.layouts.hierarchical({
      direction: 'RL',
      nodeDistance: 100,
      levelDistance: 200,
    }).then(() => {

      ogma.view.locateGraph({
        margin: 50,
        duration: 500
      });
    }).catch(console.error);

//     ogma.events.on('click', event => {
//       if (event.target && event.target.getType && event.target.getType() === 'node') {
//           const nodeName = event.target.getAttribute('text').content;
//           if (nodeName === 'VA Cerner') {
//               window.location.href = 'http://localhost:3000/apis/Cerner';
//           }
//           // Add more conditions here for other nodes as needed
//       }
//   });
  

//     // Event listener for edge clicks
// ogma.events.on('click', event => {
//   // Checking if the clicked target is an edge
//   if (event.target && event.target.isEdge && event.target.isEdge()) {
//       const edgeApiType = event.target.getAttribute('text');
//       if (edgeApiType === 'Facilities') {
//           // Navigate to a specific URL for "Facilities"
//           window.location.href = 'http://localhost:3000/apis/Cerner';
//       }
//   }
// });

  };

  return <div id="graph-container" style={{ width: '100%', height: '1000px' }}></div>;
};

export default ApiVisualization;
