import React from 'react';
import { Accordion } from '@aws-amplify/ui-react';
import { View, Flex, Text, Button, Image } from '@aws-amplify/ui-react';
import ApiDetail from './ApiDetail';

Object.map = (object, mapFn) => {
	return Object.keys(object).reduce(function (result, key) {
		result[key] = mapFn(object[key], key)
		return result
	}, {})
}

const ProviderPage = ({ provider_data }) => {
	console.log("provider_data: ", provider_data)
	const { provider, provider_description, logo } = provider_data.summary;

	return (
		<View padding="20px" style={{ backgroundColor: '#ffffff', borderRadius: '10px', maxWidth: '800px', margin: '0 auto' }}>
			{/* Provider Information */}
			<section style={{ padding: '20px', borderBottom: '2px solid #E2E8F0', marginBottom: '20px', textAlign: 'left' }}>
				<div style={{ textAlign: 'center' }}>
					{logo ?
						<Image src={logo} alt={`${provider} logo`} style={{ height: '50px' }} />
						: <h1 style={{ fontSize: '2.5em', color: '#1A202C', marginTop: '15px' }}>{provider}</h1>}
				</div>
				<p style={{ whiteSpace: "pre-wrap", color: '#4A5568', fontSize: '1.1em', marginTop: '10px' }}>{provider_description}</p>
			</section>

			{/* API List as Accordion */}
			<Accordion.Container className="accordion">
				{Object.keys(provider_data.apis).map((api_name) => {
					const api = provider_data.apis[api_name];
					console.log("api, api_name: ", api, api_name)
					return (
						<Accordion.Item key={api_name} value={`item-${api_name}`}>
							<Accordion.Trigger>
								<Flex alignItems="center">
									<Text style={{ fontSize: '1.2em', color: '#2B6CB0' }}>{api_name}</Text>
								</Flex>
							</Accordion.Trigger>
							<Accordion.Content>
								<ApiDetail api={api}></ApiDetail>
								{/* <View padding="20px" backgroundColor="#f0f4f8" borderRadius="8px">
									<Text style={{ color: '#718096', fontSize: '1.1em' }}>Category: {api.Category}</Text>
									<Text style={{ marginTop: '10px', color: '#4A5568', fontSize: '1em' }}>
										Protocols: {api.Protocols && api.Protocols.join(', ')}
									</Text>
							</View>*/}
							</Accordion.Content>
						</Accordion.Item>
					);
				})}
			</Accordion.Container>
		</View >
	);
};

export default ProviderPage;
