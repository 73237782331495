
class EnvVarNotFoundError extends Error {
	constructor(varName) {
		super(`Environment variable ${varName} not found`);
		this.name = 'EnvVarNotFoundError';
	}
}

class UnknownEnvVarError extends Error {
	constructor(varName) {
		super(`Unknown environment variable ${varName} requested`);
		this.name = 'UnknownEnvVarError';
	}
}

const envVars = {};
let config = {
	throwError: true,
	defaultValues: {
		REACT_APP_FETCH_CDR_PRACTITIONER_DATA: "http://127.0.0.1:3001/CDR-Practitioner",
		REACT_APP_FETCH_CDR_ENCOUNTER_DATA: "http://127.0.0.1:3001/CDR-Encounter",
		REACT_APP_FETCH_CDR_PATIENT_DATA: "http://127.0.0.1:3001/CDR-Patient",
		REACT_APP_DYNAMO_API: "http://localhost:3001/dxf-query?dxfNumber=",
		REACT_APP_DXF_REGISTRATION_REDIRECT: "http://localhost:3000/callback",
		REACT_APP_LANDING_PAGE_REDIRECT: "http://localhost:3000/callback",
		REACT_APP_FETCH_MIRTH_DATA: "http://127.0.0.1:3001/mirth-logs",
		REACT_APP_FETCH_SMILE_DATA: "http://127.0.0.1:3001/smile-query",
		REACT_APP_FETCH_ENCOUNTER_DATA: "http://127.0.0.1:3001/encounter-query",
		REACT_APP_FETCH_PRACTITIONER_DATA: "http://127.0.0.1:3001/practitioner-query",
		REACT_APP_ORIGIN: "http://localhost:3000",
		REACT_APP_NEO4J_API: "http://localhost:3001/neo4j",
		REACT_APP_NEPTUNE_API: "http://localhost:3001/neptune",
		REACT_APP_COGNITO_AUTH_EVENTS_API: "http://localhost:3001/cognito-auth-events",
		REACT_APP_COGNITO_USERS_API: "http://localhost:3001/cognito-users",
		REACT_APP_FTP_DIRS_API: "https://localhost:3001/sftp-directories",
		REACT_APP_FTP_FILES_API: "http://localhost:3001/sftp-directory-contents",
		REACT_APP_FTP_UPLOAD_API: "http://localhost:3001/upload",
		REACT_APP_S3_READ_FILE_API: "http://localhost:3001/s3-read-file",
	}
};

const requiredEnvVars = [
	'REACT_APP_FETCH_CDR_PRACTITIONER_DATA',
	'REACT_APP_FETCH_CDR_ENCOUNTER_DATA',
	'REACT_APP_FETCH_CDR_PATIENT_DATA',
	'REACT_APP_DYNAMO_API',
	'REACT_APP_FETCH_MIRTH_DATA',
	'REACT_APP_FETCH_SMILE_DATA',
	'REACT_APP_FETCH_ENCOUNTER_DATA',
	'REACT_APP_FETCH_PRACTITIONER_DATA',
	'REACT_APP_ORIGIN',
	'REACT_APP_NEO4J_API',
	'REACT_APP_NEPTUNE_API',
	'REACT_APP_COGNITO_AUTH_EVENTS_API',
	'REACT_APP_COGNITO_USERS_API',
	'REACT_APP_FTP_DIRS_API',
	'REACT_APP_FTP_UPLOAD_API',
	'REACT_APP_S3_READ_FILE_API',
	'REACT_APP_FTP_FILES_API'
];

// Automatically initialize environment variables when the module is loaded
(function initializeEnvVars() {
	// console.log("env", `${JSON.stringify(process.env)}`)
	requiredEnvVars.forEach(varName => {
		const envVarName = `${varName}`;
		if (process.env[envVarName]) {
			envVars[varName] = process.env[envVarName];
		} else if (config.defaultValues[varName] !== undefined) {
			envVars[varName] = config.defaultValues[varName];
		} else if (config.throwError) {
			throw new EnvVarNotFoundError(envVarName);
		}
	});
})();

// Function to get the URL from the cached environment variables
export function getEnvURL(envVarName) {
	if (requiredEnvVars.includes(envVarName)) {
		return envVars[envVarName];
	} else {
		throw new UnknownEnvVarError(envVarName);
	}
}