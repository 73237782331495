import React from "react";
import { Flex, Text } from "@aws-amplify/ui-react";

import Accordion from "../components/Accordion/Accordion";
import font from "../css/fonts.module.css";

import backgroundData from "./BackgroundData";
import useCaseData from "./useCaseData";




const Background = () => {
  const handleBackgroundDoc = () => {
    window.location.href =
      "https://emiadvisorsdc-my.sharepoint.com/:b:/g/personal/haag_kris_emiadvisorsdc_onmicrosoft_com/EZTxo577cMFHrLU7iyZ41oEBxwJdYgVtvS91NX706pzwVQ?e=UNtAO8";
  };

  return (
    <Flex
      gap="0"
      direction="column"
      width="100%"
      height="100%"
      justifyContent="flex-start"
      alignItems="center"
      overflow="hidden"
      position="relative"
      backgroundColor="rgba(255,255,255,1)"
    >
      <Flex
        gap="10px"
        direction="column"
        justifyContent="flex-start"
        alignItems="center"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        padding="73px 60px 73px 60px"
      >
        <h2 className={`${font.h2}`}>DxF Community Sandbox Background</h2>

        <Text
          fontSize="16px"
          fontWeight="400"
          color="rgba(13,26,38,1)"
          lineHeight="24px"
          textAlign="left"
          display="block"
          width="100%"
          position="relative"
          whiteSpace="pre-wrap"
          fontFamily="Montserrat"
        >
          The DxF Community Sandbox is a project of Connecting For Better Health (C4BH), a California non-profit coalition that strives to improve data sharing infrastructure with a goal of transforming health and social outcomes.


        </Text>
        <Text
          fontSize="16px"
          fontWeight="400"
          color="rgba(13,26,38,1)"
          lineHeight="24px"
          textAlign="left"
          display="block"
          width="100%"
          position="relative"
          whiteSpace="pre-wrap"
          fontFamily="Montserrat"
        >
          Built for the community and launched in December 2023, the DxF Community Sandbox is a dynamic learning environment that supports organizations participating in California’s Data Exchange Framework to securely and rapidly test, collaborate, and scale new workflows to fully realize the value and promise of the DxF.


        </Text>
        <Flex
          gap="0"
          direction="column"
          justifyContent="flex-start"
          alignItems="center"
          shrink="0"
          alignSelf="stretch"
          position="relative"
        >
          <Accordion children={backgroundData} />
        </Flex>
      </Flex>
    </Flex>
  );
};

export default Background;
