/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  CheckboxField,
  Flex,
  Grid,
  SwitchField,
  Text,
} from "@aws-amplify/ui-react";
import { fetchByPath, getOverrideProps, validateField } from "./utils";
import { generateClient } from "aws-amplify/api";
import { createHipaaa } from "../graphql/mutations";
const client = generateClient();
export default function HipaaaCreateForm(props) {
  const {
    clearOnSuccess = true,
    onSuccess,
    onError,
    onSubmit,
    onValidate,
    onChange,
    overrides,
    ...rest
  } = props;
  const initialValues = {
    CoveredHipaa: false,
    HybridHipaa: false,
    AssociateHipaa: false,
    NotCoveredHipaa: false,
    Field0: false,
  };
  const [CoveredHipaa, setCoveredHipaa] = React.useState(
    initialValues.CoveredHipaa
  );
  const [HybridHipaa, setHybridHipaa] = React.useState(
    initialValues.HybridHipaa
  );
  const [AssociateHipaa, setAssociateHipaa] = React.useState(
    initialValues.AssociateHipaa
  );
  const [NotCoveredHipaa, setNotCoveredHipaa] = React.useState(
    initialValues.NotCoveredHipaa
  );
  const [Field0, setField0] = React.useState(initialValues.Field0);
  const [errors, setErrors] = React.useState({});
  const resetStateValues = () => {
    setCoveredHipaa(initialValues.CoveredHipaa);
    setHybridHipaa(initialValues.HybridHipaa);
    setAssociateHipaa(initialValues.AssociateHipaa);
    setNotCoveredHipaa(initialValues.NotCoveredHipaa);
    setField0(initialValues.Field0);
    setErrors({});
  };
  const validations = {
    CoveredHipaa: [],
    HybridHipaa: [],
    AssociateHipaa: [],
    NotCoveredHipaa: [],
    Field0: [],
  };
  const runValidationTasks = async (
    fieldName,
    currentValue,
    getDisplayValue
  ) => {
    const value =
      currentValue && getDisplayValue
        ? getDisplayValue(currentValue)
        : currentValue;
    let validationResponse = validateField(value, validations[fieldName]);
    const customValidator = fetchByPath(onValidate, fieldName);
    if (customValidator) {
      validationResponse = await customValidator(value, validationResponse);
    }
    setErrors((errors) => ({ ...errors, [fieldName]: validationResponse }));
    return validationResponse;
  };
  return (
    <Grid
      as="form"
      rowGap="15px"
      columnGap="15px"
      padding="20px"
      onSubmit={async (event) => {
        event.preventDefault();
        let modelFields = {
          CoveredHipaa,
          HybridHipaa,
          AssociateHipaa,
          NotCoveredHipaa,
          Field0,
        };
        const validationResponses = await Promise.all(
          Object.keys(validations).reduce((promises, fieldName) => {
            if (Array.isArray(modelFields[fieldName])) {
              promises.push(
                ...modelFields[fieldName].map((item) =>
                  runValidationTasks(fieldName, item)
                )
              );
              return promises;
            }
            promises.push(
              runValidationTasks(fieldName, modelFields[fieldName])
            );
            return promises;
          }, [])
        );
        if (validationResponses.some((r) => r.hasError)) {
          return;
        }
        if (onSubmit) {
          modelFields = onSubmit(modelFields);
        }
        try {
          Object.entries(modelFields).forEach(([key, value]) => {
            if (typeof value === "string" && value === "") {
              modelFields[key] = null;
            }
          });
          const modelFieldsToSave = {
            CoveredHipaa: modelFields.CoveredHipaa,
            HybridHipaa: modelFields.HybridHipaa,
            AssociateHipaa: modelFields.AssociateHipaa,
            NotCoveredHipaa: modelFields.NotCoveredHipaa,
          };
          await client.graphql({
            query: createHipaaa.replaceAll("__typename", ""),
            variables: {
              input: {
                ...modelFieldsToSave,
              },
            },
          });
          if (onSuccess) {
            onSuccess(modelFields);
          }
          if (clearOnSuccess) {
            resetStateValues();
          }
        } catch (err) {
          if (onError) {
            const messages = err.errors.map((e) => e.message).join("\n");
            onError(modelFields, messages);
          }
        }
      }}
      {...getOverrideProps(overrides, "HipaaaCreateForm")}
      {...rest}
    >
      <Text
        children="Please check all that apply:"
        {...getOverrideProps(overrides, "SectionalElement1")}
      ></Text>
      <CheckboxField
        label="Covered Entity"
        name="CoveredHipaa"
        value="CoveredHipaa"
        isDisabled={false}
        checked={CoveredHipaa}
        onChange={(e) => {
          let value = e.target.checked;
          if (onChange) {
            const modelFields = {
              CoveredHipaa: value,
              HybridHipaa,
              AssociateHipaa,
              NotCoveredHipaa,
              Field0,
            };
            const result = onChange(modelFields);
            value = result?.CoveredHipaa ?? value;
          }
          if (errors.CoveredHipaa?.hasError) {
            runValidationTasks("CoveredHipaa", value);
          }
          setCoveredHipaa(value);
        }}
        onBlur={() => runValidationTasks("CoveredHipaa", CoveredHipaa)}
        errorMessage={errors.CoveredHipaa?.errorMessage}
        hasError={errors.CoveredHipaa?.hasError}
        {...getOverrideProps(overrides, "CoveredHipaa")}
      ></CheckboxField>
      <CheckboxField
        label="Hybrid Entity"
        name="HybridHipaa"
        value="HybridHipaa"
        isDisabled={false}
        checked={HybridHipaa}
        onChange={(e) => {
          let value = e.target.checked;
          if (onChange) {
            const modelFields = {
              CoveredHipaa,
              HybridHipaa: value,
              AssociateHipaa,
              NotCoveredHipaa,
              Field0,
            };
            const result = onChange(modelFields);
            value = result?.HybridHipaa ?? value;
          }
          if (errors.HybridHipaa?.hasError) {
            runValidationTasks("HybridHipaa", value);
          }
          setHybridHipaa(value);
        }}
        onBlur={() => runValidationTasks("HybridHipaa", HybridHipaa)}
        errorMessage={errors.HybridHipaa?.errorMessage}
        hasError={errors.HybridHipaa?.hasError}
        {...getOverrideProps(overrides, "HybridHipaa")}
      ></CheckboxField>
      <CheckboxField
        label="Business Associate of one or more Covered Entities"
        name="AssociateHipaa"
        value="AssociateHipaa"
        isDisabled={false}
        checked={AssociateHipaa}
        onChange={(e) => {
          let value = e.target.checked;
          if (onChange) {
            const modelFields = {
              CoveredHipaa,
              HybridHipaa,
              AssociateHipaa: value,
              NotCoveredHipaa,
              Field0,
            };
            const result = onChange(modelFields);
            value = result?.AssociateHipaa ?? value;
          }
          if (errors.AssociateHipaa?.hasError) {
            runValidationTasks("AssociateHipaa", value);
          }
          setAssociateHipaa(value);
        }}
        onBlur={() => runValidationTasks("AssociateHipaa", AssociateHipaa)}
        errorMessage={errors.AssociateHipaa?.errorMessage}
        hasError={errors.AssociateHipaa?.hasError}
        {...getOverrideProps(overrides, "AssociateHipaa")}
      ></CheckboxField>
      <CheckboxField
        label="Not a Covered Entity, Hybrid Entity, or Business Associate"
        name="NotCoveredHipaa"
        value="NotCoveredHipaa"
        isDisabled={false}
        checked={NotCoveredHipaa}
        onChange={(e) => {
          let value = e.target.checked;
          if (onChange) {
            const modelFields = {
              CoveredHipaa,
              HybridHipaa,
              AssociateHipaa,
              NotCoveredHipaa: value,
              Field0,
            };
            const result = onChange(modelFields);
            value = result?.NotCoveredHipaa ?? value;
          }
          if (errors.NotCoveredHipaa?.hasError) {
            runValidationTasks("NotCoveredHipaa", value);
          }
          setNotCoveredHipaa(value);
        }}
        onBlur={() => runValidationTasks("NotCoveredHipaa", NotCoveredHipaa)}
        errorMessage={errors.NotCoveredHipaa?.errorMessage}
        hasError={errors.NotCoveredHipaa?.hasError}
        {...getOverrideProps(overrides, "NotCoveredHipaa")}
      ></CheckboxField>
      <SwitchField
        label="Label"
        defaultChecked={false}
        isChecked={Field0}
        onChange={(e) => {
          let value = e.target.checked;
          if (onChange) {
            const modelFields = {
              CoveredHipaa,
              HybridHipaa,
              AssociateHipaa,
              NotCoveredHipaa,
              Field0: value,
            };
            const result = onChange(modelFields);
            value = result?.Field0 ?? value;
          }
          if (errors.Field0?.hasError) {
            runValidationTasks("Field0", value);
          }
          setField0(value);
        }}
        onBlur={() => runValidationTasks("Field0", Field0)}
        errorMessage={errors.Field0?.errorMessage}
        hasError={errors.Field0?.hasError}
        {...getOverrideProps(overrides, "Field0")}
      ></SwitchField>
      <Flex
        justifyContent="space-between"
        {...getOverrideProps(overrides, "CTAFlex")}
      >
        <Flex
          gap="15px"
          {...getOverrideProps(overrides, "RightAlignCTASubFlex")}
        ></Flex>
      </Flex>
    </Grid>
  );
}
