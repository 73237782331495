import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Flex, Text, Image, Button, View, Icon } from '@aws-amplify/ui-react';
import font from "../css/fonts.module.css";
import UseCaseDiagram from '../images/UseCaseDiagram.png';
import VisualizationsPic from '../images/visualizations.png';
import CDI from '../images/logoCDI.png';
import Cedars from '../images/logoCedarsSinai.png';
import AWSLogo from '../images/logoAWS.png';
import SmileLogo from '../images/logosmile.png';
import anthem from '../images/Anthem.png';
import blueCali from '../images/BlueCali.png'
import HealthNet from '../images/Healthnet.png';
import LACare from '../images/LACare.png';
import Coalition from '../images/Coalition.png';
import Lanes from '../images/Lanes.png';
import SacValley from '../images/SacValley.png';
import SCHIO from '../images/SCHIO.png';
import HealthGorilla from '../images/HealthGorilla.png';
import Cozeva from '../images/Cozeva.png';
import PCC from '../images/PointClickCareLogoCMYK.jpg';
import UCDavis from '../images/UCD.jpg';
import SDHC from '../images/SDHC.png';
import LC from '../images/Longhealth-21.png';
import CALHCF from '../images/CHCF.png';
import Kaiser from "../images/KaiserLogo.jpg"
import UniteUs from "../images/UnitedUsTwo.png";






const MainPage = () => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to top on every location change
  }, [location]);
  const navigate = useNavigate(); // Initialize navigate function

  // Handler for the Sign Out button
  const handleSignOut = () => {
    navigate('/'); // Redirects to the landing page
  };

  // Handler for the Query Master Patient Index button
  const HandleMirthQuery = () => {
    navigate('/Mirth');
  };

  // Handler for the Query Master Patient Index button
  const HandleNetworkQuery = () => {
    navigate('/Networking');
  };

  const HandleUseCaseQuery = () => {
    navigate('/useCase');
  };

  const HandleSmileCDRQuery = () => {
    navigate('/SmileCDR');
  };

  const HandleBackgroundQuery = () => {
    navigate('/Background');
  };

  const HandleNavigation = () => {
    navigate('/DxfRegistration');
  }
  const AdminQuery = () => {
    navigate('/AdminPage');
  };

  return (
    <Flex
      gap="0"
      direction="column"
      width="100%"
      justifyContent="flex-start"
      alignItems="center"
      overflow="hidden"
      position="relative"
      backgroundColor="rgba(255,255,255,1)"
    >
      <Flex
        gap="8px"
        direction="column"
        justifyContent="flex-start"
        alignItems="center"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        padding="73px 60px 73px 60px"
      >
        <h2 className={`${font.h2}`}>DxF Community Sandbox</h2>
        <Flex
          gap="32px"
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
          shrink="0"
          alignSelf="stretch"
          position="relative"
        >
          <Flex
            gap="10px"
            direction="column"
            justifyContent="flex-start"
            alignItems="flex-start"
            overflow="hidden"
            grow="1"
            shrink="1"
            basis="0"
            position="relative"

          >
            <Text

              fontSize="30px"
              fontWeight="500"
              color="rgba(45,112,205,1)"
              lineHeight="40px"
              textAlign="left"
              display="block"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              whiteSpace="pre-wrap"
              fontFamily="Montserrat"
              paddingBottom="12px"
            >
              Rapidly collaborate, test and scale new workflows with other DSA signatories using the Data Exchange Framework (DxF) Community Sandbox tools.
            </Text>
            <Text

              fontSize="16px"
              fontWeight="400"
              color="rgba(13,26,38,1)"
              lineHeight="24px"
              textAlign="left"
              display="block"
              letterSpacing="0.01px"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              whiteSpace="pre-wrap"
              fontFamily="Montserrat"
            >
              The Data Exchange Framework (DxF) Community Sandbox is a real-world testing environment for California DxF Participants to identify, test, and validate data exchange implementation strategies as they plan, design, and adjust their current workflows to meet state requirements.



            </Text>
            <Text

              fontSize="16px"
              fontWeight="400"
              color="rgba(13,26,38,1)"
              lineHeight="24px"
              textAlign="left"
              display="block"
              letterSpacing="0.01px"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              whiteSpace="pre-wrap"
              fontFamily="Montserrat"
            >
              The DxF Sandbox allows health providers, community-based organizations, health plans, public health agencies, and social service organizations to pursue cutting-edge data sharing approaches with meaningful cross-sector collaboration.
            </Text>



            <Text

              fontSize="16px"
              fontWeight="400"
              color="rgba(13,26,38,1)"
              lineHeight="24px"
              textAlign="left"
              display="block"
              letterSpacing="0.01px"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              whiteSpace="pre-wrap"
              fontFamily="Montserrat"

            >
              Thanks to the following organizations who are part of our co-design team and have given generously of their time and expertise to iteratively build this community asset.


            </Text>
          </Flex>
        </Flex>
      </Flex>
      <Flex
        //width="100%"
        gap="0"
        direction="column"
        width="100%"
        justifyContent="flex-start"
        alignItems="center"
        position="relative"
      >
        <Flex
          width="100%"
          gap="48px"
          direction="column"
          justifyContent="flex-start"
          alignItems="center"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="6px 60px 6px 60px"
          backgroundColor="rgba(34,84,155,1)"
        >
          <Text

            fontSize="24px"
            fontWeight="400"
            color="rgba(255,255,255,1)"
            lineHeight="36px"
            textAlign="center"
            display="block"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            whiteSpace="pre-wrap"
            fontFamily="Montserrat"
          >
            Early Adopters
          </Text>
        </Flex>
        <Flex
          width="100%"
          gap="8px"
          direction="column"
          justifyContent="flex-start"
          alignItems="center"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="33px 60px 33px 60px"
          backgroundColor="rgba(255,255,255,1)"
        >
          <Flex
            wrap="wrap"
            width="100%"
            gap="48px"
            direction="row"
            justifyContent="center"
            alignItems="center"
            shrink="0"
            alignSelf="stretch"
            position="relative"
          >
            <Image
              src={anthem}
              width="196px"
              height="58px"
              display="block"
              shrink="0"
              position="relative"
              objectFit="cover"
              alt=""
            />
            <Image
              src={blueCali}
              width="192.25px"
              height="62px"
              display="block"
              shrink="0"
              position="relative"
              objectFit="contain"
              alt=""
            />
            <Image
              src={HealthNet}
              width="96.93px"
              height="62px"
              display="block"
              shrink="0"
              position="relative"
              objectFit="contain"
              alt=""
            />
            <Image
              src={LACare}
              width="109.15px"
              height="62px"
              display="block"
              shrink="0"
              position="relative"
              objectFit="contain"
              alt=""
            />
          </Flex>
        </Flex>
      </Flex>
      <Flex
        //width="100%"
        gap="0"
        direction="column"
        width="100%"
        justifyContent="flex-start"
        alignItems="center"
        position="relative"
      >
        <Flex
          width="100%"
          gap="48px"
          direction="column"
          justifyContent="flex-start"
          alignItems="center"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="6px 60px 6px 60px"
          backgroundColor="rgba(34,84,155,1)"
        >
          <Text

            fontSize="24px"
            fontWeight="400"
            color="rgba(255,255,255,1)"
            lineHeight="36px"
            textAlign="center"
            display="block"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            whiteSpace="pre-wrap"
            fontFamily="Montserrat"
          >
            Additional Grant Support Provided By
          </Text>
        </Flex>
        <Flex
          width="100%"
          gap="8px"
          direction="column"
          justifyContent="flex-start"
          alignItems="center"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="33px 60px 33px 60px"
          backgroundColor="rgba(255,255,255,1)"
        >
          <Flex
            wrap="wrap"
            width="100%"
            gap="48px"
            direction="row"
            justifyContent="center"
            alignItems="center"
            shrink="0"
            alignSelf="stretch"
            position="relative"
          >
            <Image
              src={CDI}
              width="196px"
              height="58px"
              display="block"
              shrink="0"
              position="relative"
              objectFit="cover"
              alt=""
            />
            <Image
              src={Cedars}
              width="192.25px"
              height="62px"
              display="block"
              shrink="0"
              position="relative"
              objectFit="cover"
              alt=""
            />
            <Image
              src={AWSLogo}
              width="96.93px"
              height="62px"
              display="block"
              shrink="0"
              position="relative"
              objectFit="cover"
              alt=""
            />
            <Image
              src={SmileLogo}
              width="109.15px"
              height="62px"
              display="block"
              shrink="0"
              position="relative"
              objectFit="cover"
              alt=""
            />
            <Image
              src={Kaiser}
              width="221.76px"
              height="62px"
              display="block"
              shrink="0"
              position="relative"
              objectFit="cover"
              alt=""
            />
            <Image
              src={CALHCF}
              width="200px"
              height="100px"
              display="block"
              shrink="0"
              position="relative"
              objectFit="cover"
              alt=""
            />
            <Image
              src={UniteUs}
              width="202.27px"
              height="62px"
              display="block"
              shrink="0"
              position="relative"
              objectFit="cover"
              alt=""
            />
          </Flex>
        </Flex>
      </Flex>
      <Flex
        width="100%"
        gap="0"
        direction="column"
        justifyContent="center"
        alignItems="center"
        position="relative"
      >
        <Flex
          width="100%"
          gap="48px"
          direction="column"
          justifyContent="flex-start"
          alignItems="center"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="6px 60px 6px 60px"
          backgroundColor="rgba(34,84,155,1)"
        >
          <Text
            fontSize="24px"
            fontWeight="400"
            color="rgba(255,255,255,1)"
            lineHeight="36px"
            textAlign="center"
            display="block"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            whiteSpace="pre-wrap"
            fontFamily="Montserrat"
          >
            Additional Co-Design Team Members
          </Text>
        </Flex>
        <Flex
          width="100%"
          gap="8px"
          direction="column"
          justifyContent="center"
          alignItems="center"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="33px 60px 33px 60px"
          backgroundColor="rgba(255,255,255,1)"
        >
          {/* First row of logos */}
          <Flex
            wrap="wrap"
            width="100%"
            gap="30px"
            direction="row"
            justifyContent="center"
            alignItems="center"
            shrink="0"
            alignSelf="stretch"
            position="relative"
          >
            <Image src={Lanes} width="180px" height="58px" objectFit="contain" alt="" />
            <Image src={Coalition} width="192.25px" height="62px" objectFit="contain" alt="" />
            <Image src={SacValley} width="96.93px" height="62px" objectFit="contain" alt="" />
            <Image src={SCHIO} width="109.15px" height="62px" objectFit="contain" alt="" />
            <Image src={HealthGorilla} width="109.15px" height="62px" objectFit="contain" alt="" />
            <Image src={Cozeva} width="109.15px" height="62px" objectFit="contain" alt="" />
            <Image src={PCC} width="109.15px" height="62px" objectFit="contain" alt="" />
            <Image src={UCDavis} width="109.15px" height="62px" objectFit="contain" alt="" />
            <Image src={SDHC} width="109.15px" height="62px" objectFit="contain" alt="" />
            <Image src={LC} width="109.15px" height="62px" objectFit="contain" alt="" />
          </Flex>
        </Flex>
      </Flex>

      <Flex
        gap="24px"
        direction="column"
        width="100%"
        justifyContent="flex-start"
        alignItems="center"
        shrink="0"
        position="relative"
        padding="48px 60px 48px 60px"
        backgroundColor="rgba(10,40,81,1)"
      >
        <Flex
          gap="48px"
          direction="row"
          justifyContent="center"
          alignItems="center"
          shrink="0"
          alignSelf="stretch"
          position="relative"
        >
          <Flex
            gap="18px"
            direction="column"
            justifyContent="flex-start"
            alignItems="center"
            overflow="hidden"
            grow="1"
            shrink="1"
            basis="0"
            alignSelf="stretch"
            position="relative"
          >
            <Text

              fontSize="32px"
              fontWeight="500"
              color="rgba(255,255,255,1)"
              lineHeight="40px"
              textAlign="center"
              display="block"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              whiteSpace="pre-wrap"
              fontFamily="Montserrat"
            >
              Join the DxF Sandbox Community
            </Text>
            <Text

              fontSize="16px"
              fontWeight="400"
              color="rgba(255,255,255,1)"
              lineHeight="24px"
              textAlign="center"
              display="block"
              letterSpacing="0.01px"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              whiteSpace="pre-wrap"
            >

            </Text>
          </Flex>
        </Flex>
        <Button onClick={HandleNavigation} variation="primary" fontFamily="Montserrat"

        >
          Register DxF Participant
        </Button>
      </Flex>

    </Flex>

  )
}

export default MainPage;


