import { Card } from '@aws-amplify/ui-react'
import flex from '../css/flex.module.css'


const DsaData = ({ participant_id, dsa_data }) => {
	console.log("dsa_data: ", dsa_data)
	return (
		<Card variation="elevated" padding="20px" marginBottom="20px">
			<h3 style={{ alignSelf: 'center' }}>DxF Participant Info</h3>
			<div className={`${flex.row}`}>
				<h3 style={{ alignSelf: 'end' }}>ID:</h3>
				<div style={{ marginLeft: '.5rem', alignSelf: 'end' }}>{participant_id}</div>
			</div>
			<div className={`${flex.row}`}>
				<h3 style={{ alignSelf: 'auto' }}>Name:</h3>
				<div style={{ marginLeft: '.5rem', alignSelf: 'end', paddingBottom: 0 }}>{dsa_data.Participant_Name_PrimaryOrganization}</div>
			</div>
			<div className={`${flex.row}`}>
				<h3 style={{ alignSelf: 'auto' }}>Sub Name:</h3>
				<div style={{ marginLeft: '.5rem', alignSelf: 'end', paddingBottom: 0 }}>{dsa_data.Participant_Name_SubordinateOrganization}</div>
			</div>
			<div className={`${flex.row}`}>
				<h3 style={{ alignSelf: 'end' }}>Type:</h3>
				<div style={{ marginLeft: '.5rem', alignSelf: 'end' }}>{dsa_data.Type}</div>
			</div>
			<div className={`${flex.row}`}>
				<h3 style={{ alignSelf: 'end' }}>Sub-Type:</h3>
				<div style={{ marginLeft: '.5rem', alignSelf: 'end' }}>{dsa_data.Sub_Type}</div>
			</div>
			<div className={`${flex.row} RequestUno`}>
				<h3 style={{ alignSelf: 'center' }}>Request for Information:</h3>
				<div style={{ marginLeft: '.5rem', alignSelf: 'end' }}>{dsa_data.Request_for_Information}</div>
			</div>
			<div className={`${flex.row}`}>
				<h3 style={{ alignSelf: 'center' }}>Information Delivery:</h3>
				<div style={{ marginLeft: '.5rem', alignSelf: 'end' }}>{dsa_data.Information_Delivery}</div>
			</div>
			<div className={`${flex.row}`}>
				<h3 style={{ alignSelf: 'center' }}>ADT Event Notification</h3>
				<div style={{ marginLeft: '.5rem', alignSelf: 'end' }}>{dsa_data.Requests_for_Notifications_of_ADT_Events}</div>
			</div>
		</Card>
	)
}

export default DsaData